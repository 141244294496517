import React, { useEffect, useState, useRef } from "react";
import moment from "moment";
import ScrollToBottom from "react-scroll-to-bottom";
import "../../css/chat.scss";
import placeholder from "../../images/user.png";
import { useSelector, useDispatch } from "react-redux";
import { getReviewChat, saveReviewChat, saveChat, getChat, deleteChat, deleteReviewChat, banUser, deleteComment } from "../../redux/actions/chat";
import {
  useParams,
} from 'react-router-dom';
import { Dropdown, Menu, notification, Input, Form, Modal, Button, Checkbox, } from "antd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEllipsisVertical, faXmark } from "@fortawesome/free-solid-svg-icons";
import useWebSocket, { ReadyState } from 'react-use-websocket';
import { v4 as uuidv4 } from 'uuid';

const socketUrl = process.env.REACT_APP_SOCKET_URL
function RecordedShowChat({ chat, chatLoading, type }) {
  const dispatch = useDispatch();
  const { validate } = useSelector((state) => {
    return state;
  });

  const params = useParams();
  const review_id = params.id

  const [comments, setComments] = useState([]);
  const [value, setValue] = useState("");
  const [banModal, setBanModal] = useState(false);
  const [banLoading, setBanLoading] = useState(false);
  const [banType, setBanType] = useState("")
  const [userId, setUserId] = useState("")
  const [chatId, setChatId] = useState("")

  const lastProcessedMessageRef = useRef(null);


  const [form] = Form.useForm();

  const didUnmount = useRef(false);
  const { sendMessage, lastMessage, readyState, getWebSocket } = useWebSocket(socketUrl, {
    shouldReconnect: (closeEvent) => {
      return didUnmount.current === false;
    },
    reconnectAttempts: 2,
    reconnectInterval: 3000,
  });
  useEffect(() => {
    return () => {
      didUnmount.current = true;
    };
  }, []);

  useEffect(() => {
    if (lastMessage && lastMessage.data) {


      // Handle incoming messages
      let newdata = JSON.parse(lastMessage.data)

      // if(type=="review"){
      //   dispatch(getReviewChatfn(review_id))
      // }

      // Check if this message has already been processed
      if (lastProcessedMessageRef.current === newdata.id) {
        return; // Skip if the message is already processed
      }
      // Update the ref with the new message ID
      lastProcessedMessageRef.current = newdata.id;

      if (newdata?.user_id !== validate?.payload?.message?._id) {
        if (newdata.type === "eventmessage" || newdata.reviewId === review_id) {
          if (type !== "review") {
            dispatch(getChat(review_id)).then((res) => {
              setComments(res?.payload?.message?.data);
            });
          } else {
            dispatch(getReviewChat(review_id)).then((res) => {
              setComments(res?.payload?.message?.data);
            });
          }
        }
      }

      // else if(newdata.type === "eventmessage" && newdata?.reviewId) {
      //   dispatch(getReviewChat(review_id)).then((res) => {
      //     setComments(res?.payload?.message?.data);
      //   });
      // }
    }
  }, [lastMessage]);


  useEffect(() => {
    if (chat) {
      setComments(chat);
    }
  }, [chat]);

  function dateToFromNowDaily(myDate) {
    var fromNow = moment(myDate).format("LLL");

    return moment(myDate).calendar(null, {
      lastWeek: "LLL",
      lastDay: "[Yesterday] h:mm A",
      sameDay: "[Today] h:mm A",
      sameElse: function () {
        return "[" + fromNow + "]";
      },
    });
  }

  const handleChange = (e) => {
    setValue(e.target.value);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!value) return;

    let msg = {
      review_id: review_id,
      message: {
        author: validate?.payload?.message?.nick_name ? validate?.payload?.message?.nick_name : validate?.payload?.message?.name,
        user_id: validate?.payload?.message?._id,
        content: value,
        datetime: moment().format(),
        chat_id: uuidv4()
      }
    }

    if (type === "review") {
      const deleteMessage = {
        type: 'eventmessage',
        reviewId: review_id,
        user_id: validate?.payload?.message?._id,
        id: Math.floor(Math.random() * 10) + 1,
        payload: msg?.message
      };
      sendMessage(JSON.stringify(deleteMessage));
      dispatch(saveReviewChat(msg)).then((res) => {
        dispatch(getReviewChat(review_id)).then((res) => {
          setComments(res?.payload?.message?.data);
        });
      });
    }
    else {
      const deleteMessage = {
        type: 'eventmessage',
        messageId: review_id,
        reviewId: review_id,
        user_id: validate?.payload?.message?._id,
        id: Math.floor(Math.random() * 10) + 1,
        payload: msg?.message
      };
      sendMessage(JSON.stringify(deleteMessage));
      let data = {
        author: validate?.payload?.message?.nick_name ? validate?.payload?.message?.nick_name : validate?.payload?.message?.name,
        user_id: validate?.payload?.message?._id,
        content: value,
        datetime: moment().format(),
      }
      dispatch(saveChat(review_id, data)).then((res) => {
        dispatch(getChat(review_id)).then((res) => {
          setComments(res?.payload?.message?.data);
        });
      });
    }


    setValue("");
  };

  const handleButtonClick = (e, item) => {
    if (e.key === 'delete') {
      if (type === "review") {
        const deleteMessage = {
          type: 'eventmessage',
          reviewId: review_id,
          user_id: validate?.payload?.message?._id,
          id: Math.floor(Math.random() * 10) + 1,
        };
        sendMessage(JSON.stringify(deleteMessage));
        dispatch(deleteReviewChat(item.chat_id)).then((res) => {
          if (res.payload.success) {
            notification.success({
              message: 'Chat deleted Successfully',
              className: "toast-success",
            });
            dispatch(getReviewChat(review_id)).then((res) => {
              setComments(res?.payload?.message?.data);
            });
          } else {
            notification.error({
              message: res?.payload?.data?.message,
              className: "toast-error",
            });
          }
        });
      }
      else {
        const deleteMessage = {
          type: 'eventmessage',
          messageId: review_id,
          reviewId: review_id,
          user_id: validate?.payload?.message?._id,
          id: Math.floor(Math.random() * 10) + 1,
        };
        sendMessage(JSON.stringify(deleteMessage));
        dispatch(deleteComment(item.chat_id)).then((res) => {
          if (res.payload.success) {
            notification.success({
              message: 'Chat deleted Successfully',
              className: "toast-success",
            });
            dispatch(getChat(review_id)).then((res) => {
              setComments(res?.payload?.message?.data);
            });
          } else {
            notification.error({
              message: res?.payload?.data?.message,
              className: "toast-error",
            });
          }
        });
      }
    }
    if (e.key === 'eventban') {
      form.resetFields();
      setUserId(item?.user_id);
      setChatId(item?.chat_id);
      setBanType("chatban")
      setBanModal(true);
    }
    if (e.key === 'globalban') {
      form.resetFields();
      setUserId(item?.user_id);
      setBanType("parmaban")
      setBanModal(true);
    }
  };

  const onFinish = (val) => {
    const { reason, delete_chat } = val;
    console.log(reason, delete_chat, type, chatId);

    setBanLoading(true);
    if (type === "review") {
      const formData = {
        reason: reason,
        review_id: review_id,
        user_id: userId,
        ban_type: banType
      }
      dispatch(banUser(formData)).then((res) => {
        if (res.payload.success) {
          const banMessage = {
            type: 'eventmessage',
            // message: "User ban message",
            message: reason,
            isban: true,
            messageId: review_id,
            // reviewId: review_id,
            user_id: userId,
            // id: Math.floor(Math.random() * 10) + 1,
          };
          sendMessage(JSON.stringify(banMessage));
          notification.success({
            message: res?.payload?.message,
            className: "toast-success",
            duration: 5,
          });
          if (delete_chat) {
            const deleteMessage = {
              type: 'eventmessage',
              messageId: review_id,
              reviewId: review_id,
              user_id: validate?.payload?.message?._id,
              id: Math.floor(Math.random() * 10) + 1,
            };
            sendMessage(JSON.stringify(deleteMessage));
            dispatch(deleteReviewChat(chatId)).then((res) => {
              if (res.payload.success) {
                notification.success({
                  message: 'Chat deleted Successfully',
                  className: "toast-success",
                });
              } else {
                notification.error({
                  message: res?.payload?.data?.message,
                  className: "toast-error",
                });
              }
            });
          }
          dispatch(getReviewChat(review_id)).then((res) => {
            setComments(res?.payload?.message?.data);
          });
          setBanModal(false);
          setBanLoading(false);
          setBanType("")
          setUserId("")
          setChatId("")
          // form.resetFields();
        } else {
          setBanLoading(false);
          notification.error({
            message: res?.payload?.data?.message,
            className: "toast-error",
          });
        }
      });
    }
    else {
      const formData = {
        reason: reason,
        event_id: review_id,
        user_id: userId,
        ban_type: banType
      }
      dispatch(banUser(formData)).then((res) => {
        if (res.payload.success) {
          const banMessage = {
            type: 'eventmessage',
            // message: "User ban message",
            message: reason,
            isban: true,
            messageId: review_id,
            // reviewId: review_id,
            user_id: userId,
            // id: Math.floor(Math.random() * 10) + 1,
          };
          sendMessage(JSON.stringify(banMessage));
          notification.success({
            message: res?.payload?.message,
            className: "toast-success",
            duration: 5,
          });
          if (delete_chat) {
            const deleteMessage = {
              type: 'eventmessage',
              messageId: review_id,
              reviewId: review_id,
              user_id: validate?.payload?.message?._id,
              id: Math.floor(Math.random() * 10) + 1,
            };
            sendMessage(JSON.stringify(deleteMessage));
            dispatch(deleteComment(chatId)).then((res) => {
              if (res.payload.success) {
                notification.success({
                  message: 'Chat deleted Successfully',
                  className: "toast-success",
                });
              } else {
                notification.error({
                  message: res?.payload?.data?.message,
                  className: "toast-error",
                });
              }
            });
          }
          dispatch(getChat(review_id)).then((res) => {
            setComments(res?.payload?.message?.data);
          });
          setBanModal(false);
          setBanLoading(false);
          setBanType("")
          setUserId("")
          setChatId("")
          // form.resetFields();
        } else {
          console.log(res.payload, "error");
          setBanLoading(false);
          notification.error({
            message: res?.payload?.data?.message,
            className: "toast-error",
          });
        }
      });
    }
  };

  if (chatLoading) {
    return (
      <div className="not-found-center">
        <h3>...loading</h3>
      </div>
    );
  }

  const handleCancel = () => {
    setBanModal(false);
    setBanLoading(false);
    setBanType("")
    setUserId("")
    setChatId("")
    form.resetFields();
  };

  console.log(comments, "comments");


  return (
    <div className="live-chat-comp">
      <div id="frame">
        <div className="content">
          <ScrollToBottom className="messages recoreded-scroll">
            <ul className="message-ul">
              {comments.length === 0 ? (
                <>
                  <div className="not-found-center">
                    <h5>No Chat Found</h5>
                  </div>
                </>
              ) : (
                comments.map((it) => {
                  return (
                    <li
                      className={
                        validate?.payload?.message?._id === it.user_id ? "replies" : "sent"
                      }
                    >
                      <img src={it.avatar ? it.avatar :
                        validate?.payload?.message?._id !== it.user_id ? placeholder :
                          validate?.payload?.message?.profile_image_url || placeholder} alt="" />
                      <div className="msg-c-box message-box-main">
                        {validate?.payload?.message?._id !== it.user_id ? (
                          <>
                            <span class="user-name">{it.author} <span title={`Reason: ${it?.ban_reason}`} style={{ fontSize: '10px', color: 'red' }}>{it?.ban_reason && '(Ban)'}</span></span>
                            <p className="msg-content chat-drowpdown">
                              <span class="user-message">{it.content}</span>
                              <Dropdown.Button
                                trigger="click"
                                // className="chat-drowpdown"
                                // size="large"
                                size="small"
                                overlay={
                                  <Menu
                                    onClick={(e) =>
                                      handleButtonClick(e, it)
                                    }
                                    icon={faEllipsisVertical}
                                  >
                                    <Menu.Item
                                      key="delete"
                                    >
                                      <span>Delete</span>
                                    </Menu.Item>
                                    {!it?.ban_reason && <Menu.Item
                                      key="eventban"
                                    >
                                      <span>{type === "review" ? "Review" : "Event"} Ban</span>
                                    </Menu.Item>}
                                    {/* <Menu.Item
                                    key="globalban"
                                  >
                                    <span>Global Ban</span>
                                  </Menu.Item> */}
                                  </Menu>
                                }
                              ></Dropdown.Button>
                            </p>
                          </>
                        ) : (
                          <p className="msg-content chat-drowpdown">
                            <span class="user-message">{it.content}</span>
                            {/* <span class="user-name">{it.author}</span> */}

                            <Dropdown.Button
                              trigger="click"
                              // overlayClassName="control-room-drowpdown"
                              // size="large"
                              size="small"

                              overlay={
                                <Menu
                                  onClick={(e) =>
                                    handleButtonClick(e, it)
                                  }
                                  icon={faEllipsisVertical}
                                >
                                  <Menu.Item
                                    key="delete"
                                  >
                                    <span>Delete</span>
                                  </Menu.Item>

                                </Menu>
                              }
                            ></Dropdown.Button>
                          </p>
                        )}
                        <div className="msg-date-main">
                          <small className="msg-date">
                            {/* {moment(it.datetime).format('LLL')} */}
                            {dateToFromNowDaily(it.datetime)}
                          </small>
                        </div>
                      </div>
                    </li>
                  );
                })
              )}
            </ul>
          </ScrollToBottom>
          <div className="message-input">
            <div className="wrap">
              <form
                onSubmit={handleSubmit}
                className="chat-message-input-box">
                <input
                  className="message-field"
                  value={value}
                  type="text"
                  placeholder="Write your message..."
                  onChange={handleChange}
                />
                <button
                  onSubmit={handleSubmit}
                  className="submit message-button"
                >
                  <i className="fa fa-paper-plane" aria-hidden="true"></i>
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>
      {banModal && (
        <Modal
          className="modal-generic modal-400"
          centered
          visible={banModal}
          // onOk={handleOk}
          onCancel={handleCancel}
          closeIcon={<FontAwesomeIcon icon={faXmark} />}
          footer={false}
          maskClosable={false}
          keyboard={false}
        >
          <Form
            // layout="vertical"
            form={form}
            onFinish={onFinish}
            // onFinishFailed={onFinishFailed}
            autoComplete="off"
          >
            <div class="row">
              <div className="col-md-12">
                <h2 className="modal-hd1">
                  {banType === "chatban" ? `Are you Sure You want to ban the user from this ${type === "review" ? "review?" : "event?"} ` : "Are you sure you want to ban the user globally?"}
                </h2>
              </div>
            </div>

            <div class="row">
              <div className="col-md-12 modal-review-right">
                <div className="form-styling">
                  <Form.Item
                    name="reason"
                    labelCol={{ span: 24 }}
                    label="Reason to Ban"
                    rules={[
                      {
                        required: true,
                        message: "Please enter the reason",
                      },
                    ]}
                  >
                    <Input
                      placeholder="Enter the reason"
                      size="large"
                    />
                  </Form.Item>
                </div>
                <Form.Item
                  className="checkbox-flip"
                  name="delete_chat"
                  labelCol={{ span: 24 }}
                  // label="Discount Duration"
                  valuePropName="checked"
                  initialValue={
                    true
                  }
                // rules={[
                //   {
                //     required: true,
                //     message: "Please Enter Discount Duration",
                //   },
                // ]}
                >
                  <Checkbox>Delete this message also</Checkbox>
                </Form.Item>

              </div>
            </div>

            <div class="row">
              <div className="col-12">
                <div className="d-flex justify-content-md-end">
                  <Button
                    className="default-btn fw-normal d-flex align-items-center justify-content-center"
                    type="primary"
                    size="large"
                    htmlType="submit"
                    loading={banLoading}
                  >
                    Ban
                  </Button>
                  <Button
                    key="back"
                    className="default-btn  fw-normal outline d-flex align-items-center justify-content-center"
                    onClick={handleCancel}
                  >
                    Exit
                  </Button>
                </div>
              </div>
            </div>
          </Form>
        </Modal>
      )}
    </div>
  );
}

export default RecordedShowChat;
