import { CUSTOMURL1 } from '../../config';
import {
  SC_PARTIAL_FULFILLMENT
} from '../types/types';
import axios from 'axios';
const sc_token = JSON.parse(localStorage.getItem('sc_token'));


export const scPartialFulfillment = (order_id, data, token) => (dispatch) => {
  return axios({
    method: 'POST',
    url: `${CUSTOMURL1}fulfillment/partial`,
    headers: { Authorization: `Bearer ${sc_token ? sc_token : token}` },
    data: data
  })
    .then((response) => {
      dispatch({
        type: SC_PARTIAL_FULFILLMENT,
        payload: response.data,
      });
      return response.data;
    })
    .catch((error) => {
      return error;
    });
};
