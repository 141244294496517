import React, { useState, useEffect, useRef } from "react";
import {
  Button,
  Form,
  Input,
  InputNumber,
  Modal,
  notification,
  DatePicker,
  Spin,
  Select,
  Empty,
  Table,
  Image,
  Carousel,
  Tag,
  Space,
  Checkbox,
  Affix,
  Tooltip,
  Popover,
  Upload,
  message
} from "antd";
import { useSelector, useDispatch } from "react-redux";
import ReactPaginate from "react-paginate";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useStepsForm } from "sunflower-antd";
import { faFilePdf, faSort, faSortDown, faSortUp, faXmark, faAngleLeft, faAngleRight, faPrint, faPlus, faUpload } from "@fortawesome/free-solid-svg-icons";
import defaultImage from "../../images/user.png";
import {
  searchSku,
  searchActiveSkus,
  searchAllSkus,
  searchBySkuAndTitle,
  addToOrme,
} from "../../redux/actions/inventoryActive.action";
import "../../css/inventory.scss";
import "jspdf-autotable";
import jsPDF from "jspdf";
import { Page, Text, View, Document, StyleSheet, PDFDownloadLink, Image as ImageA } from "@react-pdf/renderer";
import Swal from "sweetalert2";
import { Link, useNavigate } from "react-router-dom";
import numeral from "numeral";
import moment from "moment";
import { scGetAllProduct } from "../../redux/actions/scGetAllProduct";
import { scDeleteProduct } from "../../redux/actions/scDeleteProduct";
import { scStatusProduct } from "../../redux/actions/scStatusProduct";
import { scImportProduct } from "../../redux/actions/scImportProduct";
import { DownOutlined } from '@ant-design/icons';
import { scGetProductSku } from "../../redux/actions/scGetProductSku";
import custom_sample from "../../video/sample_products.csv"

const { Option } = Select;
const { TextArea } = Input;

export default function SCManageProducts() {
  const dispatch = useDispatch();
  const history = useNavigate();

  const { getInventoryProducts, scGetAllProducts, validate, getProduct } = useSelector((state) => {
    return state;
  });

  const [loading, setLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(0);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [dataDetails, setDataDetail] = useState("");
  const [filterSKU, setFilterSKU] = useState(null);
  const [filterTitle, setFilterTitle] = useState();
  const [searchSKUs, setSearchSKUs] = useState([]);
  const [searchTitles, setSearchTitles] = useState([]);
  const [tempSKU, setTempSKU] = useState();
  const [tempTitle, setTempTitle] = useState();
  const [skuLoad, setSkuLoad] = useState(false);
  const [titleLoad, setTitleLoad] = useState(false);
  const [resetKey, setResetKey] = useState(0);
  const [invSort, setInvSort] = useState("inventoryLowToHigh");
  const [ormeActive, setOrmeActive] = useState("all");
  const [sortOn, setSortOn] = useState("stock");
  const [sortBy, setSortBy] = useState("asc");
  const [variantData, setVariantData] = useState([]);
  const [sortField, setSortField] = useState(null);
  const [sortDirection, setSortDirection] = useState('asc');
  const [pageLimit, setPageLimit] = useState(100);
  const [resetClick, setResetClick] = useState(false);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [selectedData, setSelectedData] = useState([]);
  const [selectedRowData, setSelectedRowData] = useState([]);
  const [selectedAllData, setSelectedAllData] = useState(false);
  const [showActionColumn, setShowActionColumn] = useState(true);
  const [affixed, setAffixed] = useState(false);
  const [open, setOpen] = useState(false);
  const [selectIndex, setSelectIndex] = useState("");
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [showBulkModal, setShowBulkModal] = useState(false);
  const [currentImageSrc, setCurrentImageSrc] = useState(null);
  const [CSVFile, setCSVFile] = useState();
  const limit = 20;
  let sc_token = JSON.parse(localStorage.getItem("sc_token"))
  const loadCustomProducts = <div className="d-flex"><span className="spin-color mx-2">
    <Spin size="small" />
  </span><p>Your products are uploading</p></div>;
  const notificationKey = `open${Date.now()}`;

  const handleUpload = () => {
    setShowBulkModal(false)
    notification.open({
      message: 'Product Uploading...',
      description: loadCustomProducts,
      key: notificationKey,
      duration: 0,
    })
    let formData = new FormData();
    // console.log(CSVFile, 'CSVFile');

    formData.append("file", CSVFile)


    form.resetFields();
    setCSVFile()
    dispatch(scImportProduct(formData, sc_token)).then((res) => {
      if (res.success) {
        notification.destroy({ key: notificationKey })
        notification.success({
          message: res.message,
          className: "toast-success",
        });
        setLoading(true);
        dispatch(scGetAllProduct(1, limit, sc_token)).then((res) => {
          if (res.products) {
            setSearchSKUs(res?.products);
            setLoading(false);
          } else {
            console.log(res, "error");
            setLoading(false);
          }
        });
      }
      else {
        notification.destroy({ key: notificationKey })
        notification.error({
          duration: 10,
          message: res?.response?.data?.message || "Something went wrong",
          className: "toast-error",
        });

      }
      form.resetFields();
    })
  }


  useEffect(() => {
    dispatch(scGetAllProduct(1, limit, sc_token)).then((res) => {
      if (res.products) {
        setSearchSKUs(res?.products);
        setLoading(false);
      } else {
        console.log(res, "error");
        setLoading(false);
      }
    });
  }, []);


  const handleDropdownVisibleChange = (visible) => {
    // If the dropdown is closing, clear the selected value
    if (!visible) {
      setFilterSKU(null);
    }
  };

  //   const handlePageClick = (e) => {
  //     console.log("e", e);
  //     const page = e.selected;
  //     setCurrentPage(page);
  //     setLoadd(true);
  //     setLoading(true);
  //     dispatch(getBoostedReviews(page + 1, limit, startDate, endDate, influencerId)).then((res) => {
  //       setLoading(false);
  //       setLoadd(false)
  //       setInfluencer("");
  //       setTotalEvent(res?.message?.total_records);
  //     });
  //   };

  // const handleSubmit = (e) => {
  //   e.preventDefault();
  //   setLoading(true);
  //   setGroupByTable(banType)
  //   setStatus(true);
  //   dispatch(getBoostedReviews(currentPage + 1, limit, startDate, endDate, influencerId)).then((res) => {
  //     setLoading(false);
  //     setInfluencer("");
  //     setTotalEvent(res?.message?.total_records);
  //     setCurrentPage(0);
  //   });
  //   // setSubmit(groupBy);
  // };

  const getStatus = (record) => {

    let status = record?.variants?.reduce((acc, item) => {
      return (acc = acc + item.inventory_quantity);
    }, 0);
    if (status > 70) {
      return (
        <div className="status-new text-success">
          {/* <div className="status-flag green"></div> */}
          High
          {/* <p>green</p> */}
        </div>
      );
    } else if (status >= 50 && status <= 70) {
      return (
        <div className="status-new text-warning-new">
          {/* <div className="status-flag yellow"></div> */}
          Medium
          {/* <p>yellow</p> */}
        </div>
      );
    } else if (status < 50) {
      return (
        <div className="status-new text-danger">
          {/* <div className="status-flag red"></div> */}
          Low
          {/* <p>red</p> */}
        </div>
      );
    } else {
      return null;
    }
  };
  const getInventory = (record) => {

    let status = record?.variants?.reduce((acc, item) => {
      return (acc = acc + item.inventory_quantity);
    }, 0);
    if (status > 70) {
      return (
        <div className="d-flex align-items-baseline w-100">
          {/* <div className="status-flag green"></div> */}
          <span className="status-new text-success mr-2">
            {status} in Stock {" "}
          </span>{record?.variants[0]?.title !== "Default Title" && <span> for {record?.variants?.length} variants </span>}
          {/* <p>green</p> */}
        </div>
      );
    } else if (status >= 50 && status <= 70) {
      return (
        <div className="d-flex align-items-baseline w-100">
          {/* <div className="status-flag yellow"></div> */}
          <span className="status-new text-warning-new mr-2">
            {status} in Stock {" "}
          </span>{record?.variants[0]?.title !== "Default Title" && <span> for {record?.variants?.length} variants </span>}
          {/* <p>yellow</p> */}
        </div>
      );
    } else if (status < 50) {
      return (
        <div className="d-flex align-items-baseline w-100">
          {/* <div className="status-flag red"></div> */}
          <span className="status-new text-danger mr-2">
            {status} in Stock {" "}
          </span>{record?.variants[0]?.title !== "Default Title" && <span> for {record?.variants?.length} variants </span>}
          {/* <p>red</p> */}
        </div>
      );
    } else {
      return null;
    }
  };

  const PDF = ({ item }) => {
    const styles = StyleSheet.create({
      page: {
        padding: 30,
      },
      container: {
        display: "flex",
        // flex: 1,
        flexDirection: 'row',
      },
      image: {
        marginBottom: 10,
      },
      marginBottom: {
        marginBottom: 10,
      },
      leftColumn: {
        flexDirection: 'column',
        width: 170,
        paddingTop: 30,
        paddingRight: 15,
      },
      footer: {
        fontSize: 12,
        textAlign: 'center',
        marginTop: 15,
        paddingTop: 5,
        borderWidth: 3,
        borderColor: 'gray',
        borderStyle: 'dashed',
        '@media orientation: landscape': {
          marginTop: 10,
        },
      },
      headerarea: {
        flexDirection: 'row',
        borderBottomWidth: 2,
        borderBottomColor: '#112131',
        borderBottomStyle: 'solid',
        alignItems: 'stretch',
      },
      detailColumn: {
        flexDirection: 'column',
        flexGrow: 9,
        textTransform: 'uppercase',
      },
      linkColumn: {
        flexDirection: 'column',
        flexGrow: 2,
        alignSelf: 'flex-end',
        justifySelf: 'flex-end',
      },
      hname: {
        fontSize: 16,
      },
      rightContainer: {
        // flex: 1,
        flexDirection: 'column',
        paddingLeft: 15,
        marginBottom: 10,
        paddingTop: 10,
      },
      text: {
        fontSize: 12,
        color: 'black',
      },
      headerContainer: {
        marginTop: 30,
        width: 380
      },
      date: {
        fontSize: 10,
        color: 'black',
        textDecoration: 'none',
        alignSelf: 'flex-end',
        justifySelf: 'flex-end',
      },
      table: {
        display: 'table',
        width: 'auto',
        borderStyle: 'solid',
        borderWidth: 1,
        borderRightWidth: 0,
        borderBottomWidth: 0,
      },
      table: {
        display: 'table',
        width: 'auto',
        borderStyle: 'solid',
        borderWidth: 1,
        borderRightWidth: 0,
        borderBottomWidth: 0,
      },
      tableRow: {
        margin: 'auto',
        flexDirection: 'row',
      },
      tableCellHead: {
        display: "flex",
        flexDirection: 'row',
        flexWrap: "wrap",
        // justifyContent: "flex-start",
        textAlign: "left",
        fontSize: 12,
        color: 'black',
        paddingLeft: 15,
      },
      tableCell: {
        margin: 'auto',
        marginTop: 5,
        marginLeft: 5,
        fontSize: 10,
        padding: 3,
        borderWidth: 1,
        borderStyle: 'solid',
        justifyContent: "flex-start",
        textAlign: "left"
      },
      rowView: {
        display: 'flex', flexDirection: 'row', borderTop: '1px solid #EEE', paddingTop: 8, paddingBottom: 8, textAlign: "left", fontSize: 10,
      }
    });
    const Table = ({ data }) => (
      <View style={styles.table}>
        <View style={styles.tableRow}>
          <Text style={styles.tableCell}>ID</Text>
          <Text style={styles.tableCell}>Name</Text>
          <Text style={styles.tableCell}>Age</Text>
        </View>
        {/* {data.map(item => (
          <TableRow {...item} />
        ))} */}
      </View>
    );
    const TableRow = ({ id, name, age }) => (
      <View style={styles.tableRow} key={id}>
        <Text style={styles.tableCell}>{id}</Text>
        <Text style={styles.tableCell}>{name}</Text>
        <Text style={styles.tableCell}>{age}</Text>
      </View>
    );
    return (
      <Document>
        <Page size="A4" style={styles.page}>
          <View style={styles.headerarea}>
            <View style={styles.detailColumn}>
              <Text style={styles.hname}>Worksheet - {item?.variants[0]?.sku} - {item?.title}</Text>
            </View>
            {/* <View style={styles.linkColumn}>
              <Text style={styles.date}>{moment(item?.created_at).utc().format("MM-DD-YYYY")}</Text>
            </View> */}
          </View>

          <View style={styles.container}>
            <View style={styles.leftColumn}>
              <ImageA
                src={item?.image?.src}
                style={styles.image}
              />
            </View>
            <View style={styles.headerContainer}>
              <View style={styles.rightContainer}>
                <Text style={styles.text}>
                  Product Title : {item?.title}
                </Text>
              </View>
              <View style={styles.rightContainer}>
                <Text style={styles.text}>Product ID : {item?.id}</Text>
              </View>
              <View style={styles.rightContainer}>
                <Text style={styles.text}>Product SKU : {item?.variants[0]?.sku}</Text>
              </View>
              <View style={styles.rightContainer}>
                <Text style={styles.text}>Product Link :  {"https://" + item?.domain?.replace(/^https?:\/\//, '')}</Text>
              </View>
              <View style={styles.rightContainer}>
                <Text style={styles.text}>Product Price : ${item?.variants[0]?.price}</Text>
              </View>
              {
                item && item?.body_html?.length > 0 &&
                <View style={styles.rightContainer}>
                  <Text style={styles.text}>Product Description : {item && item?.body_html
                    ? dataDetails?.body_html.replace(
                      /<\/?[^>]+(>|$)/g,
                      ""
                    )
                    : ""}</Text>
                </View>}
              {
                item && item?.tags?.length > 0 &&
                <View style={styles.rightContainer}>
                  <Text style={styles.text}>Product Tags : </Text>
                </View>}
              {
                item && item?.tags?.length > 0 &&
                <View style={styles.tableCellHead}>
                  {item && item?.tags?.split(",").map((e, i) => {
                    return <Text style={styles.tableCell}>{e}</Text>
                  })}
                </View>}
            </View>
          </View>


          {/* <Table data={item?.options} /> */}

          <View style={styles.marginBottom}>
            <Text style={styles.text}>
              Variants
            </Text>
          </View>
          <View style={styles.rowView}>
            <Text style={{ width: '50' }}>S.#</Text>
            {item?.options?.map((it, i) => {
              return (
                <Text style={{ width: '150' }}>{it.name}</Text>
              );
            })}
            <Text style={{ width: '150' }}>Stock</Text>
            <Text style={{ width: '150' }}>Price</Text>
          </View>
          {item?.variants.map((rowData, index) => <>
            <View style={styles.rowView}>
              <Text style={{ width: '50' }}>{index + 1}</Text>
              {rowData.option1 && <Text style={{ width: '150' }}>{rowData.option1}</Text>}
              {rowData.option2 && <Text style={{ width: '150' }}>{rowData.option2}</Text>}
              {rowData.option3 && <Text style={{ width: '150' }}>{rowData.option3}</Text>}
              <Text style={{ width: '150' }}>{rowData.inventory_quantity}</Text>
              <Text style={{ width: '150' }}>${rowData.price}</Text>
            </View>
          </>)}

        </Page>
      </Document>
    );
  };

  const downloadCSV = (csvData) => {
    // Create a link element
    const link = document.createElement('a');
    // Set the href attribute to the path of your CSV file
    link.setAttribute('href', custom_sample);
    // Set the download attribute to specify the filename
    link.setAttribute('download', 'product_sample.csv');
    // Append the link to the document body
    document.body.appendChild(link);
    // Programmatically click the link to trigger the download
    link.click();
    // Remove the link from the document body
    document.body.removeChild(link);
  };

  const dummyRequest = ({ file, onSuccess }) => {
    setTimeout(() => {
      onSuccess("ok");
    }, 0);
  };
  const normFile = (e) => {
    if (Array.isArray(e)) {
      return e;
    }
    return e?.fileList;
  };

  const uploadFileDragger = {
    accept: "text/csv",
    name: 'file',
    multiple: false,
    maxCount: 1,
    // listType: "picture-card",
    action: "https://httpbin.org/post",
    beforeUpload: (file) => {
      const isPNG =
        file.type === "text/csv";

      if (!isPNG) {
        message.error(`${file?.name} is not a valid format`);
      }

      return isPNG || Upload.LIST_IGNORE;
    },
    onChange(info) {
      const { status } = info.file;
      if (status !== 'uploading') {
      }
      if (status === 'done') {
        // message.success(`${info.file.name} file uploaded successfully.`);
        setCSVFile(info?.file?.originFileObj)
      } else if (status === 'error') {
        message.error(`${info.file.name} file upload failed.`);
      }
    },
    onDrop(e) {
      // console.log('Dropped files', e.dataTransfer.files);

    },
    // progress: {
    //   strokeColor: {
    //     '0%': '#108ee9',
    //     '100%': '#87d068',
    //   },
    //   strokeWidth: 3,
    //   format: (percent) => percent && `${parseFloat(percent.toFixed(2))}%`,
    // },

    onRemove(e) {
      setCSVFile()
      // setFileList([])
    },

  };

  const {
    form,
    current,
    gotoStep,
    stepsProps,
    formProps,
    submit,
    formLoading,
  } = useStepsForm({
    async submit(values) {

    },
  });

  const handlePageClick = (e) => {
    // setResetClick(false)
    const page = e.selected;

    setLoading(true);
    setCurrentPage(page);
    // dispatch(searchBySkuAndTitle({ sku: tempSKU ? tempSKU : "", sort_on: sortOn ? sortOn : "stock", sort: sortBy ? sortBy : "asc", is_orme_product: ormeActive === "active" ? true : false }, pageLimit, page + 1)).then(async (res) => {
    //   if (res?.success) {
    //     setSkuLoad(false);
    //     setSearchSKUs(res?.message);
    //     // const hasMatchingId = selectedRowData.length > 0 && searchSKUs.every(sku => selectedRowData.some(row => row._id === sku._id));
    //     const hasMatchingId = checkAllExist(searchSKUs, selectedRowData);
    //     setSelectedAllData(hasMatchingId)
    //   } else {
    //     setSkuLoad(false);
    //     // console.log(res, "error");
    //   }
    // })
    dispatch(scGetAllProduct(page + 1, limit, sc_token, ormeActive === "all" ? undefined : ormeActive, tempSKU)).then((res) => {
      if (res.products) {
        setSearchSKUs(res?.products);
        setLoading(false);
      } else {
        console.log(res, "error");
        setLoading(false);
      }
    });
  };

  const showDetailModal = (s) => {
    setDataDetail(s);
    setVariantData(s.variants)
    setIsModalOpen(true);
  };

  // useEffect(() => {
  //   if (validate?.payload?.message?.salesforce || validate?.payload?.message?.shopify || validate?.payload?.message?.magento || validate?.payload?.message?.scoc) {
  //     setResetClick(false)
  //     if ((tempSKU || sortOn || sortBy || ormeActive) && !resetClick) {
  //       if (!sortOn && !sortBy) {
  //         setSortOn("stock");
  //         setSortBy("asc");
  //       }
  //       // console.log(resetClick, 'resetClick');
  //       const getData = setTimeout(() => {
  //         setSkuLoad(true);
  //         dispatch(searchBySkuAndTitle({ sku: tempSKU ? tempSKU : "", sort_on: sortOn, sort: sortBy, is_orme_product: ormeActive === "active" ? true : false }, pageLimit, 1)).then((res) => {
  //           setSkuLoad(false);
  //           if (res?.success) {
  //             setLoading(false);
  //             setCurrentPage(0);
  //             setResetClick(false)
  //             setShowActionColumn(ormeActive === "active" ? true : false)
  //             setSearchSKUs(res?.message);
  //             // console.log(hasMatchingId, 'hasMatchingId');
  //             const hasMatchingId = checkAllExist(searchSKUs, selectedRowData);
  //             setSelectedAllData(hasMatchingId)
  //           } else {
  //             console.log(res, "error");
  //             setLoading(false);
  //           }
  //         });
  //       }, 300);

  //       return () => clearTimeout(getData);
  //     }
  //   }
  //   else {
  //     setLoading(false);
  //   }
  // }, [tempSKU, sortOn, sortBy, resetClick, ormeActive]);
  useEffect(() => {
    // if (ormeActive === "active") {
    setSelectedRowData([])
    setSelectedRowKeys([])
    // }
  }, [ormeActive])
  useEffect(() => {
    const checkIfTop = () => {
      // Set affixed to false if the user has scrolled to the top of the page
      if (window.pageYOffset === 0) {
        setAffixed(false);
      }
    };

    window.addEventListener('scroll', checkIfTop);

    return () => {
      window.removeEventListener('scroll', checkIfTop);
    };
  }, []);
  useEffect(() => {
    const hasMatchingId = checkAllExist(searchSKUs, selectedRowData);
    setSelectedAllData(hasMatchingId)
  }, [selectedRowData, searchSKUs])
  const handleReset = (res) => {
    setResetClick(true)
    setLoading(true);
    setTempSKU();
    setTempTitle();
    setFilterSKU();
    setFilterTitle();
    setSortOn();
    setSortBy();
    // setSortOn("stock");
    // setSortBy("asc");
    setInvSort("inventoryLowToHigh");
    setResetKey(prevKey => prevKey + 1);
    setCurrentPage(0);
    setOrmeActive("all")
    setShowActionColumn(false)
    setSelectedRowData([])
    setSelectedRowKeys([])
    dispatch(scGetAllProduct(1, limit, sc_token,)).then((res) => {
      if (res.products) {
        setSearchSKUs(res?.products);
        setLoading(false);
      } else {
        console.log(res, "error");
        setLoading(false);
      }
    });
    // dispatch(searchBySkuAndTitle({ sku: "", sort_on: "stock", sort: "asc", is_orme_product: false }, pageLimit, 1)).then((res) => {
    //   if (res.success) {
    //     setLoading(false);
    //     setSearchSKUs(res?.message);
    //   } else {
    //     setLoading(false);
    //   }
    // });
    // setResetClick(false)
    // setLoading(false);
  };


  // useEffect(() => {
  //   const getData = setTimeout(() => {
  //     setSkuLoad(true)
  //     dispatch(scGetProductSku(tempSKU, sc_token)).then((res) => {
  //       console.log(res, 'sku res');
  //       setSkuLoad(false)
  //       if (res?.success) {
  //         setSearchSKUs(res?.message)
  //       }

  //     })

  //   }, 1000)

  //   return () => clearTimeout(getData)
  // }, [tempSKU])

  // console.log(selectedAllData, 'selectedAllData');
  // console.log(selectedRowData, 'selectedRowData');
  const addTOORME = (res) => {
    let data = selectedRowData.map((val) => {
      return (
        {
          id: val?.id,
        }
      )
    })
    const commaSeparatedValues = selectedRowData.map(item => item.id).join(",");

    Swal.fire({
      title: `${`Are you sure you want to Delete ${selectedRowData.length} Product${selectedRowData.length > 1 ? "s" : ""}?`}`,
      icon: "warning",
      cancelButtonText: "No",
      showCancelButton: true,
      confirmButtonColor: "#052977",
      cancelButtonColor: "#d33",
      confirmButtonText: `Yes`,
    }).then((result) => {
      if (result.isConfirmed) {
        setLoading(true);
        // setResetClick(true)
        // setTempSKU();
        // setTempTitle();
        // setFilterSKU();
        // setFilterTitle();
        // setSortOn();
        // setSortBy();
        // setInvSort("inventoryLowToHigh");
        // setResetKey(prevKey => prevKey + 1);
        // setCurrentPage(0);

        // setSpin(true);

        dispatch(scDeleteProduct(commaSeparatedValues, sc_token)).then((res) => {
          if (res?.success) {
            // setLoading(false);
            notification.success({
              message: res.message,
              className: "toast-success",
            });
            setSelectedRowData([])
            setSelectedRowKeys([])
            setLoading(true)
            setTempSKU();
            setFilterSKU();
            setResetKey(prevKey => prevKey + 1);
            dispatch(scGetAllProduct(1, limit, sc_token)).then((res) => {
              if (res.products) {
                setSearchSKUs(res?.products);
                setLoading(false);
              } else {
                setLoading(false);
              }
            });
          } else {
            setLoading(false);
            setSelectedRowData([])
            setSelectedRowKeys([])
          }
        })
          .catch((err) => {
            setLoading(false);
            notification.error({
              message: err.payload.message,
              className: "toast-error",
            });
            setSelectedRowData([])
            setSelectedRowKeys([])
          });


      }
    });

  };
  const handleDeactive = (e) => {
    let data = [
      {
        id: e,
        is_orme_product: false
      }
    ]
    Swal.fire({
      title: `Are You Sure You Want To Deactive This Product?`,
      icon: "warning",
      cancelButtonText: "No",
      showCancelButton: true,
      confirmButtonColor: "#052977",
      cancelButtonColor: "#d33",
      confirmButtonText: `Yes`,
    }).then((result) => {
      if (result.isConfirmed) {
        setLoading(true);
        // setResetClick(true)
        // setTempSKU();
        // setTempTitle();
        // setFilterSKU();
        // setFilterTitle();
        // setSortOn();
        // setSortBy();
        // setInvSort("inventoryLowToHigh");
        // setResetKey(prevKey => prevKey + 1);
        // setCurrentPage(0);

        // setSpin(true);
        dispatch(addToOrme({ data })).then((res) => {
          if (res?.success) {
            // setLoading(false);
            notification.success({
              message: res.message,
              className: "toast-success",
            });
            // setTimeout(() => {
            dispatch(searchBySkuAndTitle({ sku: tempSKU ? tempSKU : "", sort_on: sortOn ? sortOn : "stock", sort: sortBy ? sortBy : "asc", is_orme_product: ormeActive === "active" ? true : false }, pageLimit, 1)).then((res) => {
              if (res.success) {
                setLoading(false);
                setSearchSKUs(res?.message);
              } else {
                setLoading(false);
              }
            });
            // }, 500);

          } else {
            setLoading(false);
          }
        })
          .catch((err) => {
            setLoading(false);
            notification.error({
              message: err.payload.message,
              className: "toast-error",
            });
            setSelectedRowData([])
            setSelectedRowKeys([])
          });
      }
    });

  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setLoading(true);
    dispatch(scGetAllProduct(1, limit, sc_token, ormeActive === "all" ? undefined : ormeActive, tempSKU)).then((res) => {
      if (res.products) {
        setSearchSKUs(res?.products);
        setCurrentPage(0);
        setLoading(false);
      } else {
        console.log(res, "error");
        setLoading(false);
      }
    });
    // dispatch(
    //     getInventory(
    //         pageLimit,
    //         1,
    //         "active",
    //         startDate,
    //         endDate,
    //         gender,
    //         category,
    //         subCategory,
    //         filterSKU,
    //         invSort,
    //         idType,
    //         searchID?.value
    //     )
    // ).then((res) => {
    //     setSpin(false);
    //     setCurrentPage(0);
    // });
  };

  const handleChange = (e) => {
    setInvSort(e)
    if (e === "inventoryLowToHigh") {
      setSortOn("stock");
      setSortBy("asc");
    } else if (e === "inventoryHighToLow") {
      setSortOn("stock");
      setSortBy("desc");
    } else if (e === "priceLowToHigh") {
      setSortOn("price");
      setSortBy("asc");
    } else if (e === "priceHighToLow") {
      setSortOn("price");
      setSortBy("desc");
    }
  };

  const handleOk = () => {
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    form.resetFields();
    setShowBulkModal(false)
    setIsModalOpen(false);
    setDataDetail("");
    setVariantData([]);
    setSortField(null);
    setSortDirection('asc');
  };

  const handleCheckboxChange = (e, record) => {
    // console.log(record, 'record');
    const key = record.key;
    const selectedKeys = [...selectedRowKeys];
    const selectedData = [...selectedRowData]; // Assuming selectedRowData is the state to store selected data

    if (e.target.checked) {
      selectedKeys.push(key);
      selectedData.push(record);
    } else {
      const index = selectedKeys.indexOf(key);
      if (index !== -1) {
        selectedKeys.splice(index, 1);
        selectedData.splice(index, 1);
      }
    }

    setSelectedRowKeys(selectedKeys);
    setSelectedRowData(selectedData); // Update the state with selected data
  };

  const handleSelectAll = (e) => {
    if (e.target.checked) {
      // setSelectedAllData(false)
      const selectedKeysSet = new Set(selectedRowKeys);
      const newSelectedKeys = [...selectedRowKeys];
      const newSelectedData = [...selectedRowData];

      // Add all keys to the Set
      searchSKUs.forEach(it => selectedKeysSet.add(it.id));

      // Add new selected keys to the state
      selectedKeysSet.forEach(key => {
        if (!newSelectedKeys.includes(key)) {
          newSelectedKeys.push(key);
        }
      });

      // Add new selected data to the state
      searchSKUs.forEach(it => {
        if (!selectedRowData.some(row => row.id === it.id)) {
          newSelectedData.push(it);
        }
      });

      setSelectedRowKeys(newSelectedKeys);
      setSelectedRowData(newSelectedData);
      const hasMatchingId = checkAllExist(searchSKUs, selectedRowData);
      setSelectedAllData(hasMatchingId)
    } else {
      // setSelectedAllData(true)
      // Filter out the keys and data that are not in the current page
      const filteredKeys = selectedRowKeys.filter(key => !searchSKUs.some(item => item.id === key));
      const filteredData = selectedRowData.filter(item => !searchSKUs.some(it => it.id === item.id));

      setSelectedRowKeys(filteredKeys);
      setSelectedRowData(filteredData);
    }
  };
  function checkAllExist(searchSkus, selectedrow) {
    // If selectedrow is empty, return false
    if (selectedrow.length === 0) {
      return false;
    }

    // Check if every searchSKU exists in selectedrow
    // console.log({ searchSKUs, selectedRowData });
    return searchSkus.every(sku => selectedrow.some(row => row.id === sku.id));
  }
  const changeStatusProduct = (record) => {
    Swal.fire({
      title: `${`Are you sure you want to ${record.status === "active" ? "Draft" : "Active"} this Product`}`,
      icon: "warning",
      cancelButtonText: "No",
      showCancelButton: true,
      confirmButtonColor: "#052977",
      cancelButtonColor: "#d33",
      confirmButtonText: `Yes`,
    }).then((result) => {
      if (result.isConfirmed) {
        setLoading(true);
        dispatch(scStatusProduct(record.id, record.status === "active" ? "draft" : "active", sc_token)).then((res) => {
          if (res?.success) {
            // setLoading(false);
            notification.success({
              message: res.message,
              className: "toast-success",
            });
            // setOrmeActive("all")
            // setTempSKU();
            // setFilterSKU();
            // setResetKey(prevKey => prevKey + 1);
            setSelectedRowData([])
            setSelectedRowKeys([])
            setLoading(true)
            dispatch(scGetAllProduct(1, limit, sc_token, ormeActive === "all" ? undefined : ormeActive, tempSKU)).then((res) => {
              if (res.products) {
                setSearchSKUs(res?.products);
                setLoading(false);
              } else {
                console.log(res, "error");
                setLoading(false);
              }
            });
          } else {
            setLoading(false);
            setSelectedRowData([])
            setSelectedRowKeys([])
          }
        })
          .catch((err) => {
            setLoading(false);
            notification.error({
              message: err.payload.message,
              className: "toast-error",
            });
            setSelectedRowData([])
            setSelectedRowKeys([])
          });
      }
    });

  };


  const changeMultipleStatusProduct = (status) => {
    const commaSeparatedValues = selectedRowData.map(item => item.id).join(",");
    Swal.fire({
      title: `${`Are you sure you want to ${status === "active" ? "Active" : "draft"} ${selectedRowData.length} Product${selectedRowData.length > 1 ? "s" : ""}?`}`,
      icon: "warning",
      cancelButtonText: "No",
      showCancelButton: true,
      confirmButtonColor: "#052977",
      cancelButtonColor: "#d33",
      confirmButtonText: `Yes`,
    }).then((result) => {
      if (result.isConfirmed) {
        setLoading(true);
        dispatch(scStatusProduct(commaSeparatedValues, status, sc_token)).then((res) => {
          if (res?.success) {
            // setLoading(false);
            notification.success({
              message: res.message,
              className: "toast-success",
            });
            // setOrmeActive("all")
            // setTempSKU();
            // setFilterSKU();
            // setResetKey(prevKey => prevKey + 1);
            setSelectedRowData([])
            setSelectedRowKeys([])
            setLoading(true)
            dispatch(scGetAllProduct(1, limit, sc_token, ormeActive === "all" ? undefined : ormeActive, tempSKU)).then((res) => {
              if (res.products) {
                setSearchSKUs(res?.products);
                setLoading(false);
              } else {
                // console.log(res, "error");
                setLoading(false);
              }
            });
          } else {
            setLoading(false);
            setSelectedRowData([])
            setSelectedRowKeys([])
          }
        })
          .catch((err) => {
            setLoading(false);
            notification.error({
              message: err.payload.message,
              className: "toast-error",
            });
            setSelectedRowData([])
            setSelectedRowKeys([])
          });
      }
    });

  };

  const content = (record) => {
    return (

      <>
        <a className={` balance-btn default-btn  medium-btn padb-3 ml-`} onClick={(e) => {
          {
            e.stopPropagation()
            changeStatusProduct(record)
          }
        }}
        >
          {record.status === "active" ? "Set as draft" : "Set as active"}
        </a>
        <Button onClick={(e) => { e.stopPropagation(); setOpen(false) }} className='h-0 pw-2 default-btn small-btn outline'>Close</Button >
      </>
    )
  }

  const handleOpenChange = (newOpen) => {
    setOpen(newOpen);
  };
  const columns = [
    {
      title: 'S.No',
      align: "left",
      render: (text, record, index) => {
        return (
          <>
            {(index + 1) + (currentPage * 20)}
          </>
        );
      },
    },
    {
      title: 'SKU',
      dataIndex: 'sku',
      key: 'sku',
      align: "left",
      render: (text, record, index) => {
        return (
          <>
            {record?.variants[0]?.sku ? record?.variants[0]?.sku : '-'}
          </>
        );
      },
    },
    {
      title: 'Image',
      dataIndex: 'Image',
      key: "image",
      align: "left",
      render: (text, record, index) => {
        return (
          <div className="listing-image inventory-image text-center">
            <Image
              onClick={(e) => {
                e.stopPropagation();
                handleImageClick(record?.image?.src ? record?.image?.src : defaultImage)
              }
              }
              src={record?.image?.src ? record?.image?.src : defaultImage}
              preview={false}
            />
          </div>
        );
      },
    },
    {
      title: 'Product',
      dataIndex: 'Title',
      key: "title",
      align: "left",
      width: 300,
      render: (text, record, index) => {
        return (
          <>
            <span title={record?.title}>
              {record?.title && record?.title.length > 30
                ? record?.title
                  .replace(/<\/?[^>]+(>|$)/g, "")
                  .slice(0, 30) + "..."
                : record?.title}
            </span>
          </>
        );
      },
    },
    {
      title: 'Status',
      dataIndex: 'Status',
      key: "status",
      align: "left",
      render: (text, record, index) => {
        return (
          <>
            {/* $ */}
            {/* {record?.status === "active" ? <Tag color="green">Active</Tag> : <Tag color="blue">Draft</Tag>} */}
            <Popover
              content={content(record)}
              title="Select Option"
              trigger="click"
              open={open && index === selectIndex}
              onClick={(e) => {
                if (record?.status === 'active' || record?.status === 'draft') {
                  e.stopPropagation();
                  handleOpenChange();
                  setSelectIndex(index)
                }
              }}
              placement="left"
              className="cursor-pointer"
            >
              {record?.status === "active" ? <Tag color="green">Active <DownOutlined className="status-icon" /></Tag> : record?.status === "draft" ? <Tag color="blue">Draft <DownOutlined className="status-icon" /></Tag> : "-"}
            </Popover>
          </>
        );
      },
    },
    // {
    //   title: 'Inventory',
    //   dataIndex: 'Inventory',
    //   key: "inventory",
    //   align: "left",
    //   render: (text, record, index) => {
    //     return (
    //       <>
    //         {getInventory(record)}
    //       </>
    //     );
    //   },
    // },
    {
      title: 'Price',
      dataIndex: 'Price',
      key: "price",
      align: "left",
      render: (text, record, index) => {
        return (
          <>
            {/* $ */}
            {record?.variants[0]?.price && numeral(record?.variants[0]?.price).format('$0,0.00')}
          </>
        );
      },
    },
    {
      title: 'Inventory',
      dataIndex: 'Inventory',
      key: "stock",
      align: "left",
      width: 300,
      render: (text, record, index) => {
        return (
          <div className="balance-area justify-content-between ">
            {/* <div className="balance-count  w-92"> */}

            {getInventory(record)}
            {/* {numeral(record?.variants?.reduce((acc, it) => {
                return (acc = acc + it.inventory_quantity);
              }, 0)).format('0,0')} {" "}
              {numeral(record?.variants?.reduce((acc, it) => {
                return (acc = acc + it.inventory_quantity);
              }, 0)).format('0,0') < 0 && <Tag color="orange" className="m-0 fs-10">Pre Order</Tag>} */}
            {/* </div> */}
            <div className="balance-area">
              <a
                className="balance-btn default-btn outline small-btn ml-2"
                onClick={(e) => {
                  e.stopPropagation();
                  showDetailModal(record);
                }}
              >
                View
              </a>
            </div>
          </div>
        );
      },
    },
    {
      title: '',
      dataIndex: '',
      key: "",
      align: "",
      width: 60,
    },
    // {
    //   title: 'Inventory Level',
    //   dataIndex: 'Inventory Level',
    //   key: "stocklevel",
    //   align: "left",
    //   render: (text, record, index) => {
    //     return (
    //       <>
    //         {getStatus(record)}
    //       </>
    //     );
    //   },
    // },
    {
      title: (
        // ormeActive === "active" ? <Checkbox
        //   checked={searchSKUs.length > 0} defaultChecked disabled
        //   className="disable-checkbox"
        //   onChange={handleSelectAll}
        // />
        //   : 
        searchSKUs.length > 0 &&
        <>
          <span> Select All</span>
          <Tooltip placement="topLeft" title={getProduct && getProduct?.loading ? "Please wait while products are syncing" : ""}>
            <Checkbox
              disabled={getProduct && getProduct?.loading}
              checked={selectedAllData}
              onChange={handleSelectAll}
              className="select-all-checkbox ml-2"
            />
          </Tooltip>
        </>
      ),
      dataIndex: 'select',
      key: 'select',
      align: "right",
      width: "100",
      render: (_, record) => {
        // if (record?._source?.is_orme_product) {
        //   return <Checkbox checked defaultChecked disabled className="disable-checkbox" />
        // } else {
        return (
          <Tooltip placement="left" title={getProduct && getProduct?.loading ? "Please wait while products are syncing" : ""}>
            <Checkbox
              disabled={getProduct && getProduct?.loading}
              checked={selectedRowKeys.includes(record.key)}
              onChange={(e) => handleCheckboxChange(e, record)}
              onClick={(e) => e.stopPropagation()}
            />
          </Tooltip>
        )
        // }
      },
    },
    // {
    //   title: 'Action',
    //   dataIndex: 'Action',
    //   key: "action",
    //   align: "center",
    //   render: (text, record, index) => {
    //     return (
    //       <>
    //         <div className="text-center">
    //           <a
    //             className="default-btn outline small-btn deactive-btn"
    //             onClick={(e) => {
    //               handleDeactive(record?._source?.id)
    //             }}
    //           >
    //             De-Active
    //           </a>
    //         </div>
    //       </>
    //     );
    //   },
    //   hidden: true,
    // },
  ];
  const onSelectChange = (newSelectedRowKeys) => {
    const uniqueSelectedRowKeys = new Set([...newSelectedRowKeys]);
    setSelectedRowKeys(Array.from(uniqueSelectedRowKeys));
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };
  const getSortIcon = (field) => {
    if (field !== sortField) return <FontAwesomeIcon icon={faSort} />;
    return sortDirection === 'asc' ? <FontAwesomeIcon icon={faSortUp} /> : <FontAwesomeIcon icon={faSortDown} />;
  };
  const sortData = (field) => {
    if (sortField === field) {
      setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc');
    } else {
      setSortField(field);
      setSortDirection('asc');
    }

    const sortedData = [...variantData].sort((a, b) => {
      if (field === 'price') {
        return sortDirection === 'asc' ? a[field] - b[field] : b[field] - a[field];
      } else {
        return sortDirection === 'asc' ? a["inventory_quantity"] - b["inventory_quantity"] : b["inventory_quantity"] - a["inventory_quantity"];
      }
    });

    setVariantData(sortedData);
  };
  const handlePage = (link, menu) => {
    history(link, {
      state: { key: link.split("/").length > 2 ? link.split("/")[2] : link.split("/")[1], openKey: menu }
    }
    );
  }

  const handleImageClick = (src) => {
    setCurrentImageSrc(src);
    setIsModalVisible(true);
  };

  const handleCloseModal = (e) => {
    // e.stopPropagation()
    setIsModalVisible(false);
    setCurrentImageSrc(null);
  };
  return (
    <>
      <div className="top-btn-area mb-10 d-flex justify-content-between align-items-center">
        {validate?.payload?.message?.account_type === "brand" &&
          <>
            {/* <button
              onClick={() => setModal(true)}
              className="default-btn d-flex align-items-center justify-content-center m-0 create-event-btn"
            >
              <span className="nav-text ">Bulk Upload</span>
            </button> */}

            <div className="d-flex flex-row">
              <Link
                to="/sc-add-product"
                className="default-btn d-flex align-items-center justify-content-center m-0 create-event-btn mr-2"
              >
                <FontAwesomeIcon icon={faPlus} />
                <span className="nav-text ">Add Product</span>
              </Link>
              <Button
                onClick={() => setShowBulkModal(true)}
                className="ant-btn ant-btn-primary default-btn padb-3 small-btn m-width-100 ml-0  mb-1 fw-normal h-30 px-4"
              >
                <FontAwesomeIcon icon={faUpload} />
                Import
              </Button>
            </div>
            <span> {validate?.payload?.message?.seller_center ?
              <div className="mb-2 fw-bold">Total <span className="source_cap">{ormeActive === 'all' ? '' : ormeActive}</span> Products: {(getProduct && getProduct?.loading || loading || skuLoad) ? <span className="spin-color mx-2">
                <Spin size="small" />
              </span> : numeral(scGetAllProducts?.total).format("0,0")}</div> : <></>}
            </span>

          </>
        }
      </div>
      <div className="sales-page-main">
        <div className="title-area pb-0 justify-content-between">
          <div className="d-flex align-items-center">
            <h1 className="mb-0 pb-0">Manage Products</h1>
            {validate?.payload?.message?.last_sync && <span className="ml-2 fw-bold">( Last Updated: {moment(validate?.payload?.message?.last_sync).format("MM-DD-YYYY h:mm A")} )</span>}
          </div>


        </div>
        <p className="note-txt fw-bold">Note: After add products sync the inventory.</p>

        <div className="brand_container_main aff-payment">
          <Form>
            <div className="row w-20-desktop-main">
              <div className="col-xl-3 col-md-4 col-12 mb-15">
                <p className="mb-5rem">Search SKU</p>
                <Select

                  showSearch
                  size="large"
                  className="w-100"
                  key={resetKey}
                  type="Number"
                  // notFoundContent={skuLoad ? <Spin size="small" /> : <Empty />}
                  loading={skuLoad}
                  optionFilterProp="children"
                  placeholder="Enter SKU"
                  suffixIcon={null}
                  onChange={(e) => setFilterSKU(e)}
                  onSearch={(e) => setTempSKU(e)}
                  filterOption={(input, options) =>
                    options.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                  focus={false}
                  // onChange={handleFilterSKU}
                  // value={filterSKU}
                  value={filterSKU}
                  onBlur={false}
                  open={false} // for hide dropdown
                // onDropdownVisibleChange={handleDropdownVisibleChange}
                >

                </Select>
              </div>
              {/* <div className="col-xl-3 col-md-6 col-12 mb-15">
                <p className="mb-5rem">SKU</p>
                <Select
                  showSearch
                  size="large"
                  className="w-100"
                  key={4}
                  type="Number"
                  notFoundContent={skuLoad ? <Spin size="small" /> : <Empty />}
                  loading={skuLoad}
                  optionFilterProp="children"
                  placeholder="Enter SKU"
                  onChange={(e) => setFilterSKU(e)}
                  onSearch={(e) => setTempSKU(e)}
                  filterOption={(input, options) =>
                    options.children?.toLowerCase().indexOf(input?.toLowerCase()) >= 0
                  }
                  focus={false}
                  // onChange={handleFilterSKU}
                  value={filterSKU}
                  onBlur={false}
                >
                  {searchSKUs && !skuLoad &&
                    searchSKUs?.map((item, i) => {
                      return (
                        <Option value={item?.sku}>
                          {item?.sku}
                        </Option>
                      );
                    })}
                </Select>
              </div> */}

              {/* <div className="col-xl-3 col-md-4 col-12 mb-15">
                <p className="mb-5rem">Sort By</p>
                <Select
                  className="w-100 select-style dark-field"
                  placeholder="Select Group By"
                  size="large"
                  focus={false}
                  loading={loading}
                  disabled={loading}
                  onBlur={false}
                  value={invSort}
                  onChange={(e) => handleChange(e)}
                  defaultValue="inventoryLowToHigh"
                >
                  <Option value="inventoryLowToHigh">Stock - Low to High</Option>
                  <Option value="inventoryHighToLow">Stock - High to Low</Option>
                  <Option value="priceLowToHigh">Price - Low to High</Option>
                  <Option value="priceHighToLow">Price - High to Low</Option>

                </Select>
              </div> */}
              <div className="col-xl-3 col-md-4 col-12 mb-15">
                <p className="mb-5rem">Status</p>
                <Select
                  className="w-100 select-style dark-field"
                  placeholder="Select Group By"
                  size="large"
                  focus={false}
                  loading={loading}
                  disabled={loading}
                  onBlur={false}
                  value={ormeActive}
                  onChange={(e) => setOrmeActive(e)}
                  defaultValue={"all"}
                >
                  <Option value="all">All</Option>
                  <Option value="active">Active</Option>
                  <Option value="draft">Draft</Option>

                </Select>
              </div>
              <div className="col-xl-4 col-md-12 col-12 d-flex align-items-end mb-15">
                <Button
                  onClick={handleSubmit}
                  className="default-btn fltr-hpr  medium-btn m-width-100 ml-0 fw-normal"
                  type="primary"
                  htmlType="submit"
                  disabled={loading || skuLoad}
                >
                  Submit
                </Button>
                <Button
                  onClick={handleReset}
                  className="default-btn outline fw-normal fltr-hpr medium-btn  res-btn2 min-w-100"
                  type="primary"
                  htmlType="submit"
                  disabled={loading || skuLoad}
                >
                  Reset
                </Button>
              </div>
            </div>
            {
              selectedRowData.length > 0 &&
              <>
                <Affix offsetTop={0}
                  className="affix-container"
                  // style={{ position: "absolute", top: 60, right: 30 }}
                  onChange={(affixed) => setAffixed(affixed)}
                >
                  <div className={affixed ? 'affix-background' : ''}>
                    <span className="mb-10 mx-2">Selected {selectedRowData.length} products</span>
                    {
                      selectedRowData.length > 0 && selectedRowData.some(item => item.status === "draft") &&
                      <Button
                        onClick={() => changeMultipleStatusProduct("active")}
                        className="default-btn fltr-hpr  medium-btn m-width-100 ml-0 fw-normal"
                        type="primary"
                        htmlType="submit"
                        disabled={loading || skuLoad}
                      >
                        {"Set as active"}
                      </Button>}
                    {
                      selectedRowData.length > 0 && selectedRowData.some(item => item.status === "active") &&
                      <Button
                        onClick={() => changeMultipleStatusProduct("draft")}
                        className="default-btn fltr-hpr  medium-btn m-width-100 ml-0 fw-normal"
                        type="primary"
                        htmlType="submit"
                        disabled={loading || skuLoad}
                      >
                        {"Set as draft"}
                      </Button>}
                    <Button
                      onClick={addTOORME}
                      className="default-btn fltr-hpr  medium-btn m-width-100 ml-0 fw-normal"
                      type="primary"
                      htmlType="submit"
                      disabled={loading || skuLoad}
                    >
                      {"Delete"}
                    </Button>
                  </div>
                </Affix>
              </>
            }
          </Form>
          {/* <hr className="separator-line mt-0" /> */}
        </div>
        <div className="inventory-box-main">
          <div className="purchase-data referral-data">
            {loading ? <>
              <div className="loading-wrap">
                <span className="spinclass loader-center position-relative">
                  <Spin size="large" />
                </span>
              </div>
            </>
              :
              <>
                <Table
                  style={{ cursor: "pointer" }}
                  onRow={(record, rowIndex) => {
                    return {
                      onClick: event => { console.log("click", record); history(`/sc-add-product/${record.id}`) }, // click row
                    };
                  }}
                  className="active-table"
                  // rowSelection={rowSelection}
                  columns={columns}
                  dataSource={searchSKUs?.map((it) => ({ ...it, key: it?.id }))}
                  pagination={false}
                  scroll={{ x: 1200 }}
                  size="small"
                />
                <Modal
                  visible={isModalVisible}
                  footer={null}
                  onCancel={handleCloseModal}
                >
                  <img
                    src={currentImageSrc}
                    alt="Image"
                    style={{ width: '100%', height: "400px", objectFit: "contain" }}
                  />
                </Modal>
              </>
            }

            {!loading && scGetAllProducts?.total > limit && (
              <div style={{ position: 'relative' }}>
                {/* Total page count element with positioning */}
                <span style={{
                  position: 'absolute',
                  right: 90,
                  top: '50%',
                  transform: 'translateY(-50%)',
                  paddingRight: '10px' // adjust as needed
                }}>
                  Page {currentPage + 1} of {Math.ceil(scGetAllProducts?.total / limit)}
                </span>

                <ReactPaginate
                  nextLabel={<FontAwesomeIcon icon={faAngleRight} />}
                  onPageChange={handlePageClick}
                  pageRangeDisplayed={window.innerWidth <= 760 ? 1 : 7}
                  pageCount={scGetAllProducts?.total > 0 ? Math.ceil(scGetAllProducts?.total / limit) : 0}
                  forcePage={currentPage}
                  previousLabel={<FontAwesomeIcon icon={faAngleLeft} />}
                  pageClassName="page-item d-none"
                  pageLinkClassName="page-link"
                  previousClassName="page-item"
                  previousLinkClassName="page-link"
                  nextClassName="page-item"
                  nextLinkClassName="page-link"
                  breakLabel="..."
                  breakClassName="page-item d-none"
                  breakLinkClassName="page-link"
                  containerClassName="pagination custom-paginate"
                  activeClassName="active"
                  renderOnZeroPageCount={null}
                />
              </div>
            )}
            <Modal
              maskClosable={false}
              className="modal-generic modal-1300 product-detail"
              centered
              visible={isModalOpen}
              onOk={handleOk}
              onCancel={handleCancel}
              closeIcon={<FontAwesomeIcon icon={faXmark} style={{ marginTop: "20px" }} />}
              footer={false}
            >
              <h2 className="modal-hd1 d-flex align-items-center justify-content-between">
                <div>
                  Product Details
                </div>
                {/* <div className="mr-6">
                  <PDFDownloadLink document={<PDF item={dataDetails} />} fileName={`worksheet-${dataDetails?.variants?.[0]?.sku}-${dataDetails?.title}.pdf`}>
                    <Button
                      className="balance-btn default-btn outline small-btn"
                      type="primary"
                      size="large"
                      title="Download Worksheet"
                    >
                      <FontAwesomeIcon
                        size="md"
                        className="event-icon"
                        icon={faPrint}
                      />
                      Print</Button>
                  </PDFDownloadLink>
                </div> */}
              </h2>

              <div className="row">
                <div className="col-md-3 mb-3">
                  <Carousel>
                    {dataDetails?.images?.map((item, i) => {
                      return (
                        <div>
                          <img
                            src={`${item?.src ? item.src : defaultImage}`}
                            className="img-fluid"
                          />
                        </div>
                      );
                    })}
                  </Carousel>
                </div>
                <div className="col-md-4 items-column">
                  <div className="product-items">
                    <span className="product-data">Product Title :</span>
                    <span className="product-desc">
                      {dataDetails?.title}
                    </span>
                  </div>
                  <div className="product-items">
                    <span className="product-data">Product ID :</span>
                    <span className="product-desc">
                      {dataDetails && dataDetails?.variants[0]?.product_id}
                    </span>
                  </div>
                  <div className="product-items">
                    <span className="product-data">Product SKU :</span>
                    <span className="product-desc">
                      {dataDetails && dataDetails?.variants[0]?.sku
                        ? dataDetails?.variants[0]?.sku
                        : "-"}
                    </span>
                  </div>
                  {/* <div className="product-items">
                    <span className="product-data">Product Link:</span>
                    <span className="product-desc">
                      <a
                        target="_blank"
                        href={
                          "https://" +
                          dataDetails?.domain?.replace(/^https?:\/\//, '') +
                          "/products/" +
                          dataDetails?.handle
                        }
                      >
                        {"https://" + dataDetails?.domain?.replace(/^https?:\/\//, '') + "/..."}
                      </a>
                    </span>
                  </div> */}

                  <div className="product-items">
                    <span className="product-data">Product Price :</span>
                    <span className="product-desc">
                      ${dataDetails && dataDetails?.variants[0]?.price}
                    </span>
                  </div>
                  {dataDetails && dataDetails?.body_html?.length > 0 &&
                    <div className="product-items">
                      <span className="product-data">
                        Product Description :
                      </span>
                      <span className="product-desc">
                        <TextArea
                          className="border-textarea-light"
                          style={{
                            height: 120,
                            resize: 'none',
                          }}
                          rows={4}
                          value={
                            dataDetails && dataDetails?.body_html
                              ? dataDetails?.body_html.replace(
                                /<\/?[^>]+(>|$)/g,
                                ""
                              )
                              : ""
                          }
                        />
                      </span>
                    </div>}
                  {dataDetails && dataDetails?.tags?.length > 0 &&
                    <div class="product-items">
                      <span className="product-data">Product Tags:</span>
                      <div className="product-desc prodcut-tags-scroll scrollbar-style">
                        <Space size={[0, 8]} wrap>
                          {dataDetails?.tags?.split(",")?.map((item, i) => {
                            return <Tag>{item}</Tag>;
                          })}
                        </Space>
                      </div>
                    </div>}
                </div>
                <div className="col-md-5">
                  <div className="co mt3">
                    <h4>Variants</h4>

                    <div className="table-container inventory-table new-height">
                      <table class={`table m-0 ${dataDetails?.options?.length && dataDetails?.options?.length + 2 == 5 ? "width-item-5" : ""} ${dataDetails?.options?.length && dataDetails?.options?.length + 2 == 6 ? "width-item-6" : ""}`}>
                        <thead>
                          <tr>
                            <th className="width-15 border-bottom-0">S.#</th>
                            {dataDetails?.options?.map((item, i) => {
                              return (
                                <th className="width-25 border-bottom-0">{item.name}</th>
                              );
                            })}
                            <th className="width-25 border-bottom-0"
                            // onClick={() => sortData('stock')}
                            >
                              Stock
                              {/* {getSortIcon('stock')} */}
                            </th>
                            <th className="width-25 border-bottom-0"
                            // onClick={() => sortData('price')}
                            >
                              Price
                              {/* {getSortIcon('price')} */}
                            </th>
                          </tr>
                        </thead>
                      </table>
                      <div className="table-body scrollbar-style">
                        <table class={`table ${dataDetails?.options?.length && dataDetails?.options?.length + 2 == 5 ? "width-item-5" : ""} ${dataDetails?.options?.length && dataDetails?.options?.length + 2 == 6 ? "width-item-6" : ""}`}>
                          <tbody>
                            {variantData?.map((item, i) => {
                              return (
                                <tr>
                                  <td className="width-15">{i + 1}</td>
                                  {item.option1 && <td className="width-25">{item.option1}</td>}
                                  {item.option2 && (
                                    <td className="width-25">
                                      {item.option2}
                                    </td>
                                  )}
                                  {item.option3 && (
                                    <td className="width-25">
                                      {item.option3}
                                    </td>
                                  )}
                                  <td className="width-25">
                                    {item.inventory_quantity}
                                  </td>
                                  <td className="width-25">${item.price}</td>
                                </tr>
                              );
                            })}
                          </tbody>
                        </table>
                      </div>
                    </div>


                    {/* <Table
                        pagination={false}
                        // columns={skuNewArr?.[viewIndex]}
                        columns={columnDetails}
                        size="small"
                        className="product-listing-tabl inventory-box-main details-table"
                        //  rowSelection={{ ...rowSelection }}
                        dataSource={dataDetails?.variants}
                        // scroll={
                        //     dataDetails.length > 32
                        //         ? { y: 450, x: 500 }
                        //         : false
                        // }
                        scroll={
                            dataDetails?.variants?.length > 10
                                ? { y: 400, x: 500 }
                                : { x: 500 }
                        }
                    /> */}
                  </div>
                </div>
              </div>


            </Modal>
            {/* )} */}
          </div>
        </div>

        <Modal
          className="modal-generic modal-700"
          centered
          open={showBulkModal}
          onOk={handleOk}
          onCancel={handleCancel}
          closeIcon={<FontAwesomeIcon icon={faXmark} />}
          footer={false}
          maskClosable={false}
          keyboard={false}
        // footer={[
        //   <Button
        //     key="submit"
        //     type="primary"
        //     // className="d-table m-auto"
        //     onClick={handleOk}
        //   >
        //     Save
        //   </Button>,
        //   <Button key="back" onClick={handleCancel}>
        //     Exit
        //   </Button>,
        // ]}
        >
          <h2 className="modal-hd1">Import products by CSV</h2>
          {!loading ? (
            <Form {...formProps}>
              <div className="form-styling ">
                <div className="row  d-flex align-items-center justify-content-center">

                  {/* <Button
                    className="default-btn outline d-inline-flex align-items-center justify-content-center mb-2"
                    key="back"
                    // onClick={() => downloadCSV(custom_sample)}
                    onClick={downloadCSV}
                  >
                    Download Sample CSV
                  </Button> */}
                  {/* <a href={custom_sample} download="your_file_name.csv">
          <button>Download CSV</button>
        </a> */}
                </div>
                {/* <div>
        <h5>
          CSV Uploading Detail
        </h5>
        <ul>
          <li>...</li>
          <li>...</li>
          <li>...</li>
          <li>...</li>
        </ul>
      </div> */}
                <div className="mb-2">
                  <Form.Item
                    name="csv_file"
                    valuePropName={"fileList"}
                    getValueFromEvent={normFile}
                    noStyle
                    multiple={false}
                  >
                    <Upload.Dragger
                      customRequest={dummyRequest}
                      {...uploadFileDragger}
                      name="csv_file"
                      // className={`upload_area_2 m-auto mb-20 position-relative uploadWidth full-width`}
                      className="upload-image-review ecommerce-dragger sc-import-product"
                    >
                      {/* <FontAwesomeIcon icon={faCloudArrowUp} className="mt-0" /> */}
                      <p>Drag & Drop Your File Here</p>
                      {/* <h4>Or</h4>
            <Button
              className="default-btn medium-btn txt-btn-pad padb-3">
              <FontAwesomeIcon icon={faArrowUpFromBracket} />
              Select File</Button> */}
                    </Upload.Dragger>
                  </Form.Item>
                  {/* {progress > 0 && <Progress percent={progress} />} */}
                </div>
                <div className="d-flex justify-content-between align-items-center">
                  {/* <Button
                    className=" outline d-inline-flex align-items-center justify-content-center"
                    key="back"
                    // onClick={() => downloadCSV(custom_sample)}
                    onClick={downloadCSV}
                  >
                    Download Sample CSV
                  </Button> */}
                  <a href="https://d1hq75pouwojqi.cloudfront.net/product_template.csv" rel="noopener noreferrer" target="_blank"
                    className="default-btn small-btn outline d-inline-flex">
                    Download Sample CSV
                  </a>
                  <div>
                    <Button
                      // disabled={getProduct && getProduct?.loading}
                      // to="#"
                      className="default-btn d-inline-flex align-items-center justify-content-center"
                      // onClick={submit}
                      disabled={CSVFile === undefined}
                      onClick={handleUpload}
                    >
                      <span className="nav-text">Upload</span>
                    </Button>
                    <Button
                      className="default-btn outline d-inline-flex align-items-center justify-content-center"
                      key="back"
                      onClick={handleCancel}
                    >
                      Exit
                    </Button>
                  </div>
                </div>
              </div>
            </Form>
          ) : (
            <span className="spinclass loader-center position-relative">
              <Spin size="large" />
            </span>
          )}
        </Modal>



      </div>
    </>
  );
}
