import React, { useState, useEffect } from "react";
import { Table as BootstrapTable } from "react-bootstrap";
import { Modal } from "react-bootstrap";
import {
    Modal as ModalA,
    Button, Dropdown, Form, Input, InputNumber, Popover, Space, Tag, DatePicker, Spin, Select, Empty, Table, Menu, Typography, Checkbox, notification, Affix, Upload,
    message
} from "antd";
import {
    customerorderHistory,
    details,
    getPurchaser,
    getReferrer,
} from "../../redux/actions/purchaseHistory.action";
import { useStepsForm } from "sunflower-antd";
import { useSelector, useDispatch } from "react-redux";
import axios from "axios";
import AsyncSelect from "react-select/async";
import ReactPaginate from "react-paginate";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleLeft, faAngleRight, faXmark, faUpload } from "@fortawesome/free-solid-svg-icons";
// import AsyncCustomer from "./getCustomer/customer";
import "../../css/sales.scss";
import moment from "moment";
import numeral from "numeral";
import { getSavedCategories, getSavedGeneralCategories, getSavedSubCategories } from "../../redux/actions/newCategory.action";
import { searchEventID, searchReviewID, searchShowID } from "../../redux/actions/eventID.action";
import { visitorURL } from "../../config";
import { scGetAllOrder } from "../../redux/actions/scGetAllOrder";
import { DownOutlined, CopyOutlined } from '@ant-design/icons';
import { scGetOneOrder } from "../../redux/actions/scGetOneOrder";
import { Link, useNavigate } from "react-router-dom";
import { scUpdateOrder } from "../../redux/actions/scUpdateOrder";
const { Option } = Select;
let arrHost = [];
const { RangePicker } = DatePicker;
const { Text } = Typography;

export default () => {
    const [form] = Form.useForm();
    const [scform] = Form.useForm();
    const dateFormat = "MM-DD-YYYY";
    const dispatch = useDispatch();
    const history = useNavigate();
    const [loading, setLoading] = useState(true);

    const min_date = moment().subtract(1, "year").startOf("year").format("YYYY-MM-DD");
    const max_date = moment(new Date()).format("YYYY-MM-DD");

    const [startDate, setStartDate] = useState(min_date);
    const [endDate, setEndDate] = useState(max_date);
    const [totalEvent, setTotalEvent] = useState();
    const [currentPage, setCurrentPage] = useState(0);
    const [source, setSource] = useState("all");
    const [allCustomer, setAll] = useState([]);
    const [customer, setCustomer] = useState([]);
    const [modal, setModal] = useState(false);
    const [flag, setFlag] = useState(false);
    const [status, setStatus] = useState(false);
    const [submit, setSubmit] = useState("none");
    const [customerId, setCustomerId] = useState();
    const [gender, setGender] = useState();
    const [category, setCategory] = useState();
    const [subCategory, setSubCategory] = useState();
    const [contentSource, setContentSource] = useState("all");
    const [channel, setChannel] = useState("all");
    const [load, setLoad] = useState(false);
    const [generalLoad, setGeneralLoad] = useState(false);
    const [catLoad, setCatLoad] = useState(false);
    const [subCatLoad, setSubCatLoad] = useState(false);
    const [order, setOrder] = useState();
    const [purchaseBy, setPurchaseBy] = useState();
    const [referredBy, setReferredBy] = useState();
    const [searchPurchaseBy, setSearchPurchaseBy] = useState([]);
    const [searchReferredBy, setsearchReferredBy] = useState([]);
    const [groupBy, setGroupBy] = useState("Order");
    const [groupByTable, setGroupByTable] = useState("Order");
    const [purchaseLoad, setPurchaseLoad] = useState(false);
    const [referrerLoad, setReferrerLoad] = useState(false);
    const [searchID, setSearchID] = useState();
    const [searchIDLoad, setSearchIDLoad] = useState("");
    const [searchDataIDs, setSearchDataIDs] = useState([]);
    const [allOrders, setAllOrders] = useState([]);
    const [idType, setIdType] = useState("event");
    const [open, setOpen] = useState(false);
    const [selectIndex, setSelectIndex] = useState("");
    const [selectedRowKeys, setSelectedRowKeys] = useState([]);
    const [selectedAllData, setSelectedAllData] = useState(false);
    const [selectedRowData, setSelectedRowData] = useState([]);
    const [affixed, setAffixed] = useState(false);
    const [showBulkModal, setShowBulkModal] = useState(false);
    const [CSVFile, setCSVFile] = useState();
    const [isModalOpen, setIsModalOpen] = useState(false);
    const { shopifyCustomerorder, detailsOrder, scGetAllOrders, scGetOneOrderDetail, validate } = useSelector(
        (state) => {
            return state;
        }
    );
    const limit = 15;
    const upperLimit = (currentPage + 1) * limit;
    const lowerLimit = upperLimit - limit + 1;
    const itemsPerPage = 15;
    const pageCount = Math.ceil(totalEvent / itemsPerPage);
    const getSum = (a1, a2) => parseFloat(a1) * parseFloat(a2);
    let sc_token = JSON.parse(localStorage.getItem("sc_token"))
    useEffect(() => {
        setLoading(true);
        dispatch(scGetAllOrder(
            1,
            limit,
            sc_token,
            moment(startDate).format("YYYY-MM-DD"),
            moment(endDate).format("YYYY-MM-DD"),
        )).then((res) => {
            if (res?.success) {
                setLoading(false);
                setAllOrders(res?.orders)

            } else {
                setAllOrders([])
                console.log(res, "error");
            }
        });
        // dispatch(searchEventID("", "event", startDate, endDate)).then((res) => {
        //     setSearchIDLoad(false)
        //     if (res?.success) {
        //         setSearchDataIDs(res?.message)
        //     }
        // })
        // setLoading(true);
        // setGeneralLoad(true)
        // dispatch(getSavedGeneralCategories()).then((res) => {
        //     if (res.success) {
        //         setGeneralLoad(false)
        //     } else {
        //         setGeneralLoad(false)
        //     }
        // })

        // dispatch(
        //     customerorderHistory(1, limit, startDate, endDate, gender, category, subCategory, contentSource, "", channel, "", "", "Order")
        // ).then((res) => {
        //     setTotalEvent(res?.message?.total_records);
        //     setLoading(false);
        // });
    }, []);

    const dateRangePickerChanger = (value, dataString) => {
        const startDate = dataString[0];
        const endDate = dataString[1];
        setStartDate(startDate);
        setEndDate(endDate);
    };

    const handlePageClick = (e) => {
        const page = e.selected;
        setCurrentPage(page);
        setLoading(true);
        dispatch(scGetAllOrder(page + 1,
            limit,
            sc_token,
            moment(startDate).format("YYYY-MM-DD"),
            moment(endDate).format("YYYY-MM-DD"),
            purchaseBy,
        )).then((res) => {
            if (res?.success) {
                // console.log(res);
                setLoading(false);
                setAllOrders(res?.orders)

            } else {
                console.log(res, "error");
            }
        });
        // dispatch(
        //     customerorderHistory(
        //         page + 1,
        //         limit,
        //         moment(startDate).format("YYYY-MM-DD"),
        //         moment(endDate).format("YYYY-MM-DD"),
        //         gender,
        //         category,
        //         subCategory,
        //         contentSource,
        //         customer[0]?.value,
        //         channel,
        //         purchaseBy,
        //         referredBy,
        //         groupBy,
        //         searchID,
        //         idType
        //     )
        // ).then((res) => {
        //     setLoading(false);
        //     setTotalEvent(res?.payload?.message?.total_records);
        // });
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        setGroupByTable(groupBy)
        setLoading(true);
        setStatus(true);
        setSelectedRowData([])
        setSelectedRowKeys([])

        dispatch(
            scGetAllOrder(
                1,
                limit,
                sc_token,
                moment(startDate).format("YYYY-MM-DD"),
                moment(endDate).format("YYYY-MM-DD"),
                purchaseBy,
                // gender,
                // category,
                // subCategory,
                // contentSource,
                // customer[0]?.value,
                // channel,
                // referredBy,
                // groupBy,
                // searchID,
                // idType
            )
        ).then((res) => {
            setLoading(false);
            setAllOrders(res?.orders)
            // setTotalEvent(res?.message?.total_records);
            setCurrentPage(0);
        });
        // setSubmit(groupBy);
    };

    const detailView = (orderDetail) => {
        setModal(true);
        setLoad(true);

        dispatch(scGetOneOrder(orderDetail?.id, sc_token)).then(
            (res) => {
                setLoad(false);
            }
        );
        // dispatch(details(orderDetail?.sales_tracker_id, orderDetail?._id)).then(
        //     (res) => {
        //         setLoad(false);
        //     }
        // );
    };

    const handleReset = (res) => {
        // setIdType("event")
        // setSearchID()
        // setGroupByTable("Order")
        // setSource("all");
        // setContentSource("all")
        // setChannel("all")
        // setSubmit("none");
        setLoading(true);
        setCustomer([]);
        // setGender(null);
        // setCategory(null);
        // setSubCategory(null);
        setCurrentPage(0);
        setOrder(null)
        setPurchaseBy(null)
        // setReferredBy(null)
        // setGroupBy("Order")
        setAllOrders([])
        setSelectedRowData([])
        setSelectedRowKeys([])
        const min_date = moment().subtract(1, "year").startOf("year").format("YYYY-MM-DD");
        const max_date = moment(new Date()).format("YYYY-MM-DD");
        setStartDate(min_date);
        setEndDate(max_date);
        dispatch(
            scGetAllOrder(
                1,
                limit,
                sc_token,
                moment().subtract(1, "year").startOf("year").format("YYYY-MM-DD"),
                moment(new Date()).format("YYYY-MM-DD"),
            )
        ).then((res) => {
            setLoading(false);
            setAllOrders(res?.orders)
            // setTotalEvent(res?.message?.total_records);
        });
    };

    const handleOk = () => {
        setIsModalOpen(false);
    };

    const handleCancel = () => {

        form.resetFields();
        scform.resetFields();
        setShowBulkModal(false)
        setIsModalOpen(false);
        setCSVFile()
        // setDataDetail("");
        // setVariantData([]);
        // setSortField(null);
        // setSortDirection('asc');
    };
    const dummyRequest = ({ file, onSuccess }) => {
        setTimeout(() => {
            onSuccess("ok");
        }, 0);
    };
    const normFile = (e) => {
        if (Array.isArray(e)) {
            return e;
        }
        return e?.fileList;
    };

    const uploadFileDragger = {
        accept: "text/csv",
        name: 'file',
        multiple: false,
        maxCount: 1,
        // listType: "picture-card",
        action: "https://httpbin.org/post",
        beforeUpload: (file) => {
            const isPNG =
                file.type === "text/csv";

            if (!isPNG) {
                message.error(`${file?.name} is not a valid format`);
            }

            return isPNG || Upload.LIST_IGNORE;
        },
        onChange(info) {
            const { status } = info.file;
            if (status !== 'uploading') {
            }
            if (status === 'done') {
                // message.success(`${info.file.name} file uploaded successfully.`);
                setCSVFile(info?.file?.originFileObj)
            } else if (status === 'error') {
                message.error(`${info.file.name} file upload failed.`);
            }
        },
        onDrop(e) {

        },


        onRemove(e) {
            setCSVFile()
            // setFileList([])
        },

    };

    const loadCustomProducts = <div className="d-flex"><span className="spin-color mx-2">
        <Spin size="small" />
    </span><p>Your orders are updating</p></div>;
    const notificationKey = `open${Date.now()}`;
    const handleUpload = () => {
        setShowBulkModal(false)
        notification.open({
            message: 'Order Updating...',
            description: loadCustomProducts,
            key: notificationKey,
            duration: 0,
        })
        let formData = new FormData();
        // console.log(CSVFile, 'CSVFile');

        formData.append("file", CSVFile)


        form.resetFields();
        scform.resetFields();
        setCSVFile()
        dispatch(scUpdateOrder(formData, sc_token)).then((res) => {
            if (res.success) {
                notification.destroy({ key: notificationKey })
                notification.success({
                    message: res.message,
                    className: "toast-success",
                });
                setLoading(true);
                dispatch(scGetAllOrder(
                    1,
                    limit,
                    sc_token,
                    moment(startDate).format("YYYY-MM-DD"),
                    moment(endDate).format("YYYY-MM-DD"),
                )).then((res) => {
                    if (res?.success) {
                        setLoading(false);
                        setAllOrders(res?.orders)

                    } else {
                        setAllOrders([])
                        console.log(res, "error");
                    }
                });
            }
            else {
                notification.destroy({ key: notificationKey })
                notification.error({
                    duration: 10,
                    message: res?.response?.data?.message || "Something went wrong",
                    className: "toast-error",
                });

            }
            form.resetFields();
        })
    }
    const loadOptions = async (input, callback) => {
        await smartSearchFilter(input);

        const result = arrHost.filter((item) => {
            return {
                value: `${item.value}`,
                label: `${item.label}`,
            };
        });

        callback(result);
    };

    const smartSearchFilter = async (value) => {
        if (value.length > 0 && value.trim()) {
            await axios
                .post(`brand/reports/getCustomers`, {
                    name: value.trim(),
                })
                .then((response) => {
                    const loadHost = [];
                    const host = response.data.data;
                    if (host.length === 0) {
                        // notification.error({
                        //   message: "No Host Found",
                        //   className: "toast-error",
                        // });
                    }
                    setAll(host);
                    host.map((item) => {
                        return loadHost.push({
                            value: item?._id,
                            label: item?.first_name + " " + item?.last_name,
                        });
                    });
                    arrHost = loadHost;
                })
                .catch(function (error) {
                    // console.log(error);
                });
        }
    };

    useEffect(() => {
        document.body.classList.add("bioshop-body", "shopper-bioshop-body");
    }, []);

    const handleGender = (e) => {
        setCurrentPage(0);
        setGender(e);
        setCategory(null)
        setSubCategory(null)
        setCatLoad(true)
        dispatch(getSavedCategories(e)).then((res) => {
            if (res.success) {
                setCatLoad(false)
            } else {
                setCatLoad(false)
            }
        })

    };

    const handleCategory = (e) => {
        setCurrentPage(0);
        setCategory(e);
        setSubCategory(null)
        setSubCatLoad(true)
        dispatch(getSavedSubCategories(gender, e)).then((res) => {
            if (res.success) {
                setSubCatLoad(false)
            } else {
                setSubCatLoad(false)
            }
        })
    };

    const handleSubCategory = (e) => {
        setCurrentPage(0);
        setSubCategory(e);
    };

    const handleSource = (e) => {
        setSource(e);
    };

    const handleFilterPurchaseBy = (e) => {
        setPurchaseLoad(true)
        dispatch(getPurchaser(e)).then((res) => {
            setPurchaseLoad(false)
            if (res?.success) {
                setSearchPurchaseBy(res?.message)
            }
        })

    }



    const handleIDType = (e) => {
        setIdType(e)
        setSearchDataIDs([])
        setSearchID(null)

        switch (e) {
            case "event":
                return dispatch(searchEventID("", "event", startDate, endDate)).then((res) => {
                    setSearchIDLoad(false)
                    if (res?.success) {

                        setSearchDataIDs(res?.message)
                    }
                })
            case "show":
                return dispatch(searchShowID("", "show", startDate, endDate)).then((res) => {
                    setSearchIDLoad(false)
                    if (res?.success) {

                        setSearchDataIDs(res?.message)
                    }
                })
            case "review":
                return dispatch(searchReviewID("", "review", startDate, endDate)).then((res) => {
                    setSearchIDLoad(false)
                    if (res?.success) {

                        setSearchDataIDs(res?.message)
                    }
                })
        }
    }

    const handleReviewID = (e) => {
        setSearchIDLoad(true)
        switch (idType) {
            case "event":
                return dispatch(searchEventID(e.replaceAll("-", ''), "event", startDate, endDate)).then((res) => {
                    setSearchIDLoad(false)
                    if (res?.success) {

                        setSearchDataIDs(res?.message)
                    }
                })
            case "show":
                return dispatch(searchShowID(e.replaceAll("-", ''), "show", startDate, endDate)).then((res) => {
                    setSearchIDLoad(false)
                    if (res?.success) {

                        setSearchDataIDs(res?.message)
                    }
                })
            case "review":
                return dispatch(searchReviewID(e.replaceAll("-", ''), "review", startDate, endDate)).then((res) => {
                    setSearchIDLoad(false)
                    if (res?.success) {

                        setSearchDataIDs(res?.message)
                    }
                })
        }
    }

    const handleFilterReferredBy = (e) => {
        setReferrerLoad(true)
        dispatch(getReferrer(e)).then((res) => {
            setReferrerLoad(false)
            if (res?.success) {
                setsearchReferredBy(res?.message)
            }
        })

    }
    // This is componentWillUnmount
    useEffect(() => {
        return () => {
            //  componentWillUnmount.current = true;
            document.body.classList.remove("bioshop-body", "shopper-bioshop-body");
        };
    }, []);
    function Summary() {
        return (
            <div className="sales-summery mb-3">
                <div className="row">
                    <div className="col-lg-6 col-xl-4 mb-2">
                        <div className="summarry-box new-style top-space">
                            <h4 className="summary-hd">Summary</h4>
                            <div className="col-12 summary-detail-box">
                                <h4 className="count-title">Total Orders</h4>
                                <h3 className="count">
                                    {
                                        scGetAllOrders
                                            ?.total_records
                                    }
                                </h3>
                            </div>
                            {/* <div className="col-12 summary-detail-box">
                                <h4 className="count-title">Total Gross Sales</h4>
                                <h3 className="count">
                                    {numeral(
                                        scGetAllOrders
                                            ?.gross_amount
                                    ).format("$0,0.0'")}
                                </h3>
                            </div> */}
                            {/* <div className="col-12 summary-detail-box">
                                <h4 className="count-title">Total Return Amount</h4>
                                <h3 className="count">
                                    {numeral(
                                        shopifyCustomerorder?.payload?.message?.summary[0]
                                            ?.returned_amount
                                    ).format("$0,0.0'")}
                                </h3>
                            </div>
                            <div className="col-12 summary-detail-box">
                                <h4 className="count-title">Total Balance Amount</h4>
                                <h3 className="count">
                                    {numeral(
                                        shopifyCustomerorder?.payload?.message?.summary[0]
                                            ?.balance_amount
                                    ).format("$0,0.0'")}
                                </h3>
                            </div>
                            <div className="col-12 summary-detail-box">
                                <h4 className="count-title">Total ORME Fee</h4>
                                <h3 className="count">
                                    {numeral(
                                        shopifyCustomerorder?.payload?.message?.summary[0]?.kb_fee
                                    ).format("$0,0.0'")}
                                </h3>
                            </div>
                            <div className="col-12 summary-detail-box">
                                <h4 className="count-title">Total Net Sales</h4>
                                <h3 className="count">
                                    {numeral(
                                        shopifyCustomerorder?.payload?.message?.summary[0]?.net_brand_amount
                                    ).format("$0,0.0'")}
                                </h3>
                            </div> */}
                            {/* <div className="col-12 summary-detail-box">
                <h4 className="count-title">Total Cashback</h4>
                <h3 className="count">
                  {numeral(
                    shopifyCustomerorder?.payload?.message?.summary[0]
                      ?.cashback_amount
                  ).format("$0,0.0'")}
                </h3>
              </div>
              <div className="col-12 summary-detail-box">
                <h4 className="count-title">Total Referral Fee</h4>
                <h3 className="count">
                  {numeral(
                    shopifyCustomerorder?.payload?.message?.summary[0]
                      ?.customer_commission
                  ).format("$0,0.0'")}
                </h3>
              </div>
              <div className="col-12 summary-detail-box">
                <h4 className="count-title">Total Content Fee</h4>
                <h3 className="count">
                  {numeral(
                    shopifyCustomerorder?.payload?.message?.summary[0]
                      ?.creator_commission
                  ).format("$0,0.0'")}
                </h3>
              </div> */}

                        </div>
                    </div>
                </div>
            </div>
        );
    }

    const menu = (record) => {
        return (
            // <Menu>
            //     {record.line_items.map((item, index) => (
            //         <Menu.Item key={index}>
            //             <div style={{ display: 'flex', flexDirection: 'column' }}>
            //                 <Text strong>{item.title}</Text>
            //                 {/* <Text>{item.description}</Text> */}
            //                 <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: '4px' }}>
            //                     <Text type="secondary">{item.price}</Text>
            //                     <Text>x {item.quantity}</Text>
            //                 </div>
            //             </div>
            //         </Menu.Item>
            //     ))}
            // </Menu>
            <Menu style={{ width: 350, borderRadius: '10px', }}>
                <Menu.Item key="header" disabled style={{ padding: 10 }}>
                    <Text style={{ display: 'block', marginBottom: '8px' }}>
                        {
                            record?.fulfillments.length === 0 || record?.fulfillments?.[record?.fulfillments?.length - 1]?.status == 'cancelled' ?
                                <div className="connection-status-badge-yellow firstLetterCapital wfit">
                                    Unfulfilled
                                </div>
                                : record?.fulfillment_status === 'partial' ?
                                    <div className="connection-status-badge-grey firstLetterCapital wfit">
                                        Partially Fulfilled
                                    </div>
                                    : record?.fulfillment_status === 'fulfilled' ?
                                        <div className="connection-status-badge-grey firstLetterCapital wfit">
                                            Fulfilled
                                        </div>
                                        : "-"
                        }
                    </Text>
                    <div style={{ backgroundColor: 'white', borderRadius: '10px', border: '1px solid #f0f0f0' }}>
                        <div style={{ padding: '12px', backgroundColor: '#f0f0f0', borderTopLeftRadius: '10px', borderTopRightRadius: '10px' }}>
                            <Text strong>{record?.shipping_lines[0]?.title}</Text>
                        </div>
                        {record?.line_items?.map((item, index) => (
                            <>
                                <Menu.Item key={index} style={{ padding: 0, backgroundColor: 'transparent', }}>
                                    <div style={{
                                        padding: '4px 12px',
                                        display: 'flex',
                                        flexDirection: 'column',
                                        backgroundColor: 'white',
                                        borderBottomLeftRadius: '10px',
                                        borderBottomRightRadius: '10px',
                                        fontSize: '0.85rem'
                                    }}>
                                        <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: '2px', marginBottom: '2px', }}>
                                            <Text strong>{item?.name}</Text>
                                            {/* <Text type="secondary">$ {item.price}</Text> */}
                                            <Text>x {item?.quantity}</Text>
                                        </div>
                                        {item.title && <div style={{ backgroundColor: '#f0f0f0', width: 'fit-content', borderRadius: '10px' }}>
                                            <Text style={{ padding: '8px', }}>{item?.title}</Text>
                                        </div>}
                                        {item?.sku && <Text>{item?.sku}</Text>}
                                    </div>
                                </Menu.Item>
                            </>
                        ))}
                    </div>
                </Menu.Item>
                {/* <Menu.Divider /> */}

            </Menu>
        )
    };
    const menuDeliveryStatus = (record) => {
        return (
            <Menu style={{ width: 300, borderRadius: '10px' }}>
                {
                    // record?.fulfillments?.length > 0 &&
                    record?.fulfillments?.map((item, index) => (
                        (item?.status == 'success' && (item?.tracking_company !== null || item?.tracking_number !== null)) &&

                        <Menu.Item key="header" style={{ padding: 10 }}>
                            <div>
                                <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: '2px', marginBottom: '2px' }}>
                                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                                        <Text strong>{record?.name} - F{index + 1}</Text>
                                        <Text style={{ cursor: 'pointer' }} onClick={(e) => e.stopPropagation()}>
                                            <a
                                                target="_blank"
                                                href={item?.tracking_url}
                                                onClick={(e) => e.stopPropagation()} // Prevent dropdown from closing
                                            >
                                                {item?.tracking_numbers}
                                            </a>
                                        </Text>
                                        <Text>{item?.tracking_company}</Text>
                                    </div>
                                    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'end' }}>
                                        <Text>Tracking added</Text>
                                        <CopyOutlined
                                            style={{ margin: '5px 0px' }}
                                            onClick={(e) => {
                                                e.stopPropagation()
                                                copyToClipboard(item?.tracking_numbers, 'Tracking Number')
                                            }}
                                        />
                                    </div>
                                </div>
                            </div>
                        </Menu.Item>
                    ))
                }
            </Menu>
        );
    };
    const copyToClipboard = (value, text) => {
        navigator.clipboard.writeText(value).then(() => {
            notification.success({
                message: `${text} Copied!`,
                className: 'toast-success',
            });
            console.log('Tracking Number copied to clipboard');
        }).catch(err => {
            console.error('Failed to copy: ', err);
        });
    };

    function DefaultTable() {
        let data = shopifyCustomerorder?.payload?.message?.data;
        if (data) {
            switch (groupByTable) {
                case "Order":
                    return (<>
                        <div className="table-responsive scrollbar-style">
                            <table className="transactions-box table">
                                <thead className="table_heading">
                                    <tr>
                                        <th className="td-width-60">S.#</th>
                                        <th className="td-width-150">Order Date</th>
                                        <th className="td-width-150">Order #</th>
                                        {/* <th className="td-width-150">Video Title</th>
                                        <th className="td-width-150">Department</th>
                                        <th className="td-width-150">Category</th>
                                        <th className="td-width-150">Sub Category</th>
                                        <th className="td-width-150">Channel</th>
                                        <th className="td-width-150">Content Source</th> */}
                                        <th className="td-width-150">Purchaser</th>
                                        <th className="td-width-150">Referrer</th>
                                        <th className="td-width-150 text-right">Gross Sales</th>
                                        <th className="td-width-150 text-right">Return Amount</th>
                                        <th className="td-width-150 text-right">Balance Amount</th>
                                        {/* <th className="td-width-150 text-right">Product Amount</th> */}
                                        <th className="td-width-150 text-right">ORME Fee</th>
                                        <th className="td-width-150 text-right">Net Sales</th>
                                        {/* <th className="td-width-150 text-right">Cashback</th>
                    <th className="td-width-150 text-right">Referral Fee</th>
                    <th className="td-width-150 text-right">Creator Fee</th> */}
                                    </tr>
                                </thead>
                                <tbody>
                                    {shopifyCustomerorder.loading ? (
                                        <div className="antd-loading-ift">
                                            <Spin size="large"></Spin>
                                        </div>
                                    ) : shopifyCustomerorder?.payload?.message?.data.length ===
                                        0 ? (
                                        <tr>
                                            <td colspan="12">
                                                <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                                            </td>
                                        </tr>
                                    ) : (
                                        data?.map((item, i) => {
                                            return (
                                                <tr>
                                                    <td>{i + 1 + currentPage * 15}</td>
                                                    <td>
                                                        {" "}
                                                        {moment(item?.created_date).format("MM-DD-YYYY")}
                                                    </td>
                                                    <td>
                                                        <a
                                                            className="open-modal blue-link"
                                                            onClick={() => {
                                                                detailView(item);
                                                            }}
                                                        >
                                                            {item?.order_id ? item?.order_id : "-"}
                                                        </a>
                                                    </td>
                                                    {/* <td>
                                                        {item?.event_payload ?
                                                            item?.event_payload?.is_published ?
                                                                <a
                                                                    className="open-modal blue-link limit-line"
                                                                    target="_blank"
                                                                    href={`${visitorURL}/published-events/${item?.event_payload?._id}`}
                                                                    title={item?.event_payload?.title ? item?.event_payload?.title : "-"}
                                                                >
                                                                    {item?.event_payload?.title ? item?.event_payload?.title : "-"}
                                                                </a>
                                                                :
                                                                <span className=" limit-line" title={item?.event_payload?.title ? item?.event_payload?.title : "-"}>{item?.event_payload?.title ? item?.event_payload?.title : "-"}</span>
                                                            :
                                                            item?.event === "productsale" ?
                                                                <span>-</span>
                                                                :
                                                                <a
                                                                    className="open-modal blue-link limit-line"
                                                                    target="_blank"
                                                                    href={`${visitorURL}/reviews/${item?.review_payload?._id}/${item?.brand?._id}`}
                                                                    title={item?.review_payload?.title ? item?.review_payload?.title : "-"}
                                                                >
                                                                    {item?.review_payload?.title ? item?.review_payload?.title : "-"}
                                                                </a>
                                                        }
                                                    </td>
                                                    <td>
                                                        {item?.gen_category?.name ? item?.gen_category?.name : "All"}
                                                    </td>
                                                    <td>
                                                        {item?.category?.category_name ? item?.category?.category_name : "All"}
                                                    </td>
                                                    <td>
                                                        {item?.sub_category?.sub_category_name ? item?.sub_category?.sub_category_name : "All"}
                                                    </td>
                                                    <td className="firstLetterCapital">
                                                        {item?.channel ? item?.channel : "-"}
                                                    </td>
                                                    <td className="firstLetterCapital">
                                                        {item?.content_by ? item?.content_by : "-"}
                                                    </td> */}
                                                    <td>
                                                        {item?.customer?.name ? item?.customer?.name : "-"}
                                                    </td>
                                                    <td>
                                                        {item?.affiliate?.name ? item?.affiliate?.name : "-"}
                                                    </td>
                                                    <td className="text-right">
                                                        {numeral(item?.gross_amount).format("$0,0.0'")}
                                                    </td>
                                                    <td className="text-right">
                                                        {numeral(item?.returned_amount).format("$0,0.0'")}
                                                    </td>
                                                    <td className="text-right">
                                                        {numeral((item?.balance_amount).toFixed(2)).format("$0,0.0'")}
                                                    </td>
                                                    {/* <td className="text-right">
                            {numeral(item?.order_totalprice).format("$0,0.0'")}
                          </td> */}

                                                    <td className="text-right">{numeral((item?.kb_fee).toFixed(2)).format("$0,0.0'")}</td>
                                                    <td className="text-right">{numeral((item?.net_brand_amount).toFixed(2)).format("$0,0.0'")}</td>
                                                    {/* 
                          <td className="text-right">
                            {numeral(item?.cashback_amount).format("$0,0.0'")}
                          </td>
                          <td className="text-right">
                            {numeral(item?.customer_commission).format("$0,0.0'")}
                          </td>
                          <td className="text-right">
                            {numeral(item?.creator_commission).format("$0,0.0'")}
                          </td> */}
                                                </tr>
                                            );
                                        })
                                    )}

                                    {shopifyCustomerorder?.payload?.message?.data.length > 0 && (
                                        <tr>
                                            <td></td>
                                            <td>
                                                <b>Total</b>
                                            </td>
                                            <td>
                                                <b>
                                                    {shopifyCustomerorder?.payload?.message?.data?.length +
                                                        currentPage * 15}
                                                </b>
                                            </td>
                                            <td></td>
                                            <td></td>
                                            <td className="text-right">
                                                <b>
                                                    {numeral(
                                                        data.reduce((a, b) => {
                                                            return (
                                                                Number(a) +
                                                                Number(
                                                                    b.gross_amount ? b.gross_amount : 0.0
                                                                )
                                                            );
                                                        }, 0)
                                                    ).format("$0,0.0'")}
                                                </b>
                                            </td>
                                            <td className="text-right">
                                                <b>
                                                    {numeral(
                                                        data.reduce((a, b) => {
                                                            return (
                                                                Number(a) +
                                                                Number(
                                                                    b.returned_amount ? b.returned_amount : 0.0
                                                                )
                                                            );
                                                        }, 0)
                                                    ).format("$0,0.0'")}
                                                </b>
                                            </td>
                                            <td className="text-right">
                                                <b>
                                                    {numeral(
                                                        data.reduce((a, b) => {
                                                            return (
                                                                Number(a) +
                                                                Number(
                                                                    b.balance_amount ? b.balance_amount : 0.0
                                                                )
                                                            );
                                                        }, 0)
                                                    ).format("$0,0.0'")}
                                                </b>
                                            </td>
                                            <td className="text-right">
                                                <b>
                                                    {numeral(
                                                        data.reduce((a, b) => {
                                                            return (
                                                                Number(a) + Number(b.kb_fee ? b.kb_fee : 0.0)
                                                            );
                                                        }, 0)
                                                    ).format("$0,0.0'")}
                                                </b>
                                            </td>
                                            <td className="text-right">
                                                <b>
                                                    {numeral(
                                                        data.reduce((a, b) => {
                                                            return (
                                                                Number(a) + Number(b.net_brand_amount ? b.net_brand_amount : 0.0)
                                                            );
                                                        }, 0)
                                                    ).format("$0,0.0'")}
                                                </b>
                                            </td>
                                            {/* <td className="text-right">
                        <b>
                          {numeral(
                            data.reduce((a, b) => {
                              return (
                                Number(a) +
                                Number(
                                  b.cashback_amount ? b.cashback_amount : 0.0
                                )
                              );
                            }, 0)
                          ).format("$0,0.0'")}
                        </b>
                      </td> */}
                                            {/* <td className="text-right">
                        <b>
                          {numeral(
                            data.reduce((a, b) => {
                              return (
                                Number(a) +
                                Number(
                                  b.customer_commission
                                    ? b.customer_commission
                                    : 0.0
                                )
                              );
                            }, 0)
                          ).format("$0,0.0'")}
                        </b>
                      </td> */}
                                            {/* <td className="text-right">
                        <b>
                          {numeral(
                            data.reduce((a, b) => {
                              return (
                                Number(a) +
                                Number(
                                  b.creator_commission
                                    ? b.creator_commission
                                    : 0.0
                                )
                              );
                            }, 0)
                          ).format("$0,0.0'")}
                        </b>
                      </td> */}


                                        </tr>
                                    )}
                                </tbody>
                            </table>
                        </div>
                    </>
                    );
                case "channel":
                    return (<>
                        <div className="table-responsive scrollbar-style">
                            <table className="transactions-box table">
                                <thead className="table_heading">
                                    <tr>
                                        <th className="td-width-60">S.#</th>
                                        <th className="td-width-150">Channel</th>
                                        <th className="td-width-150">Order Date</th>


                                        <th className="td-width-150 text-right">Gross Sales</th>
                                        <th className="td-width-150 text-right">Return Amount</th>
                                        <th className="td-width-150 text-right">Balance Amount</th>
                                        <th className="td-width-150 text-right">ORME Fee</th>
                                        <th className="td-width-150 text-right">Net Sales</th>
                                        {/* <th className="td-width-150 text-right">Cashback</th>
                    <th className="td-width-150 text-right">Referral Fee</th>
                    <th className="td-width-150 text-right">Creator Fee</th> */}
                                    </tr>
                                </thead>
                                <tbody>
                                    {shopifyCustomerorder.loading ? (
                                        <div className="antd-loading-ift">
                                            <Spin size="large"></Spin>
                                        </div>
                                    ) : shopifyCustomerorder?.payload?.message?.data.length ===
                                        0 ? (
                                        <tr>
                                            <td colspan="12">
                                                <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                                            </td>
                                        </tr>
                                    ) : (
                                        data?.map((item, i) => {
                                            return (
                                                <tr>
                                                    <td>{i + 1 + currentPage * 15}</td>
                                                    <td className="firstLetterCapital">
                                                        {item?.channel ? item?.channel : "-"}
                                                    </td>
                                                    <td>
                                                        {" "}
                                                        {moment(item?.created_date).format("MM-DD-YYYY")}
                                                    </td>


                                                    <td className="text-right">
                                                        {numeral(item?.gross_amount).format("$0,0.0'")}
                                                    </td>
                                                    <td className="text-right">
                                                        {numeral(item?.returned_amount).format("$0,0.0'")}
                                                    </td>
                                                    <td className="text-right">
                                                        {numeral(item?.balance_amount).format("$0,0.0'")}
                                                    </td>
                                                    <td className="text-right">{numeral(item?.kb_fee).format("$0,0.0'")}</td>
                                                    <td className="text-right">{numeral(item?.net_brand_amount).format("$0,0.0'")}</td>
                                                    {/* 
                          <td className="text-right">
                            {numeral(item?.cashback_amount).format("$0,0.0'")}
                          </td>
                          <td className="text-right">
                            {numeral(item?.customer_commission).format("$0,0.0'")}
                          </td>
                          <td className="text-right">
                            {numeral(item?.creator_commission).format("$0,0.0'")}
                          </td> */}
                                                </tr>
                                            );
                                        })
                                    )}

                                    {shopifyCustomerorder?.payload?.message?.data.length > 0 && (
                                        <tr>
                                            <td></td>
                                            <td>
                                                <b>Total</b>
                                            </td>
                                            <td>
                                                <b>
                                                    {shopifyCustomerorder?.payload?.message?.data?.length +
                                                        currentPage * 15}
                                                </b>
                                            </td>

                                            <td className="text-right">
                                                <b>
                                                    {numeral(
                                                        data.reduce((a, b) => {
                                                            return (
                                                                Number(a) +
                                                                Number(
                                                                    b.gross_amount ? b.gross_amount : 0.0
                                                                )
                                                            );
                                                        }, 0)
                                                    ).format("$0,0.0'")}
                                                </b>
                                            </td>
                                            <td className="text-right">
                                                <b>
                                                    {numeral(
                                                        data.reduce((a, b) => {
                                                            return (
                                                                Number(a) +
                                                                Number(
                                                                    b.returned_amount ? b.returned_amount : 0.0
                                                                )
                                                            );
                                                        }, 0)
                                                    ).format("$0,0.0'")}
                                                </b>
                                            </td>
                                            <td className="text-right">
                                                <b>
                                                    {numeral(
                                                        data.reduce((a, b) => {
                                                            return (
                                                                Number(a) +
                                                                Number(
                                                                    b.balance_amount ? b.balance_amount : 0.0
                                                                )
                                                            );
                                                        }, 0)
                                                    ).format("$0,0.0'")}
                                                </b>
                                            </td>
                                            <td className="text-right">
                                                <b>
                                                    {numeral(
                                                        data.reduce((a, b) => {
                                                            return (
                                                                Number(a) + Number(b.kb_fee ? b.kb_fee : 0.0)
                                                            );
                                                        }, 0)
                                                    ).format("$0,0.0'")}
                                                </b>
                                            </td>
                                            <td className="text-right">
                                                <b>
                                                    {numeral(
                                                        data.reduce((a, b) => {
                                                            return (
                                                                Number(a) + Number(b.net_brand_amount ? b.net_brand_amount : 0.0)
                                                            );
                                                        }, 0)
                                                    ).format("$0,0.0'")}
                                                </b>
                                            </td>
                                            {/* <td className="text-right">
                        <b>
                          {numeral(
                            data.reduce((a, b) => {
                              return (
                                Number(a) +
                                Number(
                                  b.cashback_amount ? b.cashback_amount : 0.0
                                )
                              );
                            }, 0)
                          ).format("$0,0.0'")}
                        </b>
                      </td> */}
                                            {/* <td className="text-right">
                        <b>
                          {numeral(
                            data.reduce((a, b) => {
                              return (
                                Number(a) +
                                Number(
                                  b.customer_commission
                                    ? b.customer_commission
                                    : 0.0
                                )
                              );
                            }, 0)
                          ).format("$0,0.0'")}
                        </b>
                      </td> */}
                                            {/* <td className="text-right">
                        <b>
                          {numeral(
                            data.reduce((a, b) => {
                              return (
                                Number(a) +
                                Number(
                                  b.creator_commission
                                    ? b.creator_commission
                                    : 0.0
                                )
                              );
                            }, 0)
                          ).format("$0,0.0'")}
                        </b>
                      </td> */}


                                        </tr>
                                    )}
                                </tbody>
                            </table>
                        </div>
                    </>
                    );
                case "date":
                    return (<>
                        <div className="table-responsive scrollbar-style">
                            <table className="transactions-box table">
                                <thead className="table_heading">
                                    <tr>
                                        <th className="td-width-60">S.#</th>
                                        <th className="td-width-150">Order Date</th>
                                        <th className="td-width-150">Total Orders</th>
                                        <th className="td-width-150 text-right">Gross Sales</th>
                                        <th className="td-width-150 text-right">Return Amount</th>
                                        <th className="td-width-150 text-right">Balance Amount</th>
                                        <th className="td-width-150 text-right">ORME Fee</th>
                                        <th className="td-width-150 text-right">Net Sales</th>
                                        {/* <th className="td-width-150 text-right">Cashback</th>
                    <th className="td-width-150 text-right">Referral Fee</th>
                    <th className="td-width-150 text-right">Content Fee</th> */}
                                    </tr>
                                </thead>
                                <tbody>
                                    {shopifyCustomerorder.loading ? (
                                        <div className="antd-loading-ift">
                                            <Spin size="large"></Spin>
                                        </div>
                                    ) : shopifyCustomerorder?.payload?.message?.data.length ===
                                        0 ? (
                                        <tr>
                                            <td colspan="12">
                                                <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                                            </td>
                                        </tr>
                                    ) : (
                                        data?.map((item, i) => {
                                            return (
                                                <tr>
                                                    <td>{i + 1 + currentPage * 15}</td>
                                                    <td>
                                                        {" "}
                                                        {moment(item?.created_date).format("MM-DD-YYYY")}
                                                    </td>
                                                    <td>

                                                        {item?.order_count ? item?.order_count : "-"}

                                                    </td>

                                                    <td className="text-right">
                                                        {numeral(item?.gross_amount).format("$0,0.0'")}
                                                    </td>
                                                    <td className="text-right">
                                                        {numeral(item?.returned_amount).format("$0,0.0'")}
                                                    </td>
                                                    <td className="text-right">
                                                        {numeral(item?.balance_amount).format("$0,0.0'")}
                                                    </td>
                                                    <td className="text-right">{numeral(item?.kb_fee).format("$0,0.0'")}</td>
                                                    <td className="text-right">{numeral(item?.net_brand_amount).format("$0,0.0'")}</td>
                                                    {/* <td className="text-right">
                            {numeral(item?.cashback_amount).format("$0,0.0'")}
                          </td>
                          <td className="text-right">
                            {numeral(item?.customer_commission).format("$0,0.0'")}
                          </td>
                          <td className="text-right">
                            {numeral(item?.creator_commission).format("$0,0.0'")}
                          </td> */}
                                                </tr>
                                            );
                                        })
                                    )}

                                    {shopifyCustomerorder?.payload?.message?.data.length > 0 && (
                                        <tr>
                                            <td></td>
                                            <td>
                                                <b>Total</b>
                                            </td>
                                            <td>
                                                <b>

                                                    {shopifyCustomerorder?.payload?.message?.data.reduce((a, b) => {
                                                        return (
                                                            Number(a) +
                                                            Number(
                                                                b.order_count ? b.order_count : 0.0
                                                            )
                                                        );
                                                    }, 0)
                                                    }
                                                </b>
                                            </td>

                                            <td className="text-right">
                                                <b>
                                                    {numeral(
                                                        data.reduce((a, b) => {
                                                            return (
                                                                Number(a) +
                                                                Number(
                                                                    b.gross_amount ? b.gross_amount : 0.0
                                                                )
                                                            );
                                                        }, 0)
                                                    ).format("$0,0.0'")}
                                                </b>
                                            </td>
                                            <td className="text-right">
                                                <b>
                                                    {numeral(
                                                        data.reduce((a, b) => {
                                                            return (
                                                                Number(a) +
                                                                Number(
                                                                    b.returned_amount ? b.returned_amount : 0.0
                                                                )
                                                            );
                                                        }, 0)
                                                    ).format("$0,0.0'")}
                                                </b>
                                            </td>
                                            <td className="text-right">
                                                <b>
                                                    {numeral(
                                                        data.reduce((a, b) => {
                                                            return (
                                                                Number(a) +
                                                                Number(
                                                                    b.balance_amount ? b.balance_amount : 0.0
                                                                )
                                                            );
                                                        }, 0)
                                                    ).format("$0,0.0'")}
                                                </b>
                                            </td>
                                            <td className="text-right">
                                                <b>
                                                    {numeral(
                                                        data.reduce((a, b) => {
                                                            return (
                                                                Number(a) + Number(b.kb_fee ? b.kb_fee : 0.0)
                                                            );
                                                        }, 0)
                                                    ).format("$0,0.0'")}
                                                </b>
                                            </td>
                                            <td className="text-right">
                                                <b>
                                                    {numeral(
                                                        data.reduce((a, b) => {
                                                            return (
                                                                Number(a) + Number(b.net_brand_amount ? b.net_brand_amount : 0.0)
                                                            );
                                                        }, 0)
                                                    ).format("$0,0.0'")}
                                                </b>
                                            </td>
                                            {/* <td className="text-right">
                        <b>
                          {numeral(
                            data.reduce((a, b) => {
                              return (
                                Number(a) +
                                Number(
                                  b.cashback_amount ? b.cashback_amount : 0.0
                                )
                              );
                            }, 0)
                          ).format("$0,0.0'")}
                        </b>
                      </td> */}
                                            {/* <td className="text-right">
                        <b>
                          {numeral(
                            data.reduce((a, b) => {
                              return (
                                Number(a) +
                                Number(
                                  b.customer_commission
                                    ? b.customer_commission
                                    : 0.0
                                )
                              );
                            }, 0)
                          ).format("$0,0.0'")}
                        </b>
                      </td> */}
                                            {/* <td className="text-right">
                        <b>
                          {numeral(
                            data.reduce((a, b) => {
                              return (
                                Number(a) +
                                Number(
                                  b.creator_commission
                                    ? b.creator_commission
                                    : 0.0
                                )
                              );
                            }, 0)
                          ).format("$0,0.0'")}
                        </b>
                      </td> */}

                                        </tr>
                                    )}
                                </tbody>
                            </table>
                        </div>
                    </>
                    );
                case "genCategory":
                    return (<>
                        <div className="table-responsive scrollbar-style">
                            <table className="transactions-box table">
                                <thead className="table_heading">
                                    <tr>
                                        <th className="td-width-60">S.#</th>
                                        <th className="td-width-150">Department</th>
                                        <th className="td-width-150">Total Orders</th>
                                        <th className="td-width-150 text-right">Gross Sales</th>
                                        <th className="td-width-150 text-right">Return Amount</th>
                                        <th className="td-width-150 text-right">Balance Amount</th>
                                        <th className="td-width-150 text-right">ORME Fee</th>
                                        <th className="td-width-150 text-right">Net Sales</th>
                                        {/* <th className="td-width-150 text-right">Cashback</th>
                    <th className="td-width-150 text-right">Referral Fee</th>
                    <th className="td-width-150 text-right">Content Fee</th> */}
                                    </tr>
                                </thead>
                                <tbody>
                                    {shopifyCustomerorder.loading ? (
                                        <div className="antd-loading-ift">
                                            <Spin size="large"></Spin>
                                        </div>
                                    ) : shopifyCustomerorder?.payload?.message?.data.length ===
                                        0 ? (
                                        <tr>
                                            <td colspan="12">
                                                <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                                            </td>
                                        </tr>
                                    ) : (
                                        data?.map((item, i) => {
                                            return (
                                                <tr>
                                                    <td>{i + 1 + currentPage * 15}</td>
                                                    <td>
                                                        {item?.gen_category?.name ? item?.gen_category?.name : "All"}
                                                    </td>
                                                    <td>

                                                        {item?.order_count ? item?.order_count : "-"}

                                                    </td>

                                                    <td className="text-right">
                                                        {numeral(item?.gross_amount).format("$0,0.0'")}
                                                    </td>
                                                    <td className="text-right">
                                                        {numeral(item?.returned_amount).format("$0,0.0'")}
                                                    </td>
                                                    <td className="text-right">
                                                        {numeral(item?.balance_amount).format("$0,0.0'")}
                                                    </td>
                                                    <td className="text-right">{numeral(item?.kb_fee).format("$0,0.0'")}</td>
                                                    <td className="text-right">{numeral(item?.net_brand_amount).format("$0,0.0'")}</td>
                                                    {/* <td className="text-right">
                            {numeral(item?.cashback_amount).format("$0,0.0'")}
                          </td>
                          <td className="text-right">
                            {numeral(item?.customer_commission).format("$0,0.0'")}
                          </td>
                          <td className="text-right">
                            {numeral(item?.creator_commission).format("$0,0.0'")}
                          </td> */}
                                                </tr>
                                            );
                                        })
                                    )}

                                    {shopifyCustomerorder?.payload?.message?.data.length > 0 && (
                                        <tr>
                                            <td></td>

                                            <td>
                                                <b>Total</b>
                                            </td>
                                            <td>
                                                <b>
                                                    {shopifyCustomerorder?.payload?.message?.data.reduce((a, b) => {
                                                        return (
                                                            Number(a) +
                                                            Number(
                                                                b.order_count ? b.order_count : 0.0
                                                            )
                                                        );
                                                    }, 0)
                                                    }
                                                </b>
                                            </td>

                                            <td className="text-right">
                                                <b>
                                                    {numeral(
                                                        data.reduce((a, b) => {
                                                            return (
                                                                Number(a) +
                                                                Number(
                                                                    b.gross_amount ? b.gross_amount : 0.0
                                                                )
                                                            );
                                                        }, 0)
                                                    ).format("$0,0.0'")}
                                                </b>
                                            </td>
                                            <td className="text-right">
                                                <b>
                                                    {numeral(
                                                        data.reduce((a, b) => {
                                                            return (
                                                                Number(a) +
                                                                Number(
                                                                    b.returned_amount ? b.returned_amount : 0.0
                                                                )
                                                            );
                                                        }, 0)
                                                    ).format("$0,0.0'")}
                                                </b>
                                            </td>
                                            <td className="text-right">
                                                <b>
                                                    {numeral(
                                                        data.reduce((a, b) => {
                                                            return (
                                                                Number(a) +
                                                                Number(
                                                                    b.balance_amount ? b.balance_amount : 0.0
                                                                )
                                                            );
                                                        }, 0)
                                                    ).format("$0,0.0'")}
                                                </b>
                                            </td>
                                            <td className="text-right">
                                                <b>
                                                    {numeral(
                                                        data.reduce((a, b) => {
                                                            return (
                                                                Number(a) + Number(b.kb_fee ? b.kb_fee : 0.0)
                                                            );
                                                        }, 0)
                                                    ).format("$0,0.0'")}
                                                </b>
                                            </td>
                                            <td className="text-right">
                                                <b>
                                                    {numeral(
                                                        data.reduce((a, b) => {
                                                            return (
                                                                Number(a) + Number(b.net_brand_amount ? b.net_brand_amount : 0.0)
                                                            );
                                                        }, 0)
                                                    ).format("$0,0.0'")}
                                                </b>
                                            </td>
                                            {/* <td className="text-right">
                        <b>
                          {numeral(
                            data.reduce((a, b) => {
                              return (
                                Number(a) +
                                Number(
                                  b.cashback_amount ? b.cashback_amount : 0.0
                                )
                              );
                            }, 0)
                          ).format("$0,0.0'")}
                        </b>
                      </td> */}
                                            {/* <td className="text-right">
                        <b>
                          {numeral(
                            data.reduce((a, b) => {
                              return (
                                Number(a) +
                                Number(
                                  b.customer_commission
                                    ? b.customer_commission
                                    : 0.0
                                )
                              );
                            }, 0)
                          ).format("$0,0.0'")}
                        </b>
                      </td> */}
                                            {/* 
                      <td className="text-right">
                        <b>
                          {numeral(
                            data.reduce((a, b) => {
                              return (
                                Number(a) +
                                Number(
                                  b.creator_commission
                                    ? b.creator_commission
                                    : 0.0
                                )
                              );
                            }, 0)
                          ).format("$0,0.0'")}
                        </b>
                      </td> */}
                                        </tr>
                                    )}
                                </tbody>
                            </table>
                        </div>
                    </>
                    );
                case "category":
                    return (<>
                        <div className="table-responsive scrollbar-style">
                            <table className="transactions-box table">
                                <thead className="table_heading">
                                    <tr>
                                        <th className="td-width-60">S.#</th>
                                        <th className="td-width-150">Category</th>
                                        <th className="td-width-150">Total Orders</th>
                                        <th className="td-width-150 text-right">Gross Sales</th>
                                        <th className="td-width-150 text-right">Return Amount</th>
                                        <th className="td-width-150 text-right">Balance Amount</th>
                                        <th className="td-width-150 text-right">ORME Fee</th>
                                        <th className="td-width-150 text-right">Net Sales</th>
                                        {/* <th className="td-width-150 text-right">Cashback</th>
                    <th className="td-width-150 text-right">Referral Fee</th>
                    <th className="td-width-150 text-right">Content Fee</th> */}
                                    </tr>
                                </thead>
                                <tbody>
                                    {shopifyCustomerorder.loading ? (
                                        <div className="antd-loading-ift">
                                            <Spin size="large"></Spin>
                                        </div>
                                    ) : shopifyCustomerorder?.payload?.message?.data.length ===
                                        0 ? (
                                        <tr>
                                            <td colspan="12">
                                                <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                                            </td>
                                        </tr>
                                    ) : (
                                        data?.map((item, i) => {
                                            return (
                                                <tr>
                                                    <td>{i + 1 + currentPage * 15}</td>
                                                    <td>
                                                        {item?.category?.category_name ? item?.category?.category_name : "All"}
                                                    </td>
                                                    <td>

                                                        {item?.order_count ? item?.order_count : "-"}
                                                    </td>

                                                    <td className="text-right">
                                                        {numeral(item?.gross_amount).format("$0,0.0'")}
                                                    </td>
                                                    <td className="text-right">
                                                        {numeral(item?.returned_amount).format("$0,0.0'")}
                                                    </td>
                                                    <td className="text-right">
                                                        {numeral(item?.balance_amount).format("$0,0.0'")}
                                                    </td>
                                                    <td className="text-right">{numeral(item?.kb_fee).format("$0,0.0'")}</td>
                                                    <td className="text-right">{numeral(item?.net_brand_amount).format("$0,0.0'")}</td>
                                                    {/* <td className="text-right">
                            {numeral(item?.cashback_amount).format("$0,0.0'")}
                          </td>
                          <td className="text-right">
                            {numeral(item?.customer_commission).format("$0,0.0'")}
                          </td>
                          <td className="text-right">
                            {numeral(item?.creator_commission).format("$0,0.0'")}
                          </td> */}
                                                </tr>
                                            );
                                        })
                                    )}

                                    {shopifyCustomerorder?.payload?.message?.data.length > 0 && (
                                        <tr>
                                            <td></td>

                                            <td>
                                                <b>Total</b>
                                            </td>
                                            <td>
                                                <b>
                                                    {shopifyCustomerorder?.payload?.message?.data.reduce((a, b) => {
                                                        return (
                                                            Number(a) +
                                                            Number(
                                                                b.order_count ? b.order_count : 0.0
                                                            )
                                                        );
                                                    }, 0)
                                                    }
                                                </b>
                                            </td>

                                            <td className="text-right">
                                                <b>
                                                    {numeral(
                                                        data.reduce((a, b) => {
                                                            return (
                                                                Number(a) +
                                                                Number(
                                                                    b.gross_amount ? b.gross_amount : 0.0
                                                                )
                                                            );
                                                        }, 0)
                                                    ).format("$0,0.0'")}
                                                </b>
                                            </td>
                                            <td className="text-right">
                                                <b>
                                                    {numeral(
                                                        data.reduce((a, b) => {
                                                            return (
                                                                Number(a) +
                                                                Number(
                                                                    b.returned_amount ? b.returned_amount : 0.0
                                                                )
                                                            );
                                                        }, 0)
                                                    ).format("$0,0.0'")}
                                                </b>
                                            </td>
                                            <td className="text-right">
                                                <b>
                                                    {numeral(
                                                        data.reduce((a, b) => {
                                                            return (
                                                                Number(a) +
                                                                Number(
                                                                    b.balance_amount ? b.balance_amount : 0.0
                                                                )
                                                            );
                                                        }, 0)
                                                    ).format("$0,0.0'")}
                                                </b>
                                            </td>
                                            <td className="text-right">
                                                <b>
                                                    {numeral(
                                                        data.reduce((a, b) => {
                                                            return (
                                                                Number(a) + Number(b.kb_fee ? b.kb_fee : 0.0)
                                                            );
                                                        }, 0)
                                                    ).format("$0,0.0'")}
                                                </b>
                                            </td>
                                            <td className="text-right">
                                                <b>
                                                    {numeral(
                                                        data.reduce((a, b) => {
                                                            return (
                                                                Number(a) + Number(b.net_brand_amount ? b.net_brand_amount : 0.0)
                                                            );
                                                        }, 0)
                                                    ).format("$0,0.0'")}
                                                </b>
                                            </td>
                                            {/* <td className="text-right">
                        <b>
                          {numeral(
                            data.reduce((a, b) => {
                              return (
                                Number(a) +
                                Number(
                                  b.cashback_amount ? b.cashback_amount : 0.0
                                )
                              );
                            }, 0)
                          ).format("$0,0.0'")}
                        </b>
                      </td> */}
                                            {/* <td className="text-right">
                        <b>
                          {numeral(
                            data.reduce((a, b) => {
                              return (
                                Number(a) +
                                Number(
                                  b.customer_commission
                                    ? b.customer_commission
                                    : 0.0
                                )
                              );
                            }, 0)
                          ).format("$0,0.0'")}
                        </b>
                      </td> */}
                                            {/* <td className="text-right">
                        <b>
                          {numeral(
                            data.reduce((a, b) => {
                              return (
                                Number(a) +
                                Number(
                                  b.creator_commission
                                    ? b.creator_commission
                                    : 0.0
                                )
                              );
                            }, 0)
                          ).format("$0,0.0'")}
                        </b>
                      </td> */}

                                        </tr>
                                    )}
                                </tbody>
                            </table>
                        </div>
                    </>
                    );
                case "subCategory":
                    return (<>
                        <div className="table-responsive scrollbar-style">
                            <table className="transactions-box table">
                                <thead className="table_heading">
                                    <tr>
                                        <th className="td-width-60">S.#</th>
                                        <th className="td-width-150">Sub Category</th>
                                        <th className="td-width-150">Total Orders</th>
                                        <th className="td-width-150 text-right">Gross Sales</th>
                                        <th className="td-width-150 text-right">Return Amount</th>
                                        <th className="td-width-150 text-right">Balance Amount</th>
                                        <th className="td-width-150 text-right">ORME Fee</th>
                                        <th className="td-width-150 text-right">Net Sales</th>
                                        {/* <th className="td-width-150 text-right">Cashback</th>
                    <th className="td-width-150 text-right">Referral Fee</th>
                    <th className="td-width-150 text-right">Content Fee</th> */}
                                    </tr>
                                </thead>
                                <tbody>
                                    {shopifyCustomerorder.loading ? (
                                        <div className="antd-loading-ift">
                                            <Spin size="large"></Spin>
                                        </div>
                                    ) : shopifyCustomerorder?.payload?.message?.data.length ===
                                        0 ? (
                                        <tr>
                                            <td colspan="12">
                                                <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                                            </td>
                                        </tr>
                                    ) : (
                                        data?.map((item, i) => {
                                            return (
                                                <tr>
                                                    <td>{i + 1 + currentPage * 15}</td>
                                                    <td>
                                                        {item?.sub_category?.sub_category_name ? item?.sub_category?.sub_category_name : "All"}
                                                    </td>
                                                    <td>

                                                        {item?.order_count ? item?.order_count : "-"}
                                                    </td>

                                                    <td className="text-right">
                                                        {numeral(item?.gross_amount).format("$0,0.0'")}
                                                    </td>
                                                    <td className="text-right">
                                                        {numeral(item?.returned_amount).format("$0,0.0'")}
                                                    </td>
                                                    <td className="text-right">
                                                        {numeral(item?.balance_amount).format("$0,0.0'")}
                                                    </td>
                                                    <td className="text-right">{numeral(item?.kb_fee).format("$0,0.0'")}</td>
                                                    <td className="text-right">{numeral(item?.net_brand_amount).format("$0,0.0'")}</td>
                                                    {/* <td className="text-right">
                            {numeral(item?.cashback_amount).format("$0,0.0'")}
                          </td>
                          <td className="text-right">
                            {numeral(item?.customer_commission).format("$0,0.0'")}
                          </td>
                          <td className="text-right">
                            {numeral(item?.creator_commission).format("$0,0.0'")}
                          </td> */}
                                                </tr>
                                            );
                                        })
                                    )}

                                    {shopifyCustomerorder?.payload?.message?.data.length > 0 && (
                                        <tr>
                                            <td></td>

                                            <td>
                                                <b>Total</b>
                                            </td>
                                            <td>
                                                <b>
                                                    {shopifyCustomerorder?.payload?.message?.data.reduce((a, b) => {
                                                        return (
                                                            Number(a) +
                                                            Number(
                                                                b.order_count ? b.order_count : 0.0
                                                            )
                                                        );
                                                    }, 0)
                                                    }
                                                </b>
                                            </td>

                                            <td className="text-right">
                                                <b>
                                                    {numeral(
                                                        data.reduce((a, b) => {
                                                            return (
                                                                Number(a) +
                                                                Number(
                                                                    b.gross_amount ? b.gross_amount : 0.0
                                                                )
                                                            );
                                                        }, 0)
                                                    ).format("$0,0.0'")}
                                                </b>
                                            </td>
                                            <td className="text-right">
                                                <b>
                                                    {numeral(
                                                        data.reduce((a, b) => {
                                                            return (
                                                                Number(a) +
                                                                Number(
                                                                    b.returned_amount ? b.returned_amount : 0.0
                                                                )
                                                            );
                                                        }, 0)
                                                    ).format("$0,0.0'")}
                                                </b>
                                            </td>
                                            <td className="text-right">
                                                <b>
                                                    {numeral(
                                                        data.reduce((a, b) => {
                                                            return (
                                                                Number(a) +
                                                                Number(
                                                                    b.balance_amount ? b.balance_amount : 0.0
                                                                )
                                                            );
                                                        }, 0)
                                                    ).format("$0,0.0'")}
                                                </b>
                                            </td>
                                            <td className="text-right">
                                                <b>
                                                    {numeral(
                                                        data.reduce((a, b) => {
                                                            return (
                                                                Number(a) + Number(b.kb_fee ? b.kb_fee : 0.0)
                                                            );
                                                        }, 0)
                                                    ).format("$0,0.0'")}
                                                </b>
                                            </td>
                                            <td className="text-right">
                                                <b>
                                                    {numeral(
                                                        data.reduce((a, b) => {
                                                            return (
                                                                Number(a) + Number(b.net_brand_amount ? b.net_brand_amount : 0.0)
                                                            );
                                                        }, 0)
                                                    ).format("$0,0.0'")}
                                                </b>
                                            </td>
                                            {/* <td className="text-right">
                        <b>
                          {numeral(
                            data.reduce((a, b) => {
                              return (
                                Number(a) +
                                Number(
                                  b.cashback_amount ? b.cashback_amount : 0.0
                                )
                              );
                            }, 0)
                          ).format("$0,0.0'")}
                        </b>
                      </td> */}
                                            {/* <td className="text-right">
                        <b>
                          {numeral(
                            data.reduce((a, b) => {
                              return (
                                Number(a) +
                                Number(
                                  b.customer_commission
                                    ? b.customer_commission
                                    : 0.0
                                )
                              );
                            }, 0)
                          ).format("$0,0.0'")}
                        </b>
                      </td> */}
                                            {/* <td className="text-right">
                        <b>
                          {numeral(
                            data.reduce((a, b) => {
                              return (
                                Number(a) +
                                Number(
                                  b.creator_commission
                                    ? b.creator_commission
                                    : 0.0
                                )
                              );
                            }, 0)
                          ).format("$0,0.0'")}
                        </b>
                      </td> */}

                                        </tr>
                                    )}
                                </tbody>
                            </table>
                        </div>
                    </>
                    );
                case "content":
                    return (<>
                        <div className="table-responsive scrollbar-style">
                            <table className="transactions-box table">
                                <thead className="table_heading">
                                    <tr>
                                        <th className="td-width-60">S.#</th>
                                        <th className="td-width-150">Content Source</th>
                                        <th className="td-width-150">Total Orders</th>
                                        <th className="td-width-150 text-right">Gross Sales</th>
                                        <th className="td-width-150 text-right">Return Amount</th>
                                        <th className="td-width-150 text-right">Balance Amount</th>
                                        <th className="td-width-150 text-right">ORME Fee</th>
                                        <th className="td-width-150 text-right">Net Sales</th>
                                        {/* <th className="td-width-150 text-right">Cashback</th>
                    <th className="td-width-150 text-right">Referral Fee</th>
                    <th className="td-width-150 text-right">Content Fee</th> */}
                                    </tr>
                                </thead>
                                <tbody>
                                    {shopifyCustomerorder.loading ? (
                                        <div className="antd-loading-ift">
                                            <Spin size="large"></Spin>
                                        </div>
                                    ) : shopifyCustomerorder?.payload?.message?.data.length ===
                                        0 ? (
                                        <tr>
                                            <td colspan="12">
                                                <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                                            </td>
                                        </tr>
                                    ) : (
                                        data?.map((item, i) => {
                                            return (
                                                <tr>
                                                    <td>{i + 1 + currentPage * 15}</td>
                                                    <td className="firstLetterCapital">
                                                        {item?.content_by ? item?.content_by : "-"}
                                                    </td>
                                                    <td>

                                                        {item?.order_count ? item?.order_count : "-"}
                                                    </td>

                                                    <td className="text-right">
                                                        {numeral(item?.gross_amount).format("$0,0.0'")}
                                                    </td>
                                                    <td className="text-right">
                                                        {numeral(item?.returned_amount).format("$0,0.0'")}
                                                    </td>
                                                    <td className="text-right">
                                                        {numeral(item?.balance_amount).format("$0,0.0'")}
                                                    </td>
                                                    <td className="text-right">{numeral(item?.kb_fee).format("$0,0.0'")}</td>
                                                    <td className="text-right">{numeral(item?.net_brand_amount).format("$0,0.0'")}</td>
                                                    {/* <td className="text-right">
                            {numeral(item?.cashback_amount).format("$0,0.0'")}
                          </td>
                          <td className="text-right">
                            {numeral(item?.customer_commission).format("$0,0.0'")}
                          </td>
                          <td className="text-right">
                            {numeral(item?.creator_commission).format("$0,0.0'")}
                          </td> */}
                                                </tr>
                                            );
                                        })
                                    )}

                                    {shopifyCustomerorder?.payload?.message?.data.length > 0 && (
                                        <tr>
                                            <td></td>

                                            <td>
                                                <b>Total</b>
                                            </td>
                                            <td>
                                                <b>
                                                    {shopifyCustomerorder?.payload?.message?.data.reduce((a, b) => {
                                                        return (
                                                            Number(a) +
                                                            Number(
                                                                b.order_count ? b.order_count : 0.0
                                                            )
                                                        );
                                                    }, 0)
                                                    }
                                                </b>
                                            </td>

                                            <td className="text-right">
                                                <b>
                                                    {numeral(
                                                        data.reduce((a, b) => {
                                                            return (
                                                                Number(a) +
                                                                Number(
                                                                    b.gross_amount ? b.gross_amount : 0.0
                                                                )
                                                            );
                                                        }, 0)
                                                    ).format("$0,0.0'")}
                                                </b>
                                            </td>
                                            <td className="text-right">
                                                <b>
                                                    {numeral(
                                                        data.reduce((a, b) => {
                                                            return (
                                                                Number(a) +
                                                                Number(
                                                                    b.returned_amount ? b.returned_amount : 0.0
                                                                )
                                                            );
                                                        }, 0)
                                                    ).format("$0,0.0'")}
                                                </b>
                                            </td>
                                            <td className="text-right">
                                                <b>
                                                    {numeral(
                                                        data.reduce((a, b) => {
                                                            return (
                                                                Number(a) +
                                                                Number(
                                                                    b.balance_amount ? b.balance_amount : 0.0
                                                                )
                                                            );
                                                        }, 0)
                                                    ).format("$0,0.0'")}
                                                </b>
                                            </td>
                                            <td className="text-right">
                                                <b>
                                                    {numeral(
                                                        data.reduce((a, b) => {
                                                            return (
                                                                Number(a) + Number(b.kb_fee ? b.kb_fee : 0.0)
                                                            );
                                                        }, 0)
                                                    ).format("$0,0.0'")}
                                                </b>
                                            </td>
                                            <td className="text-right">
                                                <b>
                                                    {numeral(
                                                        data.reduce((a, b) => {
                                                            return (
                                                                Number(a) + Number(b.net_brand_amount ? b.net_brand_amount : 0.0)
                                                            );
                                                        }, 0)
                                                    ).format("$0,0.0'")}
                                                </b>
                                            </td>
                                            {/* <td className="text-right">
                        <b>
                          {numeral(
                            data.reduce((a, b) => {
                              return (
                                Number(a) +
                                Number(
                                  b.cashback_amount ? b.cashback_amount : 0.0
                                )
                              );
                            }, 0)
                          ).format("$0,0.0'")}
                        </b>
                      </td> */}
                                            {/* <td className="text-right">
                        <b>
                          {numeral(
                            data.reduce((a, b) => {
                              return (
                                Number(a) +
                                Number(
                                  b.customer_commission
                                    ? b.customer_commission
                                    : 0.0
                                )
                              );
                            }, 0)
                          ).format("$0,0.0'")}
                        </b>
                      </td> */}
                                            {/* <td className="text-right">
                        <b>
                          {numeral(
                            data.reduce((a, b) => {
                              return (
                                Number(a) +
                                Number(
                                  b.creator_commission
                                    ? b.creator_commission
                                    : 0.0
                                )
                              );
                            }, 0)
                          ).format("$0,0.0'")}
                        </b>
                      </td> */}

                                        </tr>
                                    )}
                                </tbody>
                            </table>
                        </div>
                    </>
                    );
                case "purchased":
                    return (<>
                        <div className="table-responsive scrollbar-style">
                            <table className="transactions-box table">
                                <thead className="table_heading">
                                    <tr>
                                        <th className="td-width-60">S.#</th>
                                        <th className="td-width-150">Purchaser</th>
                                        <th className="td-width-150">Total Orders</th>
                                        <th className="td-width-150 text-right">Gross Sales</th>
                                        <th className="td-width-150 text-right">Return Amount</th>
                                        <th className="td-width-150 text-right">Balance Amount</th>
                                        <th className="td-width-150 text-right">ORME Fee</th>
                                        <th className="td-width-150 text-right">Net Sales</th>
                                        {/* <th className="td-width-150 text-right">Cashback</th>
                    <th className="td-width-150 text-right">Referral Fee</th>
                    <th className="td-width-150 text-right">Content Fee</th> */}
                                    </tr>
                                </thead>
                                <tbody>
                                    {shopifyCustomerorder.loading ? (
                                        <div className="antd-loading-ift">
                                            <Spin size="large"></Spin>
                                        </div>
                                    ) : shopifyCustomerorder?.payload?.message?.data.length ===
                                        0 ? (
                                        <tr>
                                            <td colspan="12">
                                                <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                                            </td>
                                        </tr>
                                    ) : (
                                        data?.map((item, i) => {
                                            return (
                                                <tr>
                                                    <td>{i + 1 + currentPage * 15}</td>
                                                    <td>
                                                        {item?.event === "productshare"
                                                            ? "Anonymous"
                                                            : (item?.customer?.name ? item?.customer?.name : "-")}
                                                    </td>
                                                    <td>

                                                        {item?.order_count ? item?.order_count : "-"}
                                                    </td>

                                                    <td className="text-right">
                                                        {numeral(item?.gross_amount).format("$0,0.0'")}
                                                    </td>
                                                    <td className="text-right">
                                                        {numeral(item?.returned_amount).format("$0,0.0'")}
                                                    </td>
                                                    <td className="text-right">
                                                        {numeral(item?.balance_amount).format("$0,0.0'")}
                                                    </td>
                                                    <td className="text-right">{numeral(item?.kb_fee).format("$0,0.0'")}</td>
                                                    <td className="text-right">{numeral(item?.net_brand_amount).format("$0,0.0'")}</td>
                                                    {/* <td className="text-right">
                            {numeral(item?.cashback_amount).format("$0,0.0'")}
                          </td>
                          <td className="text-right">
                            {numeral(item?.customer_commission).format("$0,0.0'")}
                          </td>
                          <td className="text-right">
                            {numeral(item?.creator_commission).format("$0,0.0'")}
                          </td> */}
                                                </tr>
                                            );
                                        })
                                    )}

                                    {shopifyCustomerorder?.payload?.message?.data.length > 0 && (
                                        <tr>
                                            <td></td>

                                            <td>
                                                <b>Total</b>
                                            </td>
                                            <td>
                                                <b>
                                                    {shopifyCustomerorder?.payload?.message?.data.reduce((a, b) => {
                                                        return (
                                                            Number(a) +
                                                            Number(
                                                                b.order_count ? b.order_count : 0.0
                                                            )
                                                        );
                                                    }, 0)
                                                    }
                                                </b>
                                            </td>

                                            <td className="text-right">
                                                <b>
                                                    {numeral(
                                                        data.reduce((a, b) => {
                                                            return (
                                                                Number(a) +
                                                                Number(
                                                                    b.gross_amount ? b.gross_amount : 0.0
                                                                )
                                                            );
                                                        }, 0)
                                                    ).format("$0,0.0'")}
                                                </b>
                                            </td>
                                            <td className="text-right">
                                                <b>
                                                    {numeral(
                                                        data.reduce((a, b) => {
                                                            return (
                                                                Number(a) +
                                                                Number(
                                                                    b.returned_amount ? b.returned_amount : 0.0
                                                                )
                                                            );
                                                        }, 0)
                                                    ).format("$0,0.0'")}
                                                </b>
                                            </td>
                                            <td className="text-right">
                                                <b>
                                                    {numeral(
                                                        data.reduce((a, b) => {
                                                            return (
                                                                Number(a) +
                                                                Number(
                                                                    b.balance_amount ? b.balance_amount : 0.0
                                                                )
                                                            );
                                                        }, 0)
                                                    ).format("$0,0.0'")}
                                                </b>
                                            </td>
                                            <td className="text-right">
                                                <b>
                                                    {numeral(
                                                        data.reduce((a, b) => {
                                                            return (
                                                                Number(a) + Number(b.kb_fee ? b.kb_fee : 0.0)
                                                            );
                                                        }, 0)
                                                    ).format("$0,0.0'")}
                                                </b>
                                            </td>
                                            <td className="text-right">
                                                <b>
                                                    {numeral(
                                                        data.reduce((a, b) => {
                                                            return (
                                                                Number(a) + Number(b.net_brand_amount ? b.net_brand_amount : 0.0)
                                                            );
                                                        }, 0)
                                                    ).format("$0,0.0'")}
                                                </b>
                                            </td>
                                            {/* <td className="text-right">
                        <b>
                          {numeral(
                            data.reduce((a, b) => {
                              return (
                                Number(a) +
                                Number(
                                  b.cashback_amount ? b.cashback_amount : 0.0
                                )
                              );
                            }, 0)
                          ).format("$0,0.0'")}
                        </b>
                      </td> */}
                                            {/* <td className="text-right">
                        <b>
                          {numeral(
                            data.reduce((a, b) => {
                              return (
                                Number(a) +
                                Number(
                                  b.customer_commission
                                    ? b.customer_commission
                                    : 0.0
                                )
                              );
                            }, 0)
                          ).format("$0,0.0'")}
                        </b>
                      </td> */}
                                            {/* <td className="text-right">
                        <b>
                          {numeral(
                            data.reduce((a, b) => {
                              return (
                                Number(a) +
                                Number(
                                  b.creator_commission
                                    ? b.creator_commission
                                    : 0.0
                                )
                              );
                            }, 0)
                          ).format("$0,0.0'")}
                        </b>
                      </td> */}


                                        </tr>
                                    )}
                                </tbody>
                            </table>
                        </div>
                    </>
                    );
                case "referred":
                    return (<>
                        <div className="table-responsive scrollbar-style">
                            <table className="transactions-box table">
                                <thead className="table_heading">
                                    <tr>
                                        <th className="td-width-60">S.#</th>
                                        <th className="td-width-150">Referrer</th>
                                        <th className="td-width-150">Total Orders</th>
                                        <th className="td-width-150 text-right">Gross Sales</th>
                                        <th className="td-width-150 text-right">Return Amount</th>
                                        <th className="td-width-150 text-right">Balance Amount</th>
                                        <th className="td-width-150 text-right">ORME Fee</th>
                                        <th className="td-width-150 text-right">Net Sales</th>
                                        {/* <th className="td-width-150 text-right">Cashback</th>
                    <th className="td-width-150 text-right">Referral Fee</th>
                    <th className="td-width-150 text-right">Content Fee</th> */}
                                    </tr>
                                </thead>
                                <tbody>
                                    {shopifyCustomerorder.loading ? (
                                        <div className="antd-loading-ift">
                                            <Spin size="large"></Spin>
                                        </div>
                                    ) : shopifyCustomerorder?.payload?.message?.data.length ===
                                        0 ? (
                                        <tr>
                                            <td colspan="12">
                                                <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                                            </td>
                                        </tr>
                                    ) : (
                                        data?.map((item, i) => {
                                            return (
                                                <tr>
                                                    <td>{i + 1 + currentPage * 15}</td>
                                                    <td>
                                                        {item?.affiliate?.name ? item?.affiliate?.name : "-"}
                                                    </td>
                                                    <td>

                                                        {item?.order_count ? item?.order_count : "-"}
                                                    </td>

                                                    <td className="text-right">
                                                        {numeral(item?.gross_amount).format("$0,0.0'")}
                                                    </td>
                                                    <td className="text-right">
                                                        {numeral(item?.returned_amount).format("$0,0.0'")}
                                                    </td>
                                                    <td className="text-right">
                                                        {numeral(item?.balance_amount).format("$0,0.0'")}
                                                    </td>
                                                    <td className="text-right">{numeral(item?.kb_fee).format("$0,0.0'")}</td>
                                                    <td className="text-right">{numeral(item?.net_brand_amount).format("$0,0.0'")}</td>
                                                    {/* <td className="text-right">
                            {numeral(item?.cashback_amount).format("$0,0.0'")}
                          </td>
                          <td className="text-right">
                            {numeral(item?.customer_commission).format("$0,0.0'")}
                          </td>
                          <td className="text-right">
                            {numeral(item?.creator_commission).format("$0,0.0'")}
                          </td> */}
                                                </tr>
                                            );
                                        })
                                    )}

                                    {shopifyCustomerorder?.payload?.message?.data.length > 0 && (
                                        <tr>
                                            <td></td>

                                            <td>
                                                <b>Total</b>
                                            </td>
                                            <td>
                                                <b>
                                                    {shopifyCustomerorder?.payload?.message?.data.reduce((a, b) => {
                                                        return (
                                                            Number(a) +
                                                            Number(
                                                                b.order_count ? b.order_count : 0.0
                                                            )
                                                        );
                                                    }, 0)
                                                    }
                                                </b>
                                            </td>

                                            <td className="text-right">
                                                <b>
                                                    {numeral(
                                                        data.reduce((a, b) => {
                                                            return (
                                                                Number(a) +
                                                                Number(
                                                                    b.gross_amount ? b.gross_amount : 0.0
                                                                )
                                                            );
                                                        }, 0)
                                                    ).format("$0,0.0'")}
                                                </b>
                                            </td>
                                            <td className="text-right">
                                                <b>
                                                    {numeral(
                                                        data.reduce((a, b) => {
                                                            return (
                                                                Number(a) +
                                                                Number(
                                                                    b.returned_amount ? b.returned_amount : 0.0
                                                                )
                                                            );
                                                        }, 0)
                                                    ).format("$0,0.0'")}
                                                </b>
                                            </td>
                                            <td className="text-right">
                                                <b>
                                                    {numeral(
                                                        data.reduce((a, b) => {
                                                            return (
                                                                Number(a) +
                                                                Number(
                                                                    b.balance_amount ? b.balance_amount : 0.0
                                                                )
                                                            );
                                                        }, 0)
                                                    ).format("$0,0.0'")}
                                                </b>
                                            </td>
                                            <td className="text-right">
                                                <b>
                                                    {numeral(
                                                        data.reduce((a, b) => {
                                                            return (
                                                                Number(a) + Number(b.kb_fee ? b.kb_fee : 0.0)
                                                            );
                                                        }, 0)
                                                    ).format("$0,0.0'")}
                                                </b>
                                            </td>
                                            <td className="text-right">
                                                <b>
                                                    {numeral(
                                                        data.reduce((a, b) => {
                                                            return (
                                                                Number(a) + Number(b.net_brand_amount ? b.net_brand_amount : 0.0)
                                                            );
                                                        }, 0)
                                                    ).format("$0,0.0'")}
                                                </b>
                                            </td>
                                            {/* <td className="text-right">
                        <b>
                          {numeral(
                            data.reduce((a, b) => {
                              return (
                                Number(a) +
                                Number(
                                  b.cashback_amount ? b.cashback_amount : 0.0
                                )
                              );
                            }, 0)
                          ).format("$0,0.0'")}
                        </b>
                      </td> */}
                                            {/* <td className="text-right">
                        <b>
                          {numeral(
                            data.reduce((a, b) => {
                              return (
                                Number(a) +
                                Number(
                                  b.customer_commission
                                    ? b.customer_commission
                                    : 0.0
                                )
                              );
                            }, 0)
                          ).format("$0,0.0'")}
                        </b>
                      </td> */}
                                            {/* <td className="text-right">
                        <b>
                          {numeral(
                            data.reduce((a, b) => {
                              return (
                                Number(a) +
                                Number(
                                  b.creator_commission
                                    ? b.creator_commission
                                    : 0.0
                                )
                              );
                            }, 0)
                          ).format("$0,0.0'")}
                        </b>
                      </td> */}


                                        </tr>
                                    )}
                                </tbody>
                            </table>
                        </div>
                    </>
                    );
                default:
                    return (<>
                        <div className="table-responsive scrollbar-style">
                            <table className="transactions-box table">
                                <thead className="table_heading">
                                    <tr>
                                        <th className="td-width-60">S.#</th>
                                        <th className="td-width-150">Order Date</th>
                                        <th className="td-width-150">Order #</th>
                                        <th className="td-width-150">Department</th>
                                        <th className="td-width-150">Category</th>
                                        <th className="td-width-150">Sub Category</th>
                                        <th className="td-width-150">Channel</th>
                                        <th className="td-width-150">Content Source</th>
                                        <th className="td-width-150">Purchaser</th>
                                        <th className="td-width-150">Referrer</th>
                                        <th className="td-width-150 text-right">Gross Sales</th>
                                        <th className="td-width-150 text-right">Return Amount</th>
                                        <th className="td-width-150 text-right">Balance Amount</th>
                                        <th className="td-width-150 text-right">ORME Fee</th>
                                        {/* <th className="td-width-150 text-right">Cashback</th>
                    <th className="td-width-150 text-right">Referral Fee</th>
                    <th className="td-width-150 text-right">Creator Fee</th> */}
                                    </tr>
                                </thead>
                                <tbody>
                                    {shopifyCustomerorder.loading ? (
                                        <div className="antd-loading-ift">
                                            <Spin size="large"></Spin>
                                        </div>
                                    ) : shopifyCustomerorder?.payload?.message?.data.length ===
                                        0 ? (
                                        <tr>
                                            <td colspan="12">
                                                <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                                            </td>
                                        </tr>
                                    ) : (
                                        data?.map((item, i) => {
                                            return (
                                                <tr>
                                                    <td>{i + 1 + currentPage * 15}</td>
                                                    <td>
                                                        {" "}
                                                        {moment(item?.created_date).format("MM-DD-YYYY")}
                                                    </td>
                                                    <td>
                                                        <a
                                                            className="open-modal blue-link"
                                                            onClick={() => {
                                                                detailView(item);
                                                            }}
                                                        >
                                                            {item?.order_id ? item?.order_id : "-"}
                                                        </a>
                                                    </td>
                                                    <td>
                                                        {item?.gen_category?.name ? item?.gen_category?.name : "All"}
                                                    </td>
                                                    <td>
                                                        {item?.category?.category_name ? item?.category?.category_name : "All"}
                                                    </td>
                                                    <td>
                                                        {item?.sub_category?.sub_category_name ? item?.sub_category?.sub_category_name : "All"}
                                                    </td>
                                                    <td className="firstLetterCapital">
                                                        {item?.channel ? item?.channel : "-"}
                                                    </td>
                                                    <td className="firstLetterCapital">
                                                        {item?.content_by ? item?.content_by : "-"}
                                                    </td>
                                                    <td>
                                                        {item?.customer?.name ? item?.customer?.name : "-"}
                                                    </td>
                                                    <td>
                                                        {item?.affiliate?.name ? item?.affiliate?.name : "-"}
                                                    </td>
                                                    <td className="text-right">
                                                        {numeral(item?.gross_amount).format("$0,0.0'")}
                                                    </td>
                                                    <td className="text-right">
                                                        {numeral(item?.returned_amount).format("$0,0.0'")}
                                                    </td>
                                                    <td className="text-right">
                                                        {numeral(item?.balance_amount).format("$0,0.0'")}
                                                    </td>
                                                    <td className="text-right">{numeral(item?.kb_fee).format("$0,0.0'")}</td>

                                                    {/* <td className="text-right">
                            {numeral(item?.cashback_amount).format("$0,0.0'")}
                          </td>
                          <td className="text-right">
                            {numeral(item?.customer_commission).format("$0,0.0'")}
                          </td>
                          <td className="text-right">
                            {numeral(item?.creator_commission).format("$0,0.0'")}
                          </td> */}
                                                </tr>
                                            );
                                        })
                                    )}

                                    {shopifyCustomerorder?.payload?.message?.data.length > 0 && (
                                        <tr>
                                            <td></td>
                                            <td>
                                                <b>Total</b>
                                            </td>
                                            <td>
                                                <b>
                                                    {shopifyCustomerorder?.payload?.message?.data?.length +
                                                        currentPage * 15}
                                                </b>
                                            </td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td className="text-right">
                                                <b>
                                                    {numeral(
                                                        data.reduce((a, b) => {
                                                            return (
                                                                Number(a) +
                                                                Number(
                                                                    b.gross_amount ? b.gross_amount : 0.0
                                                                )
                                                            );
                                                        }, 0)
                                                    ).format("$0,0.0'")}
                                                </b>
                                            </td>
                                            <td className="text-right">
                                                <b>
                                                    {numeral(
                                                        data.reduce((a, b) => {
                                                            return (
                                                                Number(a) +
                                                                Number(
                                                                    b.returned_amount ? b.returned_amount : 0.0
                                                                )
                                                            );
                                                        }, 0)
                                                    ).format("$0,0.0'")}
                                                </b>
                                            </td>
                                            <td className="text-right">
                                                <b>
                                                    {numeral(
                                                        data.reduce((a, b) => {
                                                            return (
                                                                Number(a) +
                                                                Number(
                                                                    b.balance_amount ? b.balance_amount : 0.0
                                                                )
                                                            );
                                                        }, 0)
                                                    ).format("$0,0.0'")}
                                                </b>
                                            </td>
                                            <td className="text-right">
                                                <b>
                                                    {numeral(
                                                        data.reduce((a, b) => {
                                                            return (
                                                                Number(a) + Number(b.kb_fee ? b.kb_fee : 0.0)
                                                            );
                                                        }, 0)
                                                    ).format("$0,0.0'")}
                                                </b>
                                            </td>
                                            {/* <td className="text-right">
                        <b>
                          {numeral(
                            data.reduce((a, b) => {
                              return (
                                Number(a) +
                                Number(
                                  b.cashback_amount ? b.cashback_amount : 0.0
                                )
                              );
                            }, 0)
                          ).format("$0,0.0'")}
                        </b>
                      </td> */}
                                            {/* <td className="text-right">
                        <b>
                          {numeral(
                            data.reduce((a, b) => {
                              return (
                                Number(a) +
                                Number(
                                  b.customer_commission
                                    ? b.customer_commission
                                    : 0.0
                                )
                              );
                            }, 0)
                          ).format("$0,0.0'")}
                        </b>
                      </td> */}
                                            {/* <td className="text-right">
                        <b>
                          {numeral(
                            data.reduce((a, b) => {
                              return (
                                Number(a) +
                                Number(
                                  b.creator_commission
                                    ? b.creator_commission
                                    : 0.0
                                )
                              );
                            }, 0)
                          ).format("$0,0.0'")}
                        </b>
                      </td> */}


                                        </tr>
                                    )}
                                </tbody>
                            </table>
                        </div>
                    </>
                    );

            }
        }
    }
    // const columns = [
    //     {
    //         title: 'S.No',
    //         align: "left",
    //         width: 20,
    //         render: (text, record, index) => {
    //             return (
    //                 <>
    //                     {(index + 1) + (currentPage * 20)}
    //                 </>
    //             );
    //         },
    //     },
    //     {
    //         title: 'Order',
    //         align: "left",
    //         dataIndex: 'name',
    //         key: 'name',
    //         render: (text, record, index) => {
    //             return (
    //                 <>
    //                     <a
    //                         className="open-modal blue-link"
    //                         onClick={() => {
    //                             detailView(record);
    //                         }}
    //                     >
    //                         {record?.name}
    //                     </a>
    //                 </>
    //             );
    //         },
    //     },
    //     {
    //         title: 'Order Date',
    //         align: "left",
    //         dataIndex: 'date',
    //         key: 'date',
    //         render: (text, record, index) => {
    //             return (
    //                 <>
    //                     {moment(record?.created_date).calendar()}
    //                 </>
    //             );
    //         },
    //     },
    //     {
    //         title: 'Customer',
    //         align: "left",
    //         render: (text, record, index) => {
    //             return (
    //                 <>
    //                     {record?.shipping_address[0]?.name}
    //                 </>
    //             );
    //         },

    //     },
    //     {
    //         title: 'Total',
    //         align: "left",
    //         // dataIndex: 'total_price',
    //         // key: 'total_price',
    //         render: (text, record, index) => {
    //             return (
    //                 <>
    //                     $ {record?.total_price}
    //                 </>
    //             );
    //         },
    //     },
    //     {
    //         title: 'Payment Status',
    //         align: "left",
    //         // dataIndex: 'financial_status',
    //         // key: 'financial_status',
    //         render: (text, record, index) => {
    //             return (
    //                 <>
    //                     {
    //                         record?.financial_status ?
    //                             <div className="connection-status-badge-grey firstLetterCapital wfit">
    //                                 {record?.financial_status}
    //                             </div>
    //                             : "-"
    //                     }
    //                 </>
    //             );
    //         },
    //     },
    //     {
    //         title: 'Fulfillment Status',
    //         align: "left",
    //         // dataIndex: 'fulfillment_status',
    //         // key: 'fulfillment_status',
    //         render: (text, record, index) => {
    //             return (
    //                 <>
    //                     {record?.fulfillments.length === 0 ?
    //                         <div className="connection-status-badge-grey firstLetterCapital wfit">
    //                             Unfulfilled
    //                         </div>
    //                         : record?.fulfillments.length > 0 ?
    //                             <div className="connection-status-badge-green firstLetterCapital wfit">
    //                                 Fulfilled
    //                             </div>
    //                             : "-"}
    //                 </>
    //             );
    //         },
    //     },
    //     {
    //         title: 'Items',
    //         align: "left",
    //         render: (text, record, index) => {
    //             return (
    //                 <>
    //                     <div className='d-flex align-items-center'>
    //                         <Dropdown overlay={() => menu(record)} trigger={['click']}>
    //                             <a onClick={(e) => e.preventDefault()}>
    //                                 <Space>
    //                                     {record?.line_items.length} {record?.line_items.length === 1 ? "item" : "items"}
    //                                     <DownOutlined />
    //                                 </Space>
    //                             </a>
    //                         </Dropdown>
    //                         {/* <Dropdown
    //                             key={index}
    //                             menu={
    //                                 [
    //                                     {
    //                                         label: <a href="https://www.antgroup.com">1st menu item</a>,
    //                                         key: '0',
    //                                     },
    //                                     {
    //                                         label: <a href="https://www.aliyun.com">2nd menu item</a>,
    //                                         key: '1',
    //                                     },

    //                                 ]
    //                             }
    //                             trigger={['click']}
    //                         >
    //                             <a onClick={(e) => e.preventDefault()}>
    //                                 <Space>
    //                                     {record?.line_items.length} {record?.line_items.length === 1 ? "item" : "items"}
    //                                     <DownOutlined />
    //                                 </Space>
    //                             </a>
    //                         </Dropdown> */}
    //                     </div >

    //                 </>
    //             );
    //         },
    //     },
    //     {
    //         title: 'Delivery Method',
    //         align: "left",
    //         render: (text, record, index) => {
    //             return (
    //                 <>
    //                     {record?.shipping_lines[0]?.title}
    //                 </>
    //             );
    //         },
    //     },
    //     // {
    //     //     title: 'SKU',
    //     //     dataIndex: 'sku',
    //     //     key: 'sku',
    //     //     align: "left",
    //     //     render: (text, record, index) => {
    //     //         return (
    //     //             <>
    //     //                 {record?.variants[0]?.sku ? record?.variants[0]?.sku : '-'}
    //     //             </>
    //     //         );
    //     //     },
    //     // },
    //     // {
    //     //     title: 'Image',
    //     //     dataIndex: 'Image',
    //     //     key: "image",
    //     //     align: "left",
    //     //     render: (text, record, index) => {
    //     //         return (
    //     //             <div className="listing-image inventory-image text-center">
    //     //                 <Image
    //     //                     onClick={(e) => {
    //     //                         e.stopPropagation();
    //     //                         handleImageClick(record?.image?.src ? record?.image?.src : defaultImage)
    //     //                     }
    //     //                     }
    //     //                     src={record?.image?.src ? record?.image?.src : defaultImage}
    //     //                     preview={false}
    //     //                 />
    //     //             </div>
    //     //         );
    //     //     },
    //     // },
    //     // {
    //     //     title: 'Product',
    //     //     dataIndex: 'Title',
    //     //     key: "title",
    //     //     align: "left",
    //     //     width: 300,
    //     //     render: (text, record, index) => {
    //     //         return (
    //     //             <>
    //     //                 <span title={record?.title}>
    //     //                     {record?.title && record?.title.length > 30
    //     //                         ? record?.title
    //     //                             .replace(/<\/?[^>]+(>|$)/g, "")
    //     //                             .slice(0, 30) + "..."
    //     //                         : record?.title}
    //     //                 </span>
    //     //             </>
    //     //         );
    //     //     },
    //     // },
    //     // {
    //     //     title: 'Status',
    //     //     dataIndex: 'Status',
    //     //     key: "status",
    //     //     align: "left",
    //     //     render: (text, record, index) => {
    //     //         return (
    //     //             <>
    //     //                 {/* $ */}
    //     //                 {/* {record?.status === "active" ? <Tag color="green">Active</Tag> : <Tag color="blue">Draft</Tag>} */}
    //     //                 <Popover
    //     //                     content={content(record)}
    //     //                     title="Select Option"
    //     //                     trigger="click"
    //     //                     open={open && index === selectIndex}
    //     //                     onClick={(e) => {
    //     //                         if (record?.status === 'active' || record?.status === 'draft') {
    //     //                             e.stopPropagation();
    //     //                             handleOpenChange();
    //     //                             setSelectIndex(index)
    //     //                         }
    //     //                     }}
    //     //                     placement="left"
    //     //                     className="cursor-pointer"
    //     //                 >
    //     //                     {record?.status === "active" ? <Tag color="green">Active <DownOutlined className="status-icon" /></Tag> : record?.status === "draft" ? <Tag color="blue">Draft <DownOutlined className="status-icon" /></Tag> : "-"}
    //     //                 </Popover>
    //     //             </>
    //     //         );
    //     //     },
    //     // },
    //     // {
    //     //   title: 'Inventory',
    //     //   dataIndex: 'Inventory',
    //     //   key: "inventory",
    //     //   align: "left",
    //     //   render: (text, record, index) => {
    //     //     return (
    //     //       <>
    //     //         {getInventory(record)}
    //     //       </>
    //     //     );
    //     //   },
    //     // },
    //     // {
    //     //     title: 'Price',
    //     //     dataIndex: 'Price',
    //     //     key: "price",
    //     //     align: "left",
    //     //     render: (text, record, index) => {
    //     //         return (
    //     //             <>
    //     //                 $
    //     //                 {/* {record?.variants[0]?.price && numeral(record?.variants[0]?.price).format('$0,0.00')} */}
    //     //             </>
    //     //         );
    //     //     },
    //     // },
    //     // {
    //     //     title: 'Inventory',
    //     //     dataIndex: 'Inventory',
    //     //     key: "stock",
    //     //     align: "left",
    //     //     width: 300,
    //     //     render: (text, record, index) => {
    //     //         return (
    //     //             <div className="balance-area justify-content-between ">
    //     //                 {/* <div className="balance-count  w-92"> */}

    //     //                 {/* {getInventory(record)} */}
    //     //                 {/* {numeral(record?.variants?.reduce((acc, it) => {
    //     //             return (acc = acc + it.inventory_quantity);
    //     //           }, 0)).format('0,0')} {" "}
    //     //           {numeral(record?.variants?.reduce((acc, it) => {
    //     //             return (acc = acc + it.inventory_quantity);
    //     //           }, 0)).format('0,0') < 0 && <Tag color="orange" className="m-0 fs-10">Pre Order</Tag>} */}
    //     //                 {/* </div> */}
    //     //                 <div className="balance-area">
    //     //                     <a
    //     //                         className="balance-btn default-btn outline small-btn ml-2"
    //     //                         onClick={(e) => {
    //     //                             e.stopPropagation();
    //     //                             // showDetailModal(record);
    //     //                         }}
    //     //                     >
    //     //                         View
    //     //                     </a>
    //     //                 </div>
    //     //             </div>
    //     //         );
    //     //     },
    //     // },
    //     // {
    //     //     title: '',
    //     //     dataIndex: '',
    //     //     key: "",
    //     //     align: "",
    //     //     width: 60,
    //     // },
    //     // {
    //     //   title: 'Inventory Level',
    //     //   dataIndex: 'Inventory Level',
    //     //   key: "stocklevel",
    //     //   align: "left",
    //     //   render: (text, record, index) => {
    //     //     return (
    //     //       <>
    //     //         {getStatus(record)}
    //     //       </>
    //     //     );
    //     //   },
    //     // },
    //     // {
    //     //     title: (
    //     //         // ormeActive === "active" ? <Checkbox
    //     //         //   checked={searchSKUs.length > 0} defaultChecked disabled
    //     //         //   className="disable-checkbox"
    //     //         //   onChange={handleSelectAll}
    //     //         // />
    //     //         //   : 
    //     //         searchSKUs.length > 0 &&
    //     //         <>
    //     //             <span> Select All</span>
    //     //             <Tooltip placement="topLeft" title={getProduct && getProduct?.loading ? "Please wait while products are syncing" : ""}>
    //     //                 <Checkbox
    //     //                     disabled={getProduct && getProduct?.loading}
    //     //                     checked={selectedAllData}
    //     //                     onChange={handleSelectAll}
    //     //                     className="select-all-checkbox ml-2"
    //     //                 />
    //     //             </Tooltip>
    //     //         </>
    //     //     ),
    //     //     dataIndex: 'select',
    //     //     key: 'select',
    //     //     align: "right",
    //     //     width: "100",
    //     //     render: (_, record) => {
    //     //         // if (record?._source?.is_orme_product) {
    //     //         //   return <Checkbox checked defaultChecked disabled className="disable-checkbox" />
    //     //         // } else {
    //     //         return (
    //     //             <Tooltip placement="left" title={getProduct && getProduct?.loading ? "Please wait while products are syncing" : ""}>
    //     //                 <Checkbox
    //     //                     disabled={getProduct && getProduct?.loading}
    //     //                     checked={selectedRowKeys.includes(record.key)}
    //     //                     onChange={(e) => handleCheckboxChange(e, record)}
    //     //                     onClick={(e) => e.stopPropagation()}
    //     //                 />
    //     //             </Tooltip>
    //     //         )
    //     //         // }
    //     //     },
    //     // },
    //     // {
    //     //   title: 'Action',
    //     //   dataIndex: 'Action',
    //     //   key: "action",
    //     //   align: "center",
    //     //   render: (text, record, index) => {
    //     //     return (
    //     //       <>
    //     //         <div className="text-center">
    //     //           <a
    //     //             className="default-btn outline small-btn deactive-btn"
    //     //             onClick={(e) => {
    //     //               handleDeactive(record?._source?.id)
    //     //             }}
    //     //           >
    //     //             De-Active
    //     //           </a>
    //     //         </div>
    //     //       </>
    //     //     );
    //     //   },
    //     //   hidden: true,
    //     // },
    // ];

    useEffect(() => {
        const hasMatchingId = checkAllExist(allOrders, selectedRowData);
        setSelectedAllData(hasMatchingId)
    }, [selectedRowData, allOrders])


    const handleSelectAll = (e) => {
        if (e.target.checked) {
            // setSelectedAllData(false)
            const selectedKeysSet = new Set(selectedRowKeys);
            const newSelectedKeys = [...selectedRowKeys];
            const newSelectedData = [...selectedRowData];

            // Add all keys to the Set
            allOrders.forEach(it => selectedKeysSet.add(it.id));

            // Add new selected keys to the state
            selectedKeysSet.forEach(key => {
                if (!newSelectedKeys.includes(key)) {
                    newSelectedKeys.push(key);
                }
            });

            // Add new selected data to the state
            allOrders.forEach(it => {
                if (!selectedRowData.some(row => row.id === it.id)) {
                    newSelectedData.push(it);
                }
            });

            setSelectedRowKeys(newSelectedKeys);
            setSelectedRowData(newSelectedData);
            const hasMatchingId = checkAllExist(allOrders, selectedRowData);
            setSelectedAllData(hasMatchingId)
        } else {
            // setSelectedAllData(true)
            // Filter out the keys and data that are not in the current page
            const filteredKeys = selectedRowKeys.filter(key => !allOrders.some(item => item.id === key));
            const filteredData = selectedRowData.filter(item => !allOrders.some(it => it.id === item.id));

            setSelectedRowKeys(filteredKeys);
            setSelectedRowData(filteredData);
        }
    };

    function checkAllExist(searchSkus, selectedrow) {
        // If selectedrow is empty, return false
        if (selectedrow.length === 0) {
            return false;
        }

        // Check if every searchSKU exists in selectedrow
        // console.log({ searchSKUs, selectedRowData });
        return allOrders.every(sku => selectedrow.some(row => row.id === sku.id));
    }

    const handleCheckboxChange = (e, record) => {
        // console.log(record, 'record');
        const key = record.key;
        const selectedKeys = [...selectedRowKeys];
        const selectedData = [...selectedRowData]; // Assuming selectedRowData is the state to store selected data

        if (e.target.checked) {
            selectedKeys.push(key);
            selectedData.push(record);
        } else {
            const index = selectedKeys.indexOf(key);
            if (index !== -1) {
                selectedKeys.splice(index, 1);
                selectedData.splice(index, 1);
            }
        }

        setSelectedRowKeys(selectedKeys);
        setSelectedRowData(selectedData); // Update the state with selected data
    };



    const columns = [
        {
            title: 'S.No',
            align: "left",
            width: 20,
            render: (text, record, index) => {
                if (record.key === 'custom-row-total') return null;
                return (
                    <>
                        {(index + 1) + (currentPage * 15)}
                    </>
                );
            },
        },
        {
            title: 'Order',
            align: "left",
            dataIndex: 'name',
            key: 'name',
            render: (text, record) => {
                if (record.key === 'custom-row-total') return <b>Total</b>;
                return (
                    <a
                        className={`${record?.refunds?.[0]?.note == 'Order Cancelled' && 'strike-text'}`}
                    // className="open-modal blue-link"
                    // onClick={(e) => {
                    //     e.stopPropagation()
                    //     detailView(record);
                    // }}
                    >
                        {record?.name}
                    </a>
                );
            },
        },
        {
            title: 'Order Date',
            align: "left",
            dataIndex: 'date',
            key: 'date',
            render: (text, record) => {
                if (record.key === 'custom-row-total') return null;
                return <span className={record?.refunds?.[0]?.note == 'Order Cancelled' ? 'strike-text' : ''}>
                    {moment(record?.created_at, 'YYYY-MM-DDTHH:mm:ss.SSSZ').calendar(null, {
                        lastDay: '[Yesterday at] h:mm A',
                        sameDay: '[Today at] h:mm A',
                        nextDay: '[Tomorrow at] h:mm A',
                        lastWeek: 'dddd [at] h:mm A', // Adjust this line
                        nextWeek: 'dddd [at] h:mm A',
                        // sameElse: 'DD MMM [at] h:mm A',
                        sameElse: function (now) {
                            if (this.isBefore(now, 'year')) {
                                // For dates in the previous year
                                return 'DD MMM YYYY [at] h:mm A';
                            } else {
                                // For all other dates
                                return 'DD MMM [at] h:mm A';
                            }
                        }
                    })}
                </span>
            },
        },
        {
            title: 'Customer',
            align: "left",
            render: (text, record) => {
                if (record.key === 'custom-row-total') return null;
                return (
                    <span className={record?.refunds?.[0]?.note == 'Order Cancelled' ? 'strike-text' : ''}>
                        {record?.customer?.first_name} {record?.customer?.last_name}
                    </span>
                );
            },
        },
        {
            title: 'Total',
            align: "left",
            render: (text, record) => {
                if (record.key === 'custom-row-total') {
                    return <b className={record?.refunds?.[0]?.note == 'Order Cancelled' ? 'strike-text' : ''}>$ {record.current_total_price}</b>;
                }
                return <span className={record?.refunds?.[0]?.note == 'Order Cancelled' ? 'strike-text' : ''}>$ {record?.current_total_price}</span>;
            },
        },
        {
            title: 'Payment Status',
            align: "left",
            render: (text, record) => {
                if (record.key === 'custom-row-total') return null;
                return record?.financial_status ?
                    <div className="connection-status-badge-grey firstLetterCapital wfit">
                        {record?.financial_status}
                    </div>
                    : "-";
            },
        },
        {
            title: 'Fulfillment Status',
            align: "left",
            render: (text, record) => {
                if (record.key === 'custom-row-total') return null;
                return (record?.fulfillment_status === null) ?
                    <div className="connection-status-badge-yellow firstLetterCapital wfit">
                        Unfulfilled
                    </div>
                    : record?.fulfillment_status === 'partial' ?
                        <div className="connection-status-badge-grey firstLetterCapital wfit">
                            Partially Fulfilled
                        </div>
                        : record?.fulfillment_status === 'fulfilled' ?
                            <div className="connection-status-badge-grey firstLetterCapital wfit">
                                Fulfilled
                            </div>
                            : "-";
            },
        },
        {
            title: 'Items',
            align: "left",
            render: (text, record) => {
                if (record.key === 'custom-row-total') return null;
                return (
                    <div className='d-flex align-items-center' onClick={(e) => e.stopPropagation()}>
                        <Dropdown overlay={() => menu(record)} trigger={['click']} placement="bottom">
                            <a className="sc-order-items" style={{ width: '100%', padding: '8px' }} >
                                <Space className={`${record?.refunds?.[0]?.note == 'Order Cancelled' && 'strike-text'}`}>
                                    {record?.line_items.reduce((total, item) => total + item.quantity, 0)} {record?.line_items.reduce((total, item) => total + item.quantity, 0) === 1 ? "item" : "items"}
                                    <DownOutlined className="hover-icon mb-2" size={4} />
                                </Space>
                            </a>
                        </Dropdown>
                    </div >
                );
            },
        },
        {
            title: 'Delivery Status',
            align: "left",
            render: (text, record) => {
                if (record.key === 'custom-row-total') return null;
                return (
                    <div className='d-flex align-items-center'>
                        {record?.fulfillments && record?.fulfillments?.length > 0 ?
                            // record?.fulfillments?.[0]?.tracking_company ?
                            record?.fulfillments.some(item => item?.tracking_number !== null && item?.status == 'success') ?
                                <Dropdown overlay={() => menuDeliveryStatus(record)} trigger={['click']} placement="bottom">
                                    <a className="sc-order-items" style={{ width: '100%', padding: '8px' }} onClick={(e) => e.stopPropagation()}>
                                        <Space>
                                            Tracking added
                                            {
                                                (() => {
                                                    const count = record?.fulfillments.filter(item => item.status === 'success' && item.tracking_number !== null).length;
                                                    // if (count === 2) return "+1";
                                                    if (count === 1) return "";
                                                    // if (count === 3) return "+2";
                                                    return `+${count - 1}`;
                                                })()
                                            }
                                            <DownOutlined className="hover-icon mb-2" size={4} />
                                        </Space>
                                    </a>
                                </Dropdown>
                                :
                                <></>
                            :
                            <>
                            </>
                        }
                    </div >
                );
            },
        },
        {
            title: 'Delivery Method',
            align: "left",
            render: (text, record) => {
                if (record.key === 'custom-row-total') return null;
                return (
                    <span className={record?.refunds?.[0]?.note == 'Order Cancelled' ? 'strike-text' : ''}>
                        {record?.shipping_lines[0]?.title}
                    </span>
                );
            },
        },
        {
            title: (
                // ormeActive === "active" ? <Checkbox
                //   checked={searchSKUs.length > 0} defaultChecked disabled
                //   className="disable-checkbox"
                //   onChange={handleSelectAll}
                // />
                //   : 
                allOrders.length > 0 &&
                <>
                    <span> Select All</span>
                    <Checkbox
                        // disabled={allOrders && allOrders.length < 0}
                        checked={selectedAllData}
                        onChange={handleSelectAll}
                        className="select-all-checkbox ml-2"
                    />
                </>
            ),
            dataIndex: 'select',
            key: 'select',
            align: "right",
            width: "100",
            render: (_, record) => {
                if (record.key === 'custom-row-total') return null;
                // if (record?._source?.is_orme_product) {
                //   return <Checkbox checked defaultChecked disabled className="disable-checkbox" />
                // } else {
                return (
                    <Checkbox
                        // disabled={getProduct && getProduct?.loading}
                        checked={selectedRowKeys.includes(record.key)}
                        onChange={(e) => handleCheckboxChange(e, record)}
                        onClick={(e) => e.stopPropagation()}
                    />
                )
                // }
            },
        },
    ];
    const totalPriceSum = allOrders?.reduce((sum, order) => sum + parseFloat(order.current_total_price || 0), 0);
    const customRow = {
        key: 'custom-row-total',
        // name: 'Total',
        current_total_price: totalPriceSum.toFixed(2),
    };

    const handleDownload = () => {
        const csvContent = convertOrderToCSV(selectedRowData);
        downloadCSV('orders.csv', csvContent);
        setSelectedRowData([])
        setSelectedRowKeys([])
    };

    const convertOrderToCSV = (orders) => {
        // CSV headers
        const headers = [
            'Name',
            'Email',
            'Financial Status',
            'Fulfillment Status',
            'Fulfilled at',
            'Currency',
            'Subtotal',
            'Shipping',
            'Taxes',
            'Total',
            'Discount Code',
            'Discount Amount',
            'Shipping Method',
            'Created at',
            'Lineitem quantity',
            'Lineitem name',
            'Lineitem price',
            'Lineitem compare at price',
            'Lineitem sku',
            'Lineitem requires shipping',
            'Lineitem taxable',
            'Lineitem fulfillment status',
            'Billing Name',
            'Billing Street',
            'Billing Address1',
            'Billing Address2',
            'Billing Company',
            'Billing City',
            'Billing Zip',
            'Billing Province',
            'Billing Country',
            'Billing Phone',
            'Shipping Name',
            'Shipping Street',
            'Shipping Address1',
            'Shipping Address2',
            'Shipping Company',
            'Shipping City',
            'Shipping Zip',
            'Shipping Province',
            'Shipping Country',
            'Shipping Phone',
            'Notes',
            'Note Attributes',
            'Cancelled at',
            'Payment Method',
            'Refunded Amount',
            'Vendor',
            'Id',
            'Tags',
            'Risk Level',
            'Source',
            'Lineitem discount',
            'Tax 1 Name',
            'Tax 1 Value',
            'Tax 2 Name',
            'Tax 2 Value',
            'Tax 3 Name',
            'Tax 3 Value',
            'Tax 4 Name',
            'Tax 4 Value',
            'Tax 5 Name',
            'Tax 5 Value',
            'Phone',
            'Receipt Number',
            'Duties',
            'Billing Province Name',
            'Shipping Province Name',
            'Fulfillment Tracking Company',
            'Fulfillment Tracking Number',
            'Fulfillment Tracking Url'
        ];

        // Create rows array
        const rows = orders.flatMap(order =>
            order.line_items.map((item, index) => [
                order.name,
                order.customer.email,
                index === 0 ? order.financial_status : '',
                index === 0 ? order.fulfillments?.length > 0 ? 'fulfilled' : 'unfulfilled' : '',
                index === 0 ? order.fulfillments?.length > 0 ? order.fulfillments?.[0]?.created_at : '' : '',
                index === 0 ? order.currency : '',
                index === 0 ? order.subtotal_price : '',
                index === 0 ? order.total_shipping_price_set?.presentment_money?.amount : '',
                index === 0 ? order.total_tax : '',
                index === 0 ? order.current_total_price : '',
                index === 0 ? order.discount_codes : '',
                index === 0 ? order.total_discounts : '',
                index === 0 ? order.shipping_lines?.[0]?.code : '',
                order.created_at,
                item.quantity,
                item.name,
                item.price,
                item?.compare_at_price, //
                item.sku,
                item?.requires_shipping, //
                item.taxable,
                item.fulfillment_status ? item.fulfillment_status : 'pending', // pending
                index === 0 ? order.billing_address?.name : '',
                index === 0 ? order.billing_address?.street : '',
                index === 0 ? order.billing_address?.address1 : '',
                index === 0 ? order.billing_address?.address2 : '',
                index === 0 ? order.billing_address?.company : '',
                index === 0 ? order.billing_address?.city : '',
                index === 0 ? order.billing_address?.zip : '',
                index === 0 ? order.billing_address?.province : '',
                index === 0 ? order.billing_address?.country : '',
                index === 0 ? order.billing_address?.phone : '',
                index === 0 ? order.shipping_address?.name : '',
                index === 0 ? order.shipping_address?.street : '',
                index === 0 ? order.shipping_address?.address1 : '',
                index === 0 ? order.shipping_address?.address2 : '',
                index === 0 ? order.shipping_address?.company : '',
                index === 0 ? order.shipping_address?.city : '',
                index === 0 ? order.shipping_address?.zip : '',
                index === 0 ? order.shipping_address?.province : '',
                index === 0 ? order.shipping_address?.country : '',
                index === 0 ? order.shipping_address?.phone : '',
                index === 0 ? order.note : '',
                index === 0 ? order.note_attributes?.length > 0 ?
                    `${order.note_attributes?.[0]?.name}: ${order.note_attributes?.[0]?.value}`
                    : ''
                    : '', //note attributes
                index === 0 ? order.cancelled_at : '',
                index === 0 ? order.payment_terms : '',
                index === 0 ? order.refunds.length > 0 ? '' : '' : '',
                item?.vendor,
                index === 0 ? order.id : '',
                index === 0 ? order.tags : '',
                index === 0 ? order.risk_level : '', //
                index === 0 ? order.source_identifier : '', //
                item?.total_discount,
                index === 0 ? order?.tax_lines?.[0]?.title : '', //
                index === 0 ? order?.tax_lines?.[0]?.price : '', //
                index === 0 ? order?.tax_lines?.[1]?.title : '', //
                index === 0 ? order?.tax_lines?.[1]?.price : '', //
                index === 0 ? order?.tax_lines?.[2]?.title : '', //
                index === 0 ? order?.tax_lines?.[2]?.price : '', //
                index === 0 ? order?.tax_lines?.[3]?.title : '', //
                index === 0 ? order?.tax_lines?.[3]?.price : '', //
                index === 0 ? order?.tax_lines?.[4]?.title : '', //
                index === 0 ? order?.tax_lines?.[4]?.price : '', //
                index === 0 ? order?.customer?.phone : '', //
                index === 0 ? order?.receipt_number : '', //
                index === 0 ? order?.duties : '', //
                index === 0 ? order?.billing_address?.province : '', //
                index === 0 ? order?.shipping_address?.province : '', //
                index === 0 ? order.fulfillments?.length > 0
                    ? order?.fulfillments?.[0]?.tracking_company
                    : ''
                    : '',
                index === 0 ? order.fulfillments?.length > 0
                    ? order?.fulfillments?.[0]?.tracking_numbers
                    : ''
                    : '',
                index === 0 ? order.fulfillments?.length > 0
                    ? order?.fulfillments?.[0]?.tracking_url
                    : ''
                    : '',
            ])
        );

        // Combine headers and rows into CSV format
        const csvContent = [headers, ...rows].map(e => e.join(',')).join('\n');
        return csvContent;
    };

    // Function to trigger file download
    const downloadCSV = (filename, csvContent) => {
        const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
        const url = URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.setAttribute('href', url);
        link.setAttribute('download', filename);
        link.style.visibility = 'hidden';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    };

    return (
        <>
            <div className="top-btn-area mb-10 d-flex justify-content-between align-items-center">
                {validate?.payload?.message?.account_type === "brand" &&
                    <>
                        <div className="d-flex flex-row">
                            <Button
                                onClick={() => setShowBulkModal(true)}
                                className="ant-btn ant-btn-primary default-btn padb-3 small-btn m-width-100 ml-0  mb-1 fw-normal h-30 px-4"
                            >
                                <FontAwesomeIcon icon={faUpload} />
                                Import
                            </Button>
                        </div>

                    </>
                }
            </div>
            <div className="sales-page-main">
                {/* <div class="title-area mb-10">
          <h1>Orders</h1>
        </div> */}
                <div className="inventory-box-main sc-manage-order-cont">
                    <Form form={form}>
                        <div className="row w-20-desktop-main">
                            <div className="col-xl-3 col-md-6 col-12 mb-15">
                                <p className="mb-5rem">Select Start Date / End Date</p>
                                <RangePicker
                                    size="large"
                                    className="w-100"
                                    key={4}
                                    value={
                                        startDate && endDate
                                            ? [moment(startDate), moment(endDate)]
                                            : []
                                    }
                                    allowClear={false}
                                    ranges={{
                                        "All Time": [
                                            moment().subtract(1, "year").startOf("year"),
                                            moment(),
                                        ],
                                        "YTD": [moment().startOf("year"), moment()],
                                        "Last 30 Days": [moment().subtract(30, "days"), moment()],
                                        "Last 7 Days": [moment().subtract(7, "days"), moment()],
                                        "Today": [moment(), moment()],
                                    }}
                                    format={dateFormat}
                                    onChange={dateRangePickerChanger}
                                />
                            </div>
                            {/* <div className="col-xl-3 col-md-6 col-12 mb-15">
                                <p className="mb-5rem">Search ID</p>
                                <div className='d-flex align-items-center'>
                                    <Select
                                        placeholder="Type"
                                        size="large"
                                        className='idTypeSelect'
                                        style={{
                                            width: '35%',
                                        }}
                                        focus={false}
                                        loading={loading}
                                        onBlur={false}
                                        value={idType}
                                        onChange={(e) => handleIDType(e)}
                                    >
                                        <Option value="event">Event</Option>
                                        <Option value="show">Show</Option>
                                        <Option value="review">Review</Option>
                                    </Select>
                                    <Select
                                        showSearch
                                        size="large"
                                        className="w-100"
                                        key={4}
                                        disabled={idType ? false : true}
                                        notFoundContent={searchIDLoad && <Spin size="small" />}
                                        loading={searchIDLoad}
                                        optionFilterProp="children"
                                        placeholder="Enter ID"
                                        labelInValue
                                        onChange={(e) => setSearchID(e)}
                                        optionLabelProp="label"
                                        onSearch={handleReviewID}
                                        filterOption={(input, options) =>
                                            options.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                        }
                                        focus={false}
                                        value={searchID ? searchID?.label?.[0] + "-" + searchID?.label?.split(idType === "event" ? 'E' : (idType === "review" ? "R" : "S"))[1]?.split('-').join('')?.match(/.{1,3}/g)?.join('-') : null}
                                        onBlur={false}
                                    >
                                        {searchDataIDs && !searchIDLoad &&
                                            searchDataIDs?.map((item, i) => {
                                                return (
                                                    <Option key={item?._id ? item?._id : (item?._id ? item?._id : item?._id)} label={item?.event_counter ? item?.event_counter : (item?.review_counter ? item?.review_counter : item?.show_counter)} >
                                                        {
                                                            idType === "event" ? item?.event_counter && item?.event_counter?.[0] + "-" + item?.event_counter?.split('E')[1]?.split('-')?.join('')?.match(/.{1,3}/g)?.join('-') :
                                                                (idType === "review" ? item?.review_counter?.[0] + "-" + item?.review_counter?.split('R')[1]?.split('-')?.join('')?.match(/.{1,3}/g)?.join('-') :
                                                                    item?.show_counter?.[0] + "-" + item?.show_counter?.split('S')[1]?.split('-')?.join('')?.match(/.{1,3}/g)?.join('-'))
                                                        }
                                                    </Option>
                                                );
                                            })}
                                    </Select>
                                </div>

                            </div>

                            <div className="col-xl-3 col-md-6 col-12 mb-15">
                                <p className="mb-5rem">Department</p>
                                <Select
                                    size="large"
                                    className="w-100"
                                    key={4}
                                    placeholder="Select Department"
                                    focus={false}
                                    onChange={handleGender}
                                    value={
                                        gender
                                    }
                                    onBlur={false}
                                    loading={generalLoad}
                                    disabled={!savedGeneralCategories || generalLoad}
                                >

                                    {savedGeneralCategories &&
                                        savedGeneralCategories?.message?.map((item, i) => {
                                            return (
                                                <Option value={item?.category_id}>
                                                    {item?.name}
                                                </Option>
                                            );
                                        })}
                                </Select>
                            </div>
                            <div className="col-xl-3 col-md-6 col-12 mb-15">
                                <p className="mb-5rem">Category</p>
                                <Select
                                    size="large"
                                    className="w-100"
                                    key={4}
                                    placeholder="Select Category"
                                    focus={false}
                                    onChange={handleCategory}
                                    value={
                                        category
                                    }
                                    onBlur={false}
                                    loading={catLoad}
                                    disabled={(!savedCategory.success || catLoad) || !gender}
                                >
                                    <Option value="all">
                                        {"All"}
                                    </Option>
                                    {savedCategory &&
                                        savedCategory?.message?.map((item, i) => {
                                            return (
                                                <Option value={item?.category_id}>
                                                    {item?.category_name}
                                                </Option>
                                            );
                                        })}
                                </Select>
                            </div>
                            <div className="col-xl-3 col-md-6 col-12 mb-15">
                                <p className="mb-5rem">Sub Category</p>
                                <Select
                                    size="large"
                                    className="w-100"
                                    key={4}
                                    placeholder="Select Sub Category"
                                    focus={false}
                                    onChange={handleSubCategory}
                                    value={
                                        subCategory
                                    }
                                    onBlur={false}
                                    loading={subCatLoad}
                                    disabled={(!savedSubCategory?.success || subCatLoad) || (!gender || !category)}
                                >
                                    <Option value="">
                                        {"All"}
                                    </Option>
                                    {savedSubCategory &&
                                        savedSubCategory?.message?.map((item, i) => {
                                            return (
                                                <Option value={item?.sub_category_id}>
                                                    {item?.sub_category_name}
                                                </Option>
                                            );
                                        })}
                                </Select>
                            </div>


                            <div className="col-xl-3 col-md-6 col-12 mb-15">
                                <p className="mb-5rem">Channel</p>
                                <Select
                                    className="select-style dark-field w-100"
                                    placeholder="Source"
                                    size="large"
                                    focus={false}
                                    onBlur={false}
                                    loading={loading}
                                    disabled={loading}
                                    onChange={(value) => setChannel(value)}
                                    value={channel === "" ? null : channel}
                                >
                                    <Option value="all">All</Option>
                                    <Option value="direct">Direct</Option>
                                    <Option value="referred">Referred</Option>
                                </Select>
                            </div>
                            <div className="col-xl-3 col-md-6 col-12 mb-15">
                                <p className="mb-5rem">Content Source</p>
                                <Select
                                    className="select-style dark-field w-100"
                                    placeholder="Source"
                                    size="large"
                                    focus={false}
                                    onBlur={false}
                                    loading={loading}
                                    disabled={loading}
                                    onChange={(value) => setContentSource(value)}
                                    value={contentSource === "" ? null : contentSource}
                                >
                                    <Option value="all">All</Option>
                                    <Option value="brand">Brand</Option>
                                    <Option value="creator">Creator</Option>
                                </Select>
                            </div> */}


                            {/* <div className="col-xl-3 col-md-6 col-12 mb-15">
                                <p className="mb-5rem">Customer</p>
                                <Select
                                    showSearch
                                    size="large"
                                    className="w-100"
                                    key={4}
                                    notFoundContent={purchaseLoad ? <Spin size="small" /> : undefined}
                                    loading={purchaseLoad}
                                    optionFilterProp="children"
                                    placeholder="Enter Customer Name"
                                    onChange={(e) => setPurchaseBy(e)}
                                    onSearch={handleFilterPurchaseBy}
                                    filterOption={(input, options) =>
                                        options?.children?.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                    }
                                    focus={false}
                                    // onChange={handleFilterSKU}
                                    value={purchaseBy}
                                    onBlur={false}
                                >
                                    {searchPurchaseBy && !purchaseLoad &&
                                        searchPurchaseBy?.map((item, i) => {
                                            return (
                                                <Option value={item?._id}>
                                                    {item?.name + " (" + item?.email + ")"}
                                                </Option>
                                            );
                                        })}
                                </Select>
                            </div> */}
                            {/* <div className="col-xl-3 col-md-6 col-12 mb-15">
                                <p className="mb-5rem">Referrer</p>
                                <Select
                                    showSearch
                                    size="large"
                                    className="w-100"
                                    key={4}
                                    notFoundContent={referrerLoad && <Spin size="small" />}
                                    loading={referrerLoad}
                                    optionFilterProp="children"
                                    placeholder="Enter Referrer"
                                    onChange={(e) => setReferredBy(e)}
                                    onSearch={handleFilterReferredBy}
                                    filterOption={(input, options) =>
                                        options.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                    }
                                    focus={false}
                                    value={referredBy}
                                    onBlur={false}
                                >
                                    {searchReferredBy && !referrerLoad &&
                                        searchReferredBy?.map((item, i) => {
                                            return (
                                                <Option value={item?._id}>
                                                    {item?.name + " (" + item?.email + ")"}
                                                </Option>
                                            );
                                        })}
                                </Select>
                            </div>
                            <div className="col-xl-3 col-md-6 col-12 mb-15">
                                <p className="mb-5rem">Group By</p>
                                <Select
                                    className="w-100 select-style dark-field"
                                    placeholder="Select Group By"
                                    size="large"
                                    focus={false}
                                    loading={loading}
                                    disabled={loading}
                                    onBlur={false}
                                    value={groupBy}
                                    onChange={(e) => setGroupBy(e)}
                                // defaultValue="date"
                                >
                                    <Option value="">Order</Option>
                                    <Option value="channel">Channel</Option>
                                    <Option value="content">Content Source</Option>
                                    <Option value="date">Date</Option>
                                    <Option value="genCategory">Department</Option>
                                    <Option value="category">Category</Option>
                                    <Option value="subCategory">Sub Category</Option>
                                    <Option value="purchased">Purchaser</Option>
                                    <Option value="referred">Referred</Option>
                                </Select>
                            </div> */}
                            <div className="col-xl-3 col-md-6 col-12 d-flex align-items-end mb-15">
                                <Button
                                    onClick={handleSubmit}
                                    className="default-btn fltr-hpr  medium-btn m-width-100 ml-0 fw-normal"
                                    type="primary"
                                    htmlType="submit"
                                >
                                    Search
                                </Button>
                                <Button
                                    onClick={handleReset}
                                    className="default-btn outline fw-normal fltr-hpr medium-btn  res-btn2 min-w-100"
                                    type="primary"
                                    htmlType="submit"
                                >
                                    Reset
                                </Button>
                            </div>
                        </div>
                        {
                            selectedRowData.length > 0 &&
                            <>
                                <Affix offsetTop={0}
                                    className="affix-container"
                                    // style={{ top: 60 }}
                                    onChange={(affixed) => setAffixed(affixed)}
                                >
                                    <div className={affixed ? 'affix-background' : ''}>
                                        <span className="mb-10 mx-2">Selected {selectedRowData.length} orders</span>
                                        <Button
                                            onClick={handleDownload}
                                            className="default-btn fltr-hpr  medium-btn m-width-100 ml-0 fw-normal"
                                            type="primary"
                                            htmlType="submit"
                                        //   disabled={loading || skuLoad}
                                        >
                                            {"Export"}
                                        </Button>
                                    </div>
                                </Affix>
                            </>
                        }
                    </Form>
                    <hr className="separator-line mt-0" />
                    <div className="purchase-data referral-data">
                        {loading ? (
                            // <div className="antd-loading-ift">
                            // 	<Spin size="large" />
                            // </div>
                            <div className="loading-wrap">
                                <span className="spinclass loader-center position-relative">
                                    <Spin size="large" />
                                </span>
                            </div>
                        ) : (
                            <>
                                {/* {submit === "none" && <DefaultTable />} */}
                                {
                                    // submit === "none" &&

                                    <Table
                                        style={{ cursor: "pointer" }}
                                        // onRow={(record, rowIndex) => {
                                        //     return {
                                        //         onClick: event => { console.log("click", record); history(`/scorders/${record.id}`) }, // click row
                                        //     };
                                        // }}
                                        onRow={(record) => {
                                            if (record.key === 'custom-row-total') {
                                                return {}; // No click event for the total row
                                            }
                                            return {
                                                onClick: () => {
                                                    console.log("click", record);
                                                    history(`/scorders/${record.id}`);
                                                },
                                            };
                                        }}
                                        className="active-table mb-4"
                                        columns={columns}
                                        // dataSource={allOrders?.map((it) => ({ ...it, key: it?.id }))}
                                        dataSource={allOrders.length > 0 ? [
                                            ...allOrders?.map((it) => ({ ...it, key: it?.id })),
                                            customRow
                                        ] : []}
                                        pagination={false}
                                        scroll={{ x: 1200 }}
                                        size="small"
                                    />
                                }
                                <div className="purchase-data referral-data">
                                    <div style={{ position: 'relative' }}>
                                        {
                                            scGetAllOrders?.total_records > 0 &&
                                            <span style={{
                                                position: 'absolute',
                                                right: 90,
                                                top: '50%',
                                                transform: 'translateY(-50%)',
                                                paddingRight: '10px' // adjust as needed
                                            }}>
                                                Page {currentPage + 1} of {Math.ceil(scGetAllOrders?.total_records / 15)}
                                            </span>}
                                        {scGetAllOrders?.orders?.length ? (
                                            <ReactPaginate
                                                nextLabel={<FontAwesomeIcon icon={faAngleRight} />}
                                                onPageChange={handlePageClick}
                                                pageRangeDisplayed={window.innerWidth <= 760 ? 1 : 7}
                                                pageCount={Math.ceil(scGetAllOrders?.total_records / 15)}
                                                forcePage={currentPage}
                                                previousLabel={<FontAwesomeIcon icon={faAngleLeft} />}
                                                pageClassName="page-item d-none"
                                                pageLinkClassName="page-link"
                                                previousClassName="page-item"
                                                previousLinkClassName="page-link"
                                                nextClassName="page-item"
                                                nextLinkClassName="page-link"
                                                breakLabel="..."
                                                breakClassName="page-item d-none"
                                                breakLinkClassName="page-link"
                                                containerClassName={"pagination custom-paginate"}
                                                activeClassName="active"
                                                renderOnZeroPageCount={null}
                                            />
                                        ) : (
                                            ""
                                        )}
                                    </div>
                                </div>
                                {scGetAllOrders?.orders?.length > 0 && (
                                    <Summary />
                                )}
                            </>
                        )}
                    </div>
                </div>
            </div>


            <ModalA
                className="modal-generic modal-700"
                centered
                open={showBulkModal}
                onOk={handleOk}
                onCancel={handleCancel}
                closeIcon={<FontAwesomeIcon icon={faXmark} />}
                footer={false}
                maskClosable={false}
                keyboard={false}
            // footer={[
            //   <Button
            //     key="submit"
            //     type="primary"
            //     // className="d-table m-auto"
            //     onClick={handleOk}
            //   >
            //     Save
            //   </Button>,
            //   <Button key="back" onClick={handleCancel}>
            //     Exit
            //   </Button>,
            // ]}
            >
                <h2 className="modal-hd1">Update orders by CSV</h2>
                {!loading ? (
                    <Form form={scform}>
                        <div className="form-styling ">
                            <div className="row  d-flex align-items-center justify-content-center">
                            </div>

                            <div className="mb-2">
                                <Form.Item
                                    name="csv_file"
                                    valuePropName={"fileList"}
                                    getValueFromEvent={normFile}
                                    noStyle
                                    multiple={false}
                                >
                                    <Upload.Dragger
                                        customRequest={dummyRequest}
                                        {...uploadFileDragger}
                                        // name="csv_file"
                                        // className={`upload_area_2 m-auto mb-20 position-relative uploadWidth full-width`}
                                        className="upload-image-review ecommerce-dragger sc-import-product"
                                    >
                                        {/* <FontAwesomeIcon icon={faCloudArrowUp} className="mt-0" /> */}
                                        <p>Drag & Drop Your File Here</p>
                                    </Upload.Dragger>
                                </Form.Item>
                                {/* {progress > 0 && <Progress percent={progress} />} */}
                            </div>
                            <div className="d-flex justify-content-between align-items-center">
                                <a href="https://d1hq75pouwojqi.cloudfront.net/product_template.csv" rel="noopener noreferrer" target="_blank"
                                    className="default-btn small-btn outline d-inline-flex">
                                    Download Sample CSV
                                </a>
                                <div>
                                    <Button
                                        // disabled={getProduct && getProduct?.loading}
                                        // to="#"
                                        className="default-btn d-inline-flex align-items-center justify-content-center"
                                        // onClick={submit}
                                        disabled={CSVFile === undefined}
                                        onClick={handleUpload}
                                    >
                                        <span className="nav-text">Upload</span>
                                    </Button>
                                    <Button
                                        className="default-btn outline d-inline-flex align-items-center justify-content-center"
                                        key="back"
                                        onClick={handleCancel}
                                    >
                                        Exit
                                    </Button>
                                </div>
                            </div>
                        </div>
                    </Form>
                ) : (
                    <span className="spinclass loader-center position-relative">
                        <Spin size="large" />
                    </span>
                )}
            </ModalA>


            <Modal
                dialogClassName="code-activated-popup"
                size="lg"
                scrollable
                show={modal}
                onHide={() => setModal(false)}
                centered
                backdrop="static"
            >
                <Modal.Header className="modal-header-style2" closeButton>
                    <Modal.Title className="mb-0">Order Detail</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {load ? (
                        <span className="mt-2 mr-2 loader-center position-relative">
                            <Spin size="large" />
                        </span>
                        // <Spin
                        //   style={{
                        //     display: "flex",
                        //     justifyContent: "center",
                        //   }}
                        // />
                    ) : (
                        <>
                            <div className="order-detail-title-area">
                                <div className="d-flex flex-column">
                                    <div className="order-num total-orders-batches">
                                        Order
                                        <div className="order-name">
                                            {
                                                scGetOneOrderDetail?.payload?.order
                                                    ?.name
                                            }
                                        </div>

                                        {
                                            scGetOneOrderDetail?.payload?.order
                                                ?.cancel_reason &&
                                            <div className="connection-status-badge-red firstLetterCapital">
                                                Cancelled
                                            </div>
                                        }

                                        <div className="connection-status-badge-grey firstLetterCapital">
                                            {
                                                scGetOneOrderDetail?.payload?.order
                                                    ?.financial_status
                                            }
                                        </div>
                                        {
                                            scGetOneOrderDetail?.payload?.order
                                                ?.fulfillment_status ?
                                                // <div className="connection-status-badge-grey firstLetterCapital">
                                                //   {
                                                scGetOneOrderDetail?.payload?.order
                                                    ?.fulfillment_status === "partial" ?
                                                    <div className="connection-status-badge-orange firstLetterCapital">
                                                        Partially Fulfilled
                                                    </div>
                                                    :
                                                    <div className="connection-status-badge-green firstLetterCapital">
                                                        {scGetOneOrderDetail?.payload?.order
                                                            ?.fulfillment_status}
                                                    </div>
                                                //   }
                                                // </div>
                                                :
                                                <div className="connection-status-badge-yellow firstLetterCapital">
                                                    Unfulfilled
                                                </div>
                                        }
                                        {
                                            !detailsOrder?.loading && detailsOrder?.payload?.message?.order_return_detail?.length > 0
                                            &&
                                            <div className="connection-status-badge-grey firstLetterCapital">
                                                Returned
                                            </div>
                                        }
                                    </div>
                                    <duv className="order-num order-name-main">
                                        Customer Name:
                                        <div className="order-name">
                                            {scGetOneOrderDetail?.payload?.order?.customer
                                                ?.first_name +
                                                " " +
                                                scGetOneOrderDetail?.payload?.order?.customer
                                                    ?.last_name}
                                        </div>
                                    </duv>
                                </div>

                                <span className="order-date">
                                    {moment
                                        .utc(
                                            scGetOneOrderDetail?.payload?.order?.created_at
                                        )
                                        .format("MMMM Do YYYY [from Online Store]")}
                                </span>
                            </div>

                            <BootstrapTable responsive size="sm" className="transactions-box table-responsive scrollbar-style">
                                <thead className="table_heading">
                                    <tr>
                                        <th className="td-width-60">S.#</th>
                                        <th className="td-width-120">Order Date</th>
                                        <th className="td-width-100">SKU #</th>
                                        <th className="td-width-200">Description</th>
                                        <th className="td-width-80">Qty</th>
                                        <th className="td-width-100 text-right">Price</th>
                                        <th className="td-width-100 text-right">Amount</th>
                                        <th className="td-width-100 text-right">Status</th>
                                    </tr>
                                </thead>

                                <tbody>
                                    {!scGetOneOrderDetail?.loading &&
                                        scGetOneOrderDetail?.payload?.order?.line_items?.map(
                                            (item, key) => {
                                                return (
                                                    <tr key={key}>
                                                        <td>{key + 1}</td>
                                                        <td>
                                                            {moment
                                                                .utc(order?.created_at)
                                                                .format("MM-DD-YYYY")}
                                                        </td>
                                                        <td>{item?.sku}</td>
                                                        <td>{item?.title}</td>
                                                        <td>{item?.quantity}</td>
                                                        <td className="text-right">{numeral(item?.price).format("$0,0.0'")}</td>
                                                        <td className="text-right">
                                                            {numeral(
                                                                getSum(item?.quantity, item?.price)
                                                            ).format("$0,0.0'")}
                                                        </td>
                                                        <td className="text-right firstLetterCapital">
                                                            {item?.fulfillment_status ? item?.fulfillment_status : "UnFulfilled"}
                                                        </td>
                                                    </tr>
                                                );
                                            }
                                        )}
                                </tbody>
                            </BootstrapTable>
                            <div className="order-sum">
                                <div className="paid-mid-row">
                                    <div className="left-txt multi-content">
                                        <div className="left-hd">Subtotal</div>
                                        <div className="left-info">
                                            {
                                                scGetOneOrderDetail?.payload?.order?.line_items
                                                    ?.length
                                            }{" "}
                                            item
                                        </div>
                                    </div>
                                    <div className="right-txt">
                                        {numeral(
                                            scGetOneOrderDetail?.payload?.order
                                                ?.total_line_items_price
                                        ).format("$0,0.0'")}
                                    </div>
                                </div>

                                <div className="paid-mid-row">
                                    <div className="left-txt multi-content">
                                        <div className="left-hd">Discount</div>
                                        {scGetOneOrderDetail?.payload?.order
                                            ?.total_discounts
                                            ? numeral(
                                                scGetOneOrderDetail?.payload?.order
                                                    ?.total_discounts /
                                                scGetOneOrderDetail?.payload?.order
                                                    ?.total_line_items_price
                                            ).format("0%")
                                            : ""}
                                    </div>
                                    {scGetOneOrderDetail?.payload?.order
                                        ?.total_discounts ? (
                                        <>
                                            <div className="right-txt">
                                                -
                                                {numeral(
                                                    scGetOneOrderDetail?.payload?.order
                                                        ?.total_discounts
                                                ).format("$0,0.0'")}
                                            </div>
                                        </>
                                    ) : (
                                        <div className="right-txt">
                                            {numeral(0).format("$0,0.0'")}
                                        </div>
                                    )}
                                </div>

                                <div className="paid-mid-row">
                                    <div className="left-txt multi-content">
                                        <div className="left-hd">Shipping</div>
                                    </div>
                                    <div className="right-txt">
                                        {numeral(
                                            scGetOneOrderDetail?.payload?.order
                                                ?.total_shipping_price_set?.presentment_money?.amount
                                        ).format("$0,0.0'")}
                                    </div>
                                </div>

                                <div className="paid-mid-row">
                                    <div className="left-txt multi-content">
                                        <div className="left-hd">Tax</div>
                                    </div>
                                    <div className="right-txt">
                                        {numeral(
                                            scGetOneOrderDetail?.payload?.order?.total_tax
                                        ).format("$0,0.0'")}
                                    </div>
                                </div>
                                <div className="paid-mid-row">
                                    <div className="left-txt multi-content">
                                        <div className="left-hd fw-bold">Total Amount</div>
                                    </div>
                                    <div className="right-txt fw-bold">
                                        {numeral(
                                            scGetOneOrderDetail?.payload?.order?.current_total_price
                                        ).format("$0,0.0'")}
                                    </div>
                                </div>
                            </div>


                            {/* fulfillment Table */}
                            {!scGetOneOrderDetail?.loading &&
                                scGetOneOrderDetail?.payload?.order
                                    ?.fulfillment_status && (
                                    <div style={{ marginTop: "10px" }}>
                                        <div className="order-detail-title-area">
                                            <div className="d-flex flex-column">
                                                <div className="order-num">Fulfillment Detail</div>
                                            </div>
                                        </div>
                                        <Table responsive size="sm" className="transactions-box">
                                            <thead className="table_heading">
                                                <tr>
                                                    <th>SKU #</th>
                                                    <th>Tracking Company</th>
                                                    <th>Tracking Number</th>
                                                    {/* <th>SKU #</th>
                          <th>Description</th>
                          <th>Qty</th>
                          <th>Price</th>
                          <th>Amount</th> */}
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {scGetOneOrderDetail?.payload?.order?.fulfillments?.length &&
                                                    scGetOneOrderDetail?.payload?.order?.fulfillments?.map(
                                                        (item, key) => {
                                                            return (
                                                                <tr key={key}>
                                                                    <td>{item?.line_items?.[0]?.sku ? item?.line_items?.[0]?.sku : "-"}</td>
                                                                    <td>{item?.tracking_company ? item?.tracking_company : "-"}</td>
                                                                    <td>{item?.tracking_number ? item?.tracking_url ?
                                                                        <a
                                                                            className="open-modal blue-link"
                                                                            target="_blank"
                                                                            href={item?.tracking_url}
                                                                        >
                                                                            {item?.tracking_number}
                                                                        </a>
                                                                        :
                                                                        <span>
                                                                            {item?.tracking_number ? item?.tracking_number : "-"}
                                                                        </span>
                                                                        : "-"}</td>
                                                                </tr>
                                                            );
                                                        }
                                                    )}
                                            </tbody>
                                        </Table>
                                        {/* {console.log(detailsOrder?.payload?.message?.order_return_detail?.refund_line_items, 'refunds')} */}
                                        {/* <div className="order-sum">
                      <>
                        <div className="paid-mid-row">
                          <div className="left-txt multi-content">
                            <div className="left-hd">Subtotal</div>
                            <div className="left-info">
                              {detailsOrder?.payload?.message?.order_return_detail
                                ?.refund_line_items?.length
                                ? detailsOrder?.payload?.message
                                  ?.order_return_detail?.refund_line_items?.length
                                : 0}{" "}
                              item
                            </div>
                          </div>
                          <div className="right-txt">
                            {numeral(
                              detailsOrder?.payload?.message?.order_return_detail?.refund_line_items?.reduce(
                                (acc, item) => {
                                  return (acc = acc + item.subtotal);
                                },
                                0
                              )
                            ).format("$0,0.0'")}
                          </div>
                        </div>
                        <div className="paid-mid-row">
                          <div className="left-txt multi-content">
                            <div className="left-hd">Tax</div>
                          </div>
                          <div className="right-txt">
                            {numeral(
                              detailsOrder?.payload?.message?.order_return_detail?.refund_line_items?.reduce(
                                (acc, item) => {
                                  return (acc = acc + item.total_tax);
                                },
                                0
                              )
                            ).format("$0,0.0'")}
                          </div>
                        </div>
                        <div className="paid-mid-row">
                          <div className="left-txt multi-content">
                            <div className="left-hd fw-bold">Total Amount</div>
                          </div>
                          <div className="right-txt fw-bold">
                            {numeral(
                              detailsOrder?.payload?.message?.order_return_detail?.refund_line_items?.reduce(
                                (acc, item) => {
                                  return (acc = acc + item.subtotal);
                                },
                                0
                              ) +
                              detailsOrder?.payload?.message?.order_return_detail?.refund_line_items?.reduce(
                                (acc, item) => {
                                  return (acc = acc + item.total_tax);
                                },
                                0
                              )
                            ).format("$0,0.0'")}
                          </div>
                        </div>
                      </>
                    </div> */}
                                    </div>
                                )}
                            {/* fulfillment Table */}


                            {!scGetOneOrderDetail?.loading &&
                                scGetOneOrderDetail?.payload?.order?.order_return_detail?.length > 0 && (
                                    <div style={{ marginTop: "20px" }}>
                                        <div className="order-detail-title-area">
                                            <div className="d-flex flex-column">
                                                <div className="order-num">Returned Detail</div>
                                            </div>
                                        </div>
                                        <Table responsive size="sm" className="transactions-box">
                                            <thead className="table_heading">
                                                <tr>
                                                    <th>S#</th>
                                                    <th>Order Date</th>
                                                    <th>SKU #</th>
                                                    <th>Description</th>
                                                    <th>Qty</th>
                                                    <th>Price</th>
                                                    <th>Amount</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {!detailsOrder?.loading &&
                                                    detailsOrder?.payload?.message?.order_return_detail?.length && detailsOrder?.payload?.message?.order_return_detail?.map(
                                                        (item, key) => {
                                                            return item?.refund_line_items?.map((item2, key2) => {
                                                                return (
                                                                    <tr key={key2}>
                                                                        <td>{key2 + 1}</td>
                                                                        <td>{moment.utc(order?.created_at).format("MM-DD-YYYY")}</td>
                                                                        <td>{item2?.line_item.sku}</td>
                                                                        <td>{item2?.line_item?.title}</td>
                                                                        <td>{item2?.quantity}</td>
                                                                        <td>{numeral(item2?.line_item?.price).format("$0,0.0'")}</td>
                                                                        <td>{numeral(item2?.subtotal).format("$0,0.0'")}</td>
                                                                    </tr>
                                                                );
                                                            })


                                                        }
                                                    )}
                                            </tbody>
                                        </Table>
                                        {/* {console.log(detailsOrder?.payload?.message?.order_return_detail?.refund_line_items, 'refunds')} */}
                                        <div className="order-sum">
                                            <>
                                                <div className="paid-mid-row">
                                                    <div className="left-txt multi-content">
                                                        <div className="left-hd">Subtotal</div>
                                                        <div className="left-info">
                                                            {detailsOrder?.payload?.message?.order_return_detail
                                                                ?.length
                                                                ? detailsOrder?.payload?.message
                                                                    ?.order_return_detail?.length
                                                                : 0}{" "}
                                                            item
                                                        </div>
                                                    </div>
                                                    <div className="right-txt">
                                                        {numeral(
                                                            // detailsOrder?.payload?.message?.order_return_detail?.reduce(
                                                            //   (acc, item) => {
                                                            //     return (acc = acc + item?.refund_line_items[0].subtotal);
                                                            //   },
                                                            //   0
                                                            // )
                                                            detailsOrder?.payload?.message?.order_return_detail.reduce((total, order) => {
                                                                return total + (order.refund_line_items || []).reduce((acc, item) => {
                                                                    return acc + item.subtotal;
                                                                }, 0);
                                                            }, 0)
                                                        ).format("$0,0.0'")}
                                                    </div>
                                                </div>
                                                {
                                                    scGetOneOrderDetail?.payload?.order
                                                        ?.cancel_reason && !scGetOneOrderDetail?.payload?.order
                                                            ?.fulfillment_status &&

                                                    // detailsOrder?.payload?.message?.order_return_detail[0]?.refund_line_items?.length > 0
                                                    // && detailsOrder?.payload?.message?.order_return_detail[0]?.order_adjustments?.length > 0 &&
                                                    < div className="paid-mid-row">
                                                        <div className="left-txt multi-content">
                                                            <div className="left-hd">Shipping</div>
                                                        </div>
                                                        <div className="right-txt">
                                                            {/* {numeral(
                                detailsOrder?.payload?.message?.order_return_detail[0]?.order_adjustments[0]?.amount
                              ).format("$0,0.0'")} */}
                                                            {
                                                                numeral(detailsOrder?.payload?.message?.order_return_detail.reduce((total, order) => {
                                                                    return total + (order.order_adjustments || []).reduce((acc, adjustment) => {
                                                                        if (adjustment.kind === "shipping_refund") {
                                                                            acc += Math.abs(adjustment.amount);
                                                                        }
                                                                        return acc;
                                                                    }, 0);
                                                                }, 0)).format("$0,0.0'")
                                                            }
                                                        </div>
                                                    </div>}
                                                <div className="paid-mid-row">
                                                    <div className="left-txt multi-content">
                                                        <div className="left-hd">Tax</div>
                                                    </div>
                                                    <div className="right-txt">
                                                        {numeral(
                                                            // detailsOrder?.payload?.message?.order_return_detail?.reduce(
                                                            //   (acc, item) => {
                                                            //     return (acc = acc + item?.refund_line_items[0].total_tax);
                                                            //   },
                                                            //   0
                                                            // )
                                                            detailsOrder?.payload?.message?.order_return_detail.reduce((total, order) => {
                                                                return total + (order.refund_line_items || []).reduce((acc, item) => {
                                                                    return acc + item.total_tax;
                                                                }, 0);
                                                            }, 0)
                                                        ).format("$0,0.0'")}
                                                    </div>
                                                </div>
                                                <div className="paid-mid-row">
                                                    <div className="left-txt multi-content">
                                                        <div className="left-hd fw-bold">Total Amount</div>
                                                    </div>
                                                    <div className="right-txt fw-bold">
                                                        {numeral(
                                                            // detailsOrder?.payload?.message?.order_return_detail?.reduce(
                                                            //   (acc, item) => {
                                                            //     return (acc = acc + item?.refund_line_items[0].subtotal);
                                                            //   },
                                                            //   0
                                                            // )
                                                            detailsOrder?.payload?.message?.order_return_detail.reduce((total, order) => {
                                                                return total + (order.refund_line_items || []).reduce((acc, item) => {
                                                                    return acc + item.subtotal;
                                                                }, 0);
                                                            }, 0)
                                                            +
                                                            // detailsOrder?.payload?.message?.order_return_detail?.reduce(
                                                            //   (acc, item) => {
                                                            //     return (acc = acc + item?.refund_line_items[0].total_tax);
                                                            //   },
                                                            //   0
                                                            // )
                                                            detailsOrder?.payload?.message?.order_return_detail.reduce((total, order) => {
                                                                return total + (order.refund_line_items || []).reduce((acc, item) => {
                                                                    return acc + item.total_tax;
                                                                }, 0);
                                                            }, 0)
                                                            +
                                                            ((scGetOneOrderDetail?.payload?.order
                                                                ?.cancel_reason && !scGetOneOrderDetail?.payload?.order
                                                                    ?.fulfillment_status) ?
                                                                (detailsOrder?.payload?.message?.order_return_detail.reduce((total, order) => {
                                                                    return total + (order.order_adjustments || []).reduce((acc, adjustment) => {
                                                                        if (adjustment.kind === "shipping_refund") {
                                                                            acc += Math.abs(adjustment.amount);
                                                                        }
                                                                        return acc;
                                                                    }, 0);
                                                                }, 0))
                                                                : 0)
                                                        ).format("$0,0.0'")}
                                                    </div>
                                                </div>
                                            </>
                                        </div>
                                    </div>
                                )}
                        </>
                    )}
                </Modal.Body>

                <Modal.Footer>
                    <Button
                        onClick={() => setModal(false)}
                        className="default-btn outline padb-3"
                    >
                        <i className="fa fa-arrow-left" aria-hidden="true"></i> &nbsp; Go
                        Back
                    </Button>
                </Modal.Footer>
            </Modal >
        </>
    );
};
