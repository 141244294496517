import React, { useEffect, useMemo, useRef, useState } from "react";
import { CalendarFilled, ExclamationCircleOutlined, EyeTwoTone, EyeFilled, MoreOutlined, EllipsisOutlined } from "@ant-design/icons";
import {
  faAngleLeft,
  faCaretRight,
  faUser,
  faCloudArrowUp,
  faUserTie,
  faXmark,
  faEye,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import numeral from "numeral";
import {
  Affix,
  Button,
  Checkbox,
  Col,
  Row,
  DatePicker,
  Form,
  Image,
  Input,
  InputNumber,
  message,
  Modal,
  notification,
  Select,
  Space,
  Spin,
  Steps,
  Switch,
  Table,
  Tabs,
  Upload,
  Tooltip,
  Tag,
  Dropdown,
  Menu,
  Alert,
  Radio,
  Card,
  Typography
} from "antd";
import moment from "moment";
import { ErrorBoundary } from "react-error-boundary";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate, useParams, useLocation } from "react-router-dom";
import ReactRouterPrompt from "react-router-prompt";
import { useStepsForm } from "sunflower-antd";
import step1 from "../../images/step1.svg";
import step2 from "../../images/step2.svg";
import step3 from "../../images/step3.svg";
import previewImg from "../../images/upload-banner.png";
import { MinusCircleOutlined, PlusOutlined, LoadingOutlined, DownOutlined } from '@ant-design/icons';
import {
  generateVideoThumbnails,
  generateVideoThumbnailViaUrl,
  importFileandPreview,
} from "@rajesh896/video-thumbnails-generator";
import {
  getEventsBy_Id,
  getShowBy_Id,
  postEvent,
  UpdateEvents,
  postShow,
  UpdateShows,
} from "../../redux/actions/event.action";
import { getPromoRequest } from "../../redux/actions/promoRequest";
import SkeletonComponent from "../../components/SkeletenLoader";
import AsyncProduct from "../create-event/scheduleEvent/asyncProduct";
import ReactPlayer from "react-player";
// import { getParentCategories } from "../../redux/actions/category.action";
import {
  getSavedGeneralCategories,
  getSavedCategories,
  getSavedSubCategories,
} from "../../redux/actions/newCategory.action";
import S3FileUpload from "react-s3";
import { UploadOutlined } from "@ant-design/icons";
import { REACT_S3, UPLOAD_DONE, UPLOADS3 } from "../../redux/types/types";
import AWS from "aws-sdk";
import { Percent, SetPercent } from "../../context/PercentProvider";
import { useContext } from "react";
import { getFeeStructure } from "../../redux/actions/feestructure.action";
import { searchBySkuAndTitle } from "../../redux/actions/inventoryActive.action";
import ReactQuill from 'react-quill';
import SunEditor from 'suneditor-react';
import 'suneditor/dist/css/suneditor.min.css'; // Import SunEditor styles
import 'react-quill/dist/quill.snow.css';
import { SortableContainer, SortableElement } from 'react-sortable-hoc';
import { DeleteOutlined } from "@mui/icons-material";
import { scAddProduct } from "../../redux/actions/scAddProduct";
import { DndContext, PointerSensor, useSensor } from '@dnd-kit/core';
import {
  arrayMove,
  SortableContext,
  useSortable,
  verticalListSortingStrategy,
  rectSortingStrategy
} from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { scGetOneProduct } from "../../redux/actions/scGetOneProduct";
import { InfoCircleOutlined, QuestionCircleOutlined } from '@ant-design/icons';
import { scUpdateProduct } from "../../redux/actions/scUpdateProduct";
import { scGetOneOrder } from "../../redux/actions/scGetOneOrder";
import "../../css/sales.scss";
import { scUpdateFulfillment } from "../../redux/actions/scUpdateFulfillment";
import { scCancelOrder } from "../../redux/actions/scCancelOrder";
import { scCancelFulfillment } from "../../redux/actions/scCancelFulfillment";
import { scPartialFulfillment } from "../../redux/actions/scPartialFulfillment";
import { scFulfillmentTracking } from "../../redux/actions/scFulfillmentTracking";


const { Text } = Typography;
const DraggableUploadListItem = ({ originNode, file }) => {
  const { attributes, listeners, setNodeRef, transform, transition, isDragging } = useSortable({
    id: file.uid,
  });
  const style = {
    transform: CSS.Translate.toString(transform),
    transition,
    cursor: 'move',
  };
  return (
    <div
      ref={setNodeRef}
      style={style}
      // prevent preview event when drag end
      className={isDragging ? 'is-dragging' : ''}
      {...attributes}
      {...listeners}
    >
      {/* hide error tooltip when dragging */}
      {file.status === 'error' && isDragging ? originNode.props.children : originNode}
    </div>
  );
};

window.Buffer = window.Buffer || require("buffer").Buffer;

const { Step } = Steps;
const { Option } = Select;

AWS.config.update({
  region: process.env.REACT_APP_UPLOAD_SC_IMAGES_REGION,
  accessKeyId: process.env.REACT_APP_UPLOAD_SC_IMAGES_ACCESS_KEY_ID,
  secretAccessKey: process.env.REACT_APP_UPLOAD_SC_IMAGES_SECRET_ACCESS_KEY,
});

const SortableImage = SortableElement(({ item, onRemove, onPreview }) => (
  <div style={{ position: 'relative', margin: '10px', display: 'inline-block' }}>
    <div style={{ position: 'absolute', top: 0, right: 0, zIndex: 1 }}>
      <Button
        type="link"
        style={{
          "background": "white",
          "borderRadius": "50%",
          "textAlign": "center",
          "display": "flex",
          "justifyContent": "center",
          "alignItems": "center",
          "margin": "1px",
          "border": "1px solid red"
        }}
        danger
        icon={<DeleteOutlined />}
        onClick={() => {
          onRemove(item);
        }}
      />
    </div>
    <div style={{ cursor: 'pointer' }} onClick={() => onPreview(item)}>
      <Image width={100} src={item.thumbUrl || item.url} />
    </div>
  </div>
));

const SortableList = SortableContainer(({ items, onRemove, onPreview }) => {
  return (
    <div>
      {items.map((item, index) => (
        <SortableImage
          key={item.uid}
          index={index}
          item={item}
          onRemove={onRemove}
          onPreview={onPreview}
        />
      ))}
    </div>
  );
});

export default function SCOrderDetail() {
  const sensor = useSensor(PointerSensor, {
    activationConstraint: {
      distance: 10,
    },
  });
  const onDragEnd = ({ active, over }) => {
    if (active.id !== over?.id) {
      setFileUrl((prev) => {
        const activeIndex = prev.findIndex((i) => i.uid === active.id);
        const overIndex = prev.findIndex((i) => i.uid === over?.id);
        return arrayMove(prev, activeIndex, overIndex);
      });
      setFileList((prev) => {
        const activeIndex = prev.findIndex((i) => i.uid === active.id);
        const overIndex = prev.findIndex((i) => i.uid === over?.id);
        return arrayMove(prev, activeIndex, overIndex);
      });

    }
  };

  const editorOptions = {
    buttonList: [
      ['undo', 'redo'],
      ['font'],
      ['formatBlock'],
      ['bold', 'underline', 'italic', 'strike'],
      ['align', 'list'],
      ['link',],
      ['removeFormat'],
      ['codeView'], // HTML edit button

    ],
    font: [
      'Nunito Sans', 'sans-serif', 'Arial', 'Comic Sans MS', 'Courier New', 'Georgia', 'Tahoma', 'Trebuchet MS', 'Verdana'
    ],
    height: 100,
  };
  const handleDescriptionChange = (value) => {
    setDescription(value);
    scform.setFieldsValue({
      description: value,
    });
  };


  const { confirm } = Modal;
  const s3 = new AWS.S3();
  const { TextArea } = Input;
  const dispatch = useDispatch();
  const history = useNavigate();

  const {
    parentCategory,
    promoRequest,
    validate,
    savedGeneralCategories,
    getStructureFees,
    getInventoryProductsBySkuAndTitle,
    scGetOneProductDetail,
    scGetOneOrderDetail
  } = useSelector((state) => {
    return state;
  });

  const params = useParams();
  const id = params.id;
  let query = new URL(window.location.href);
  let searchParams = new URLSearchParams(query.search);
  const [scform] = Form.useForm();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isRefundModalOpen, setIsRefundModalOpen] = useState(false);
  const [isReturnModalOpen, setIsReturnModalOpen] = useState(false);
  const [isCancelModalOpen, setIsCancelModalOpen] = useState(false);
  const setPercent = useContext(SetPercent);
  const [streamModal, setIsModalOpenStream] = useState(false);
  const [isModalOpenDetail, setIsModalOpenDetail] = useState(false);
  const [isCheckedOpen, setIsCheckedOpen] = useState(false);
  const [dataDetails, setDataDetail] = useState([]);
  const [referralPercent, setReferralPercent] = useState("0");
  const [discount, setDiscount] = useState("");
  const [influencer_percent, setInfluencer_percent] = useState("0");
  const [promo, setPromo] = useState("");
  const [loading, setLoading] = useState(false);
  const [load, setLoad] = useState(false);
  const [flag, setFlag] = useState(false);
  const [defaultBanner, setDefaultBanner] = useState(false);
  const [videoRatio, setRatio] = useState("16:9");
  const [fileList, setFileList] = useState([]);
  const [contentType, setContentType] = useState("");
  const [dateTime, setDate] = useState("");
  const [formState, setFormState] = useState("add");
  const [published, setPublished] = useState(false);
  const [sku, setSku] = useState([]);
  const [host, setHost] = useState([]);
  const [host2, setHost2] = useState([]);
  const [host3, setHost3] = useState([]);
  const [host4, setHost4] = useState([]);
  const [skuError, setSkuError] = useState(false);
  const [parent, setParent] = useState("");
  const [promptModal, setPromptModal] = useState(true);
  const [vidMsg, setVidMsg] = useState(false);
  const [multipleHosts, setMultipleHosts] = useState(false);
  const [imageUpload, setImageUpload] = useState(false);
  const [sampleBanner, setSampleBanner] = useState(false);
  const [banner, setBanner] = useState("");
  const [recURL, setRecURL] = useState("");
  const [previewImage, setPreviewImage] = useState("");
  const [eventTitle, setEventTitle] = useState("");
  const [note, setEventNote] = useState("");
  const [thankyou, setEventThankyou] = useState("");
  const [infoTitle, setInfoTitle] = useState("");
  const [infoDesc, setInfoDesc] = useState("");
  const [platform, setPlatform] = useState(false);
  const [streamUrl, setStreamUrl] = useState("local");
  const [externalUrl, setExternalUrl] = useState("");
  const [productPromoCodeDscs, setProductPromoCodeDscs] = useState("0%");
  const [productPromoCodePromo, setproductPromoCodePromo] = useState("KB0");
  const [orientation, setOrientation] = useState("");
  const [flagg, setFlagg] = useState(false);
  const [saveLoading, setSaveLoading] = useState(false);
  const [fields, setFields] = useState({
    image: "",
  });
  const [imageThumb, setImageThumb] = useState("");
  const [ext, setExt] = useState({});

  const [gender, setGender] = useState("");
  const [category, setCategory] = useState("");
  const [subCategory, setSubCategory] = useState("");
  const [categoryOptions, setCategoryOptions] = useState([]);
  const [subCategoryOptions, setSubCategoryOptions] = useState([]);
  const [genLoad, setGenLoad] = useState(false);
  const [catLoad, setCatLoad] = useState(false);
  const [catSubLoad, setSubCatLoad] = useState(false);
  const [bannerExists, setBannerExists] = useState(false);

  const selectRef = useRef();

  const [video, setVideo] = useState();
  const [thumbNumber, setThumbNumber] = useState(0);
  const [picCount, setPicCount] = useState(0);
  const [videoUrl, setVideoUrl] = useState("");
  const [videoThumb, setVideoThumb] = useState("");
  const [thumbnails, setThumbnails] = useState([]);
  const [thumbnailLoader, setThumbnailLoader] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const [msg, setMsg] = useState(false);
  const [previewOpen, setPreviewOpen] = useState(false);
  const [previewVisible, setPreviewVisible] = useState(false);
  const [previewTitle, setPreviewTitle] = useState('');
  const [tags, setTags] = useState([]);
  const [fileUrl, setFileUrl] = useState([]);
  const [description, setDescription] = useState('');
  const [weightUnit, setWeightUnit] = useState('lb');
  const [formValues, setFormValues] = useState([]);
  const [variantValues, setVariantValues] = useState([]);
  // const [transformData, setTransformData] = useState([]);
  const [allValues, setAllValues] = useState([]);
  const [previousVariants, setPreviousVariants] = useState([]);
  const [orderData, setOrderData] = useState([]);
  const [comparedPrice, setComparedPrice] = useState(0);
  const [selectedRecord, setSelectedRecord] = useState(null);
  const [imgLoading, setImgLoading] = useState(false);
  const [variantValueChange, setVariantValueChange] = useState(false);
  const [time, setTime] = useState(true);
  const [affixed, setAffixed] = useState(false);
  const [trackingNo, setTrackingNo] = useState();
  const [shippingCareer, setShippingCareer] = useState();
  const [refundAmount, setRefundAmount] = useState();
  const [isReStock, setIsReStock] = useState(true);
  const [refundReason, setRefundReason] = useState();
  const [staffNote, setStaffNote] = useState();
  const [trackingUrl, setTrackingUrl] = useState();
  const [url, setUrl] = useState('');
  const [isValid, setIsValid] = useState(null);
  const [cancelReason, setCancelReason] = useState('customer');
  const [refundOption, setRefundOption] = useState('now');
  const [isTrackingModalOpen, setIsTrackingModalOpen] = useState(false);
  const [selectedTrackingData, setSelectedTrackingData] = useState(null);

  const validateURL = (urlString) => {
    const urlRegex = /^(https?:\/\/)?([a-zA-Z0-9-]+\.)+[a-zA-Z]{2,}(\/.*)?$/;
    return urlRegex.test(urlString);
  };

  const handleOptionChange = (e) => {
    setRefundOption(e.target.value);
  };
  // Handle URL input change
  const handleUrlChange = (event) => {
    const newUrl = event.target.value;
    setUrl(newUrl);
    setTrackingUrl(newUrl)
    setIsValid(validateURL(newUrl));
  };

  // const [previewImage, setPreviewImage] = useState('');
  let sc_token = JSON.parse(localStorage.getItem("sc_token"))
  const refs = useRef({
    video: null,
    loader: null,
    numberInput: null,
    thumbButton: null,
  });

  useEffect(() => {
    // Check if all files are done uploading
    const allDone = fileList.every((f) => f.status === 'done' && f.url);
    setImgLoading(!allDone);
  }, [fileList]);
  useEffect(() => {
    const checkIfTop = () => {
      // Set affixed to false if the user has scrolled to the top of the page
      if (window.pageYOffset === 0) {
        setAffixed(false);
      }
    };

    window.addEventListener('scroll', checkIfTop);

    return () => {
      window.removeEventListener('scroll', checkIfTop);
    };
  }, []);

  const mergeVariants = (variantValues, allVariants) => {
    return variantValues.map(variant => {
      const match = allVariants.find(av =>
        av.option1 === variant.option1 &&
        av.option2 === (variant.option2 || null) &&
        av.option3 === (variant.option3 || null)
      );

      if (match) {
        // If titles match, return the merged result
        if (match.title === variant.title) {
          return {
            ...match,
            ...variant,
            title: variant.title, // Ensure the original title is kept
            price: variant.price, // Ensure the original title is kept
            inventory_quantity: variant.inventory_quantity, // Ensure the original title is kept
          };
        } else {
          // If titles do not match, return only the options fields
          return {
            sku1: variant.sku, // Ensure the original title is kept
            option1: variant.option1,
            option2: variant.option2,
            option3: variant.option3
          };
        }
      } else {
        // If no match, return the original variant
        return variant;
      }
    });
  };

  useEffect(() => {

    if (id) {
      setTime(false)
      setLoad(true);
      dispatch(scGetOneOrder(id, sc_token)).then(
        (response) => {
          // console.log(response, 'response');

          if (response.order) {
            setOrderData(response?.order)
            setFormState("edit");
            setLoad(false);
            setRefundAmount(response?.order?.total_price)
          }
          else {
            setLoad(false);
          }
        }
      );

    } else {
      setLoad(false);
    }
  }, [id])
  const showModal = (record) => {
    setSelectedRecord(record);
    setIsModalOpen(true);
  };
  const showTrackingModal = (record) => {
    setSelectedTrackingData(record);
    setIsTrackingModalOpen(true);
  };

  const handleCancelOrder = () => {
    setSaveLoading(true)
    let data = {
      reason: cancelReason,
      type: 'cancelled',
      orderId: id
    }

    dispatch(scCancelOrder(id, data, sc_token)).then((res) => {

      if (res?.success) {
        setSaveLoading(false)
        notification.success({
          message: res?.message,
          className: "toast-success",
        })
        setLoad(true);
        dispatch(scGetOneOrder(id, sc_token)).then(
          (response) => {
            // console.log(response, 'response');

            if (response.order) {
              setOrderData(response?.order)
              setFormState("edit");
              setLoad(false);

            }
          }
        );
        setTrackingNo()
        setShippingCareer()
        setTrackingUrl()
        setIsModalOpen(false);
        setIsCancelModalOpen(false)
        setSelectedRecord(null)
      }
      else {
        setSaveLoading(false)
        notification.error({
          message: res?.response?.data?.message,
          className: "toast-error",
        });
        setIsModalOpen(false);
        setSelectedRecord(null)
      }
    })



  };
  const handleOk = () => {
    // decide which api to run
    const allFulfill = orderData?.fulfillment_status !== 'partial' && selectedRecord.every(item => item.fulfillable_quantity === item.quantity);

    if (allFulfill) {
      console.log('full');
      setSaveLoading(true)
      let data = {
        tracking_number: trackingNo,
        tracking_company: shippingCareer,
        tracking_url: trackingUrl
      }
      dispatch(scUpdateFulfillment(id, data, sc_token)).then((res) => {

        if (res?.success) {
          setSaveLoading(false)
          notification.success({
            message: res?.message,
            className: "toast-success",
          })
          setLoad(true);
          dispatch(scGetOneOrder(id, sc_token)).then(
            (response) => {
              // console.log(response, 'response');

              if (response.order) {
                setOrderData(response?.order)
                setFormState("edit");
                setLoad(false);

              }
            }
          );
          setTrackingNo()
          setShippingCareer()
          setTrackingUrl()
          setIsModalOpen(false);
          setSelectedRecord(null)
        }
        else {
          setSaveLoading(false)
          notification.error({
            message: res?.response?.data?.message,
            className: "toast-error",
          });
          setIsModalOpen(false);
          setSelectedRecord(null)
        }
      })
    }
    else {
      console.log('partial');
      const filteredLines = selectedRecord.filter(item => item.quantity > 0 && item.fulfillment_status !== 'fulfilled');
      let data = {
        orderId: id,
        line_item: filteredLines.map((item) => ({
          current_quantity: item?.quantity,
          fulfillable_quantity: item?.fulfillable_quantity,
          id: item?.id

        })),
        tracking_number: trackingNo,
        tracking_company: shippingCareer,
        tracking_url: trackingUrl
      }

      console.log(data, 'fulfillment data ');

      dispatch(scPartialFulfillment(id, data, sc_token)).then((res) => {

        if (res?.success) {
          setSaveLoading(false)
          notification.success({
            message: res?.message,
            className: "toast-success",
          })
          setLoad(true);
          dispatch(scGetOneOrder(id, sc_token)).then(
            (response) => {
              // console.log(response, 'response');

              if (response.order) {
                setOrderData(response?.order)
                setFormState("edit");
                setLoad(false);

              }
            }
          );
          setTrackingNo()
          setShippingCareer()
          setTrackingUrl()
          setIsModalOpen(false);
          setSelectedRecord(null)
        }
        else {
          setSaveLoading(false)
          notification.error({
            message: res?.response?.data?.message,
            className: "toast-error",
          });
          setIsModalOpen(false);
          setSelectedRecord(null)
        }
      })
    }




  };
  const handleRefund = () => {
    setSaveLoading(true)
    // let data = {
    //   reason: refundReason,
    //   amount: refundAmount,
    // }
    //for fully refunded call cancel api
    let data = {
      reason: refundReason,
      type: 'refunded',
      orderId: id
    }

    dispatch(scCancelOrder(id, data, sc_token)).then((res) => {
      if (res?.success) {
        setSaveLoading(false)
        notification.success({
          message: res?.message,
          className: "toast-success",
        })
        setLoad(true);
        dispatch(scGetOneOrder(id, sc_token)).then(
          (response) => {
            // console.log(response, 'response');
            if (response.order) {
              setOrderData(response?.order)
              setFormState("edit");
              setLoad(false);

            }
          }
        );
        setTrackingNo()
        setShippingCareer()
        setTrackingUrl()
        setIsModalOpen(false);
        setIsRefundModalOpen(false)
        setIsCancelModalOpen(false)
        setSelectedRecord(null)
      }
      else {
        setSaveLoading(false)
        notification.error({
          message: res?.response?.data?.message,
          className: "toast-error",
        });
        setIsModalOpen(false);
        setSelectedRecord(null)
      }
    })

  };
  const handleCancelFulfillment = (fulfillmentId) => {
    setSaveLoading(true)
    let data = {
      orderId: id,
      fulfillmentID: fulfillmentId || orderData?.fulfillments?.[orderData?.fulfillments?.length - 1]?.id,
    }

    console.log(data, 'data');

    dispatch(scCancelFulfillment(id, data, sc_token)).then((res) => {

      if (res?.success) {
        setSaveLoading(false)
        notification.success({
          message: res?.message,
          className: "toast-success",
        })
        setLoad(true);
        dispatch(scGetOneOrder(id, sc_token)).then(
          (response) => {
            // console.log(response, 'response');

            if (response.order) {
              setOrderData(response?.order)
              setFormState("edit");
              setLoad(false);

            }
          }
        );
        setTrackingNo()
        setShippingCareer()
        setTrackingUrl()
        setIsModalOpen(false);
        setIsCancelModalOpen(false)
        setSelectedRecord(null)
      }
      else {
        setSaveLoading(false)
        notification.error({
          message: res?.response?.data?.message,
          className: "toast-error",
        });
        setIsModalOpen(false);
        setSelectedRecord(null)
      }
    })



  };
  const handleReturn = () => {
    // pending
    setSaveLoading(true)

    let data = {
      orderId: id
    }

    // dispatch(scCancelOrder(id, data, sc_token)).then((res) => {
    //   if (res?.success) {
    //     setSaveLoading(false)
    //     notification.success({
    //       message: res?.message,
    //       className: "toast-success",
    //     })
    //     setLoad(true);
    //     dispatch(scGetOneOrder(id, sc_token)).then(
    //       (response) => {
    //         // console.log(response, 'response');
    //         if (response.order) {
    //           setOrderData(response?.order)
    //           setFormState("edit");
    //           setLoad(false);

    //         }
    //       }
    //     );
    //     setTrackingNo()
    //     setShippingCareer()
    //     setTrackingUrl()
    //     setIsModalOpen(false);
    //     setIsRefundModalOpen(false)
    //     setIsReturnModalOpen(false)
    //     setIsCancelModalOpen(false)
    //     setSelectedRecord(null)
    //   }
    //   else {
    //     setSaveLoading(false)
    //     notification.error({
    //       message: res?.response?.data?.message,
    //       className: "toast-error",
    //     });
    //     setIsModalOpen(false);
    //     setSelectedRecord(null)
    //   }
    // })

  };
  const handleOkDetail = () => {
    setIsModalOpenDetail(false);
  };

  const handleAddTracking = () => {
    setSaveLoading(true)
    console.log(selectedTrackingData, 'selectedTrackingData')
    let data = {
      orderId: id,
      fulfillmentID: selectedTrackingData?.id,
      tracking_number: trackingNo,
      tracking_company: shippingCareer,
      tracking_url: trackingUrl
    }
    console.log(data, 'data');

    dispatch(scFulfillmentTracking(id, data, sc_token)).then((res) => {

      if (res?.success) {
        setSaveLoading(false)
        notification.success({
          message: res?.message,
          className: "toast-success",
        })
        setLoad(true);
        dispatch(scGetOneOrder(id, sc_token)).then(
          (response) => {
            // console.log(response, 'response');

            if (response.order) {
              setOrderData(response?.order)
              setFormState("edit");
              setLoad(false);

            }
          }
        );
        setTrackingNo()
        setShippingCareer()
        setTrackingUrl()
        setIsModalOpen(false);
        setSelectedTrackingData(null)
        setIsTrackingModalOpen(false);
        setSelectedRecord(null)
      }
      else {
        setSaveLoading(false)
        notification.error({
          message: res?.response?.data?.message,
          className: "toast-error",
        });
        setIsModalOpen(false);
        setSelectedRecord(null)
      }
    })
  }

  const handleOkStream = () => {
    setIsModalOpenStream(false);
  };

  const handleCancelStream = () => {
    setIsModalOpenStream(false);
  };

  const OpenSubmit = () => {
    setIsCheckedOpen(false);
  };
  // const handleCancel = () => {
  //   setIsModalOpen(false);

  //   history("/events");
  // };

  const handleCancelDetail = () => {
    setIsModalOpenDetail(false);
  };

  const handleCancelInfo = () => {
    setIsCheckedOpen(false);
    setPlatform(false);
  };

  const handleOkInfo = () => {
    setIsCheckedOpen(false);
  };

  // const handleChange = ({ fileList }) => setFileList(fileList);

  // const handleCancel = () => setPreviewVisible(false);
  const handleCancel = () => {
    setIsModalOpen(false);
    setIsRefundModalOpen(false);
    setIsReturnModalOpen(false);
    setIsCancelModalOpen(false);
    setIsTrackingModalOpen(false);
    setSelectedRecord(null)
    setSelectedTrackingData(null)
    setTrackingNo()
    setShippingCareer()
    setTrackingUrl()
    setIsValid(null)
    setRefundOption('now')
    setCancelReason('customer')
    setStaffNote(undefined)
  };

  // const handlePreview = async (file) => {
  //   setPreviewVisible(true);
  //   let src = file.url;
  //   if (!src) {
  //     src = await new Promise((resolve) => {
  //       const reader = new FileReader();
  //       reader.readAsDataURL(file.originFileObj);
  //       reader.onload = () => resolve(reader.result);
  //     });
  //   }
  //   const image = new Image();
  //   image.src = src;
  //   console.log(src, 'src');
  //   setPreviewImage(src);
  //   const imgWindow = window.open(src);
  //   imgWindow.document.write(image.outerHTML);
  // };

  // const getBase64 = (file) =>
  //   new Promise((resolve, reject) => {
  //     const reader = new FileReader();
  //     reader.readAsDataURL(file);
  //     reader.onload = () => resolve(reader.result);
  //     reader.onerror = (error) => reject(error);
  //   });
  const handlePreview = async (file) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj);
    }
    setPreviewImage(file.url || file.preview);
    setPreviewOpen(true);
  };
  const getBase64 = (file) =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });
  // const handlePreview = async (file) => {
  //   let src = file.url;
  //   if (!src) {
  //     src = await new Promise((resolve) => {
  //       const reader = new FileReader();
  //       reader.readAsDataURL(file.originFileObj);
  //       reader.onload = () => resolve(reader.result);
  //     });
  //   }

  //   setPreviewImage(src);
  //   setPreviewVisible(true);
  //   setPreviewTitle(file.name || file.url.substring(file.url.lastIndexOf('/') + 1));
  // };
  // const handleChange = ({ fileList: newFileList }) => setFileList(newFileList);
  // const handleChange = async ({ file, fileList: newFileList, event }) => {
  //   const isImage = file.type.startsWith('image/');
  //   if (!isImage) {
  //     message.error('You can only upload image files!');
  //     return isImage;
  //   }
  //   else {
  //     setFlag(true);
  //     setFileList(newFileList)
  //     if (file.status === 'uploading') {
  //       setFileList(newFileList)
  //     }
  //     if (file.status == 'done') {
  //       try {


  //         // console.log(fileUrl, 'fileUrl before', i);
  //         const url = await uploadToS3(file.originFileObj);
  //         const dimensions = await getImageDimensions(file.originFileObj);
  //         setFileList(newFileList)
  //         // setFileList([...fileList, { ...file, url }]);
  //         // setFileUrl([...fileUrl, { ...file, url, ...dimensions }]);

  //         setFileUrl(prevFileUrl => [
  //           ...prevFileUrl,
  //           { ...file, url, ...dimensions }
  //         ]);


  //         const allUploaded = newFileList.every(item => item.status === 'done');
  //         if (allUploaded) {
  //           console.log('All files are uploaded.');
  //           // You can set a status or trigger any action here
  //         } else {
  //           console.log('Uploading in progress...');
  //           // Handle uploading status
  //         }
  //       } catch (error) {
  //         message.error(`Upload failed for file ${file.name}`);
  //       }
  //       // console.log(fileUrl, 'fileUrl after', i);
  //     }
  //   }


  // };


  const handleChange = async ({ file, fileList: newFileList }) => {
    // Filter the new file list to only include image files

    // const filteredList = newFileList.filter((file) => file?.type?.startsWith('image/'));

    // Separate files with URLs from those without
    const filesWithUrl = newFileList.filter((f) => f.url);
    const filesWithoutUrl = newFileList.filter((f) => !f.url);

    // // Set the combined file list to state (those with URLs and without URLs)
    setFileList([...filesWithUrl, ...filesWithoutUrl]);

    // Check if any files are currently uploading
    const uploading = filesWithoutUrl.some((f) => f.status === 'uploading');
    setImgLoading(uploading);

    // Handle uploads for files without URLs
    if (file.status === 'done' && file.type.startsWith('image/') && !file.url) {
      await handleFileUpload(file, filesWithoutUrl);
    }
  };

  const handleFileUpload = async (file, currentFileList) => {
    // Skip upload if the file already has a URL
    if (file.url) {
      return;
    }

    try {
      const url = await uploadToS3(file.originFileObj);
      if (url) {
        const dimensions = await getImageDimensions(file.originFileObj);
        const updatedFileList = currentFileList.map((f) =>
          f.uid === file.uid ? { ...f, url, ...dimensions } : f
        );

        setFileUrl((prevFileUrl) => [...prevFileUrl, { ...file, url, ...dimensions }]);

        // Check if all files are done uploading
        const allDone = updatedFileList.every((f) => f.status === 'done' && f.url);
        setImgLoading(!allDone);
      } else {
        message.error(`Failed to upload ${file.name}`);
      }
    } catch (error) {
      message.error(`Upload failed for file ${file.name}`);
    }
  };


  // const beforeUpload = (file) => {
  //   const isImage = file.type.startsWith('image/');
  //   if (!isImage) {
  //     message.error('You can only upload image files!');
  //   }
  //   return isImage;
  // };

  const handleSortEnd = ({ oldIndex, newIndex }) => {
    setFileList(arrayMove(fileList, oldIndex, newIndex));
  };
  // Function to retrieve image dimensions from a File object
  const getImageDimensions = async (file) => {

    return new Promise((resolve, reject) => {
      const reader = new FileReader();

      reader.onload = (event) => {
        const img = new window.Image();

        img.src = event.target.result;

        img.onload = () => {
          resolve({ width: img.width, height: img.height });
        };

        img.onerror = (error) => {
          reject(error);
        };
      };

      reader.readAsDataURL(file);
    });
  };

  const handleRemove = (file) => {
    setFileList(fileList.filter(f => f.uid !== file.uid));
    setFileUrl(fileUrl.filter(f => f.uid !== file.uid));
    if (fileUrl.length === 0) {
      setFlag(false);
    }
  };

  const uploadToS3 = async (file) => {
    const params = {
      Bucket: process.env.REACT_APP_UPLOAD_SC_IMAGES_BUCKET_NAME,
      Key: `${Date.now()}-${file.name}`,
      Body: file,
      ACL: 'public-read',
    };

    try {
      const data = await s3.upload(params).promise();
      return data.Location; // Return the URL of the uploaded file
    } catch (err) {
      console.error('Error uploading to S3:', err);
      return err;
    }
  };

  const selectWeightUnit = (
    // <Form.Item name="weightUnit" className=" mb-0">
    <Select onChange={value => setWeightUnit(value)} defaultValue={weightUnit}>
      <Option value="lb">lb</Option>
      <Option value="oz">oz</Option>
      <Option value="kg">kg</Option>
      <Option value="g">g</Option>
    </Select>
    // </Form.Item>
  );
  const selectWeightUnitForEdit = (
    // <Form.Item name="weightUnit" className=" mb-0">
    <Select
      value={variantValues[selectedRecord?.position - 1]?.weight_unit}
      onChange={(e) => handleTableChange(e, selectedRecord?.position, 'weight_unit')}
    >
      <Option value="lb">lb</Option>
      <Option value="oz">oz</Option>
      <Option value="kg">kg</Option>
      <Option value="g">g</Option>
    </Select>
    // </Form.Item>
  );


  const generateVariants = (options) => {

    const combine = (optionIndex, currentCombination, result) => {
      if (optionIndex === options.length) {
        // Check if currentCombination is not empty
        if (Object.keys(currentCombination)?.length > 0) {
          result.push({ ...currentCombination });
        }
        return;
      }

      const { optionName, optionValues } = options[optionIndex];
      if (optionValues.length === 0) {
        combine(optionIndex + 1, currentCombination, result);
      } else {
        optionValues.forEach(value => {
          currentCombination[`option${optionIndex + 1}`] = value;
          combine(optionIndex + 1, currentCombination, result);
        });
      }
    };

    const variants = [];
    combine(0, {}, variants);
    return variants;
  };
  const generateVariants2 = (options) => {

    const combine = (optionIndex, currentCombination, result) => {
      if (optionIndex === options.length) {
        // Check if currentCombination is not empty
        if (Object.keys(currentCombination)?.length > 0) {
          result.push({ ...currentCombination });
        }
        return;
      }

      const { optionName, optionValues, ...rest } = options[optionIndex];
      if (optionValues.length === 0) {
        combine(optionIndex + 1, currentCombination, result);
      } else {
        optionValues.forEach(value => {
          const newCombination = {
            ...currentCombination,
            [`option${optionIndex + 1}`]: value,
            ...rest
          };
          combine(optionIndex + 1, newCombination, result);
        });
      }
    };

    const variants = [];
    combine(0, {}, variants);
    return variants;
  };

  const handleSubmit = (values) => {
    if (formState === 'add') {
      // console.log(values, 'valuess');

      const filterValues = {
        ...allValues.options,
        options: allValues.options?.map(option => ({
          ...option,
          optionValues: option.optionValues?.filter(value => value != null) || []
        })).filter(option => option.optionName && option.optionValues.length > 0)
      };
      // console.log('Cleaned Values:', filterValues);

      // const variants = values.options ? generateVariants(filterValues.options) : [];


      setSaveLoading(true)
      let images = fileUrl?.map((item, index) => ({
        position: index + 1,
        alt: item?.name,
        width: item?.width,
        height: item?.height,
        src: item.url,
        variant_ids: []
      }));

      let newOptions = filterValues?.options && filterValues?.options?.map((item, index) => ({
        position: index + 1,
        name: item?.optionName,
        values: item?.optionValues
      }))

      // console.log(newOptions, 'newOptions');

      // const newVariant = variants?.length > 0 ? variants?.map((item, index) => {
      //   const options = Object.values(item);
      //   const title = options.join(' / ');
      //   return {
      //     ...item,
      //     position: index + 1,
      //     sku: values.sku,
      //     weight: values.weight,
      //     weight_unit: weightUnit,
      //     title: title,
      //     inventory_quantity: values?.quantity,
      //     price: values?.price,
      //   };
      // }) : [];


      const newVariant = variantValues?.length > 0 ? variantValues?.map((item, index) => {
        return {
          // ...item,
          position: index + 1,
          sku: values.sku,
          weight: values.weight,
          weight_unit: weightUnit,
          title: item?.title,
          inventory_quantity: item?.quantity ? item?.quantity : values?.quantity ? values?.quantity : 0,
          price: item?.price ? item?.price : values?.price ? values?.price : 0,
          compare_at_price: values?.compare_at_price,
          option1: item?.title?.split(" / ")[0] || null,
          option2: item?.title?.split(" / ")[1] || null,
          option3: item?.title?.split(" / ")[2] || null,
          // option1: newOptions.length > 0 ? newOptions[0]?.name : null,
          // option2: newOptions.length > 1 ? newOptions[1]?.name : null,
          // option3: newOptions.length > 2 ? newOptions[2]?.name : null,
        }
      }) : []

      let data = {
        title: values?.title,
        body_html: values?.description,
        status: values?.status,
        tags: tags.toString(),
        // shop_name: validate?.payload?.message?.seller_center?.shop_name,
        // domain: validate?.payload?.message?.seller_center?.shop_name,
        images,
        image: images[0],
        // variants: []
        variants: newVariant?.length > 0 ? newVariant : [{
          title: "Default Title",
          price: values?.price ? values?.price : 0,
          position: 1,
          inventory_quantity: values?.quantity ? values?.quantity : 0,
          sku: values?.sku ? values?.sku : null,
          weight: values?.weight ? values?.weight : 0,
          weight_unit: weightUnit,
          option1: "Default Title",
          compare_at_price: values?.compare_at_price ? values?.compare_at_price : 0,
        }],
        options: newOptions ? newOptions : [{
          name: "Title",
          position: 1,
          values: [
            "Default Title"
          ]
        }]
      }

      // console.log(data, 'data');

      dispatch(scAddProduct(data, sc_token)).then((res) => {
        if (res.success) {
          scform.resetFields();
          setFlag(false)
          setFileUrl([])
          setVariantValues([])
          notification.success({
            message: res?.message,
            className: "toast-success",
          })
          setSaveLoading(false)
          setTimeout(() => {
            history("/scproducts")
          }, 500);
        }
        else {
          notification.error({
            message: res?.response?.data?.message,
            className: "toast-error",
          });
          setSaveLoading(false)
          // scform.resetFields();
        }

        setWeightUnit('lb')
      })
    }
    else {
      // setLoad(true);
      // formstate edit work
      // console.log(allValues, 'allValues');

      const filterValues = {
        ...values.options,
        options: values.options?.map(option => ({
          ...option,
          optionValues: option.optionValues?.filter(value => value != null) || []
        })).filter(option => option.optionName && option.optionValues.length > 0)
      };

      // for swapping
      let newfileList = fileList.map(file => {
        const match = fileUrl.find(urlFile => urlFile.uid === file.uid);
        if (match) {
          return {
            ...file,
            url: match.url,
            width: match.width,
            height: match.height
          };
        }
        return file;
      });

      // console.log(newfileList, 'newfileList');


      setSaveLoading(true)
      // let images = newfileList?.map((item, index) => ({

      //   // Delete the thumbUrl property
      //   delete item.thumbUrl,

      //   ...item,
      //   position: index + 1,
      //   alt: item?.name,
      //   width: item?.width,
      //   height: item?.height,
      //   src: item.url,
      //   variant_ids: []
      // }));
      let images = newfileList?.map((item, index) => {
        // Create a new object without modifying the original item
        let newItem = { ...item };
        // Delete the thumbUrl property
        delete newItem.thumbUrl;
        delete newItem.response;

        return {
          ...newItem,
          position: index + 1,
          alt: newItem?.name,
          width: newItem?.width,
          height: newItem?.height,
          src: newItem.url,
          variant_ids: []
        };
      });
      let newOptions = filterValues?.options && filterValues?.options?.map((item, index) => ({
        ...item,
        position: index + 1,
        name: item?.optionName,
        values: item?.optionValues
      }))

      const mergedVariants = mergeVariants(variantValues, previousVariants);
      let newVariant
      if (formState === 'add') {
        newVariant = variantValues?.length > 0 ? variantValues?.map((item, index) => {
          return {
            ...item,
            position: index + 1,
            // sku: values.sku,
            weight: values.weight,
            weight_unit: weightUnit,
            title: item?.title,
            inventory_quantity: item?.quantity ? item?.quantity : values?.quantity,
            price: item?.price ? item?.price : values?.price,
            compare_at_price: values?.compare_at_price,
            option1: item?.title.split(" / ")[0] || null,
            option2: item?.title.split(" / ")[1] || null,
            option3: item?.title.split(" / ")[2] || null,
            // option1: newOptions.length > 0 ? newOptions[0]?.name : null,
            // option2: newOptions.length > 1 ? newOptions[1]?.name : null,
            // option3: newOptions.length > 2 ? newOptions[2]?.name : null,
          }
        }) : []
      } else {
        newVariant = mergedVariants?.length > 0 ? mergedVariants?.map((item, index) => {
          return {
            ...item,
            position: index + 1,
            // sku: values.sku,
            // weight: values.weight,
            // weight_unit: weightUnit,
            title: item?.title,
            inventory_quantity: item?.quantity ? item?.quantity : values?.quantity ? values?.quantity : 0,
            price: item?.price ? item?.price : values?.price,
            // compare_at_price: values?.compare_at_price,
            option1: item?.title?.split(" / ")[0] || null,
            option2: item?.title?.split(" / ")[1] || null,
            option3: item?.title?.split(" / ")[2] || null,
            // option1: newOptions.length > 0 ? newOptions[0]?.name : null,
            // option2: newOptions.length > 1 ? newOptions[1]?.name : null,
            // option3: newOptions.length > 2 ? newOptions[2]?.name : null,
          }
        }) : []
      }




      let data = {
        product_id: orderData?.id,
        brand_id: orderData?.brand_id,
        title: values?.title,
        body_html: values?.description,
        status: values?.status,
        tags: tags.toString(),
        images,
        image: images[0],
        variants: newVariant?.length > 0 ? newVariant : [{
          title: "Default Title",
          price: values?.price ? values?.price : 0,
          position: 1,
          inventory_quantity: values?.quantity ? values?.quantity : 0,
          sku: values?.sku,
          weight: values?.weight,
          weight_unit: weightUnit,
          option1: "Default Title",
          compare_at_price: values?.compare_at_price,
        }],
        options: (newOptions && newOptions.length > 0) ? newOptions : [{
          name: "Title",
          position: 1,
          values: [
            "Default Title"
          ]
        }]
      }


      // scform.resetFields();
      setFlag(false)
      setVariantValueChange(false)
      // setFileUrl([])
      // setVariantValues([])
      dispatch(scUpdateProduct(data, sc_token)).then((res) => {
        if (res.success) {
          scform.resetFields();
          notification.success({
            message: res?.message,
            className: "toast-success",
          })
          // setSaveLoading(false)
          // setLoad(false);
          setTimeout(() => {
            history("/scproducts")
          }, 500);
        }
        else {
          notification.error({
            message: res?.response?.data?.message,
            className: "toast-error",
          });
          setLoad(false);
          setSaveLoading(false)
          scform.resetFields();
        }

        // setWeightUnit('lb')
      })

    }





  };




  const uploadButton = (
    <button
      style={{
        border: 0,
        background: 'none',
      }}
      type="button"
    >
      <PlusOutlined />
      <div
        style={{
          marginTop: 8,
        }}
      >
        Upload
      </div>
    </button>
  );

  const onToggle = (record, recInd) => {
    if (record?.is_active == true) {
      record.is_active = false;
      let obj = record;
      const key = { ...obj };
      key.is_active = false;

      setSku((current) =>
        current?.map((obj, index) => {
          if (index == recInd) {
            return { ...obj, is_active: false };
          }
          return obj;
        })
      );
    } else {
      record.is_active = true;
      let obj = record;
      const key = { ...obj };
      key.is_active = true;
      setSku((current) =>
        current?.map((obj, index) => {
          if (index == recInd) {
            return { ...obj, is_active: true };
          }
          return obj;
        })
      );
    }
    // record.is_active = checked;
  };
  // console.log(selectedRecord, 'selectedRecord');

  const handleTableChange = (value, key, column) => {
    setVariantValueChange(true)
    const newData = [...variantValues];

    const index = newData.findIndex((item) => key === item.position);
    if (index > -1) {
      const item = newData[index];
      setSelectedRecord(item); //for variant view
      newData.splice(index, 1, { ...item, [column]: value });
      setVariantValues(newData);
    } else {
      newData.push({ key, [column]: value });
      setVariantValues(newData);
    }
  };

  const handleQuantityChange = (value, index) => {
    const updatedRecords = [...selectedRecord];
    updatedRecords[index].fulfillable_quantity = value;
    setSelectedRecord(updatedRecords);
  };
  const handleReturnReason = (value, index) => {
    const updatedRecords = [...selectedRecord];
    updatedRecords[index].reason = value;
    setSelectedRecord(updatedRecords);
  };

  // const handleInputChange = (value, key, column) => {
  //   const newData = [...transformData];

  //   const updateChild = (children) => {
  //     return children.map(child => {
  //       if (child.key === key) {
  //         return { ...child, [column]: value };
  //       } else if (child.children) {
  //         return { ...child, children: updateChild(child.children) };
  //       }
  //       return child;
  //     });
  //   };

  //   const updatedData = newData.map(item => {
  //     if (item.key === key) {
  //       return { ...item, [column]: value };
  //     } else if (item.children) {
  //       return { ...item, children: updateChild(item.children) };
  //     }
  //     return item;
  //   });

  //   setTransformData(updatedData);
  // };

  const variantsColumn = [
    {
      title: "S.No",
      dataIndex: "sno",
      key: "sno",
      width: 10,
      render: (text, record, index) => {
        return <p>{index + 1}</p>;
      },
    },
    {
      title: "Variant",
      dataIndex: "title",
      key: "title",
      // width: 100,
    },
    {
      title: "Price",
      dataIndex: "price",
      key: "price",
      // width: 100,
      render: (text, record) => (
        <InputNumber
          defaultValue={formState === "edit" ? record?.price ? record?.price : allValues.price : allValues.price ? allValues.price : 0.00}
          style={{ width: '100%' }}
          value={text}
          placeholder="0.00"
          min={0}
          // max={allValues.price}
          formatter={(value) => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
          parser={(value) => value?.replace(/\$\s?|(,*)/g, '')}
          onChange={(e) => handleTableChange(e, record?.position, 'price')}
        />
      ),
    },
    {
      title: "Available",
      dataIndex: "quantity",
      key: "quantity",
      // width: 100,
      render: (text, record) => (
        <InputNumber
          defaultValue={formState === "edit" ? record?.quantity ? record?.quantity : allValues.quantity : allValues.quantity ? allValues.quantity : 0}
          value={text}
          min={0}
          // max={allValues.quantity}
          placeholder="0"
          style={{ width: '100%' }}
          onChange={(e) => handleTableChange(e, record?.position, 'quantity')}
        />

      ),
    },
    {
      title: "View",
      key: "details",
      hidden: formState === 'add' ? true : false,
      render: (text, record) => (
        <Button
          className="default-btn"
          style={{ minWidth: "auto", height: "auto", padding: '6px' }}
          // type="link"
          icon={<EyeFilled twoToneColor="white" />}
          onClick={() => showModal(record)}
        >
          {/* View Details */}
        </Button>
      ),
    },
    // {
    //   title: "IMAGE",
    //   dataIndex: "mediaUrl",
    //   key: "mediaUrl",
    //   width: 70,
    //   render: (text, record) => {
    //     return (
    //       <div className="listing-image inventory-image">
    //         <Image src={`${record.mediaUrl}`} />
    //       </div>
    //     );
    //   },
    // },
    // {
    //   title: "Description",
    //   dataIndex: "productDesc",
    //   key: "productDesc",
    //   width: 250,
    //   render: (text, record) => {
    //     return <div className="desc-limit">{record.productDesc}</div>;
    //   },
    // },
    // {
    //   title: "Price",
    //   dataIndex: "price",
    //   key: "price",
    //   width: 80,
    //   render: (text, record) => {
    //     return <div className="desc-limit">${record.price}</div>;
    //   },
    // },

    // {
    //   title: "Balance",
    //   dataIndex: "event",
    //   key: "event",
    //   width: 140,
    //   render: (text, record) => (
    //     <>
    //       <div className="balance-area">
    //         <div className="balance-count">
    //           {record?.variants?.reduce((acc, item) => {
    //             return (acc = acc + item.inventory_quantity);
    //           }, 0)}
    //         </div>
    //         <div className="balance-area">
    //           <a
    //             className="balance-btn default-btn outline small-btn"
    //             onClick={() => {
    //               showModalDetail(record);
    //             }}
    //           >
    //             View
    //             {/* <FontAwesomeIcon icon={faEye} /> */}
    //           </a>
    //         </div>
    //       </div>
    //     </>
    //   ),
    // },
    // {
    //   title: "Status",
    //   dataIndex: "event",
    //   key: "event",
    //   width: 100,
    //   render: (text, record) => {
    //     let status = record?.variants?.reduce((acc, item) => {
    //       return (acc = acc + item.inventory_quantity);
    //     }, 0);
    //     if (status >= 70) {
    //       return (
    //         <div className="status-info">
    //           <div className="status-flag green"></div>
    //           {/* <p>green</p> */}
    //         </div>
    //       );
    //     } else if (status >= 50 && status <= 70) {
    //       return (
    //         <div className="status-info">
    //           <div className="status-flag yellow"></div>
    //           {/* <p>yellow</p> */}
    //         </div>
    //       );
    //     } else if (status <= 50) {
    //       return (
    //         <div className="status-info">
    //           <div className="status-flag red"></div>
    //           {/* <p>red</p> */}
    //         </div>
    //       );
    //     } else {
    //       return null;
    //     }
    //   },
    // },

  ].filter(item => !item.hidden);
  // const variantsColumn2 = [
  //   {
  //     title: 'Title',
  //     dataIndex: 'title',
  //     key: 'title'
  //   },
  //   {
  //     title: 'Price',
  //     dataIndex: 'price',
  //     key: 'price',
  //     render: (text, record) => (
  //       <Input
  //         value={text}
  //         onChange={(e) => handleInputChange(e.target.value, record.key, 'price')}
  //       />
  //     )
  //   },
  //   {
  //     title: 'Quantity',
  //     dataIndex: 'quantity',
  //     key: 'quantity',
  //     render: (text, record) => (
  //       <Input
  //         value={text}
  //         onChange={(e) => handleInputChange(e.target.value, record.key, 'quantity')}
  //       />
  //     )
  //   }

  // ];
  const columns = [
    {
      title: "S.No",
      dataIndex: "sno",
      key: "sno",
      width: 60,
      render: (text, record, index) => {
        return <p>{index + 1}</p>;
      },
    },
    {
      title: "SKU",
      dataIndex: "ProductSku",
      key: "ProductSku",
      width: 60,
    },
    {
      title: "IMAGE",
      dataIndex: "mediaUrl",
      key: "mediaUrl",
      width: 70,
      render: (text, record) => {
        return (
          <div className="listing-image inventory-image">
            <Image src={`${record.mediaUrl}`} />
          </div>
        );
      },
    },
    {
      title: "Description",
      dataIndex: "productDesc",
      key: "productDesc",
      width: 250,
      render: (text, record) => {
        return <div className="desc-limit">{record.productDesc}</div>;
      },
    },
    {
      title: "Price",
      dataIndex: "price",
      key: "price",
      width: 80,
      render: (text, record) => {
        return <div className="desc-limit">${record.price}</div>;
      },
    },

    {
      title: "Balance",
      dataIndex: "event",
      key: "event",
      width: 140,
      render: (text, record) => (
        <>
          <div className="balance-area">
            <div className="balance-count">
              {record?.variants?.reduce((acc, item) => {
                return (acc = acc + item.inventory_quantity);
              }, 0)}
            </div>
            <div className="balance-area">
              <a
                className="balance-btn default-btn outline small-btn"
                onClick={() => {
                  showModalDetail(record);
                }}
              >
                View
                {/* <FontAwesomeIcon icon={faEye} /> */}
              </a>
            </div>
          </div>
        </>
      ),
    },
    {
      title: "Status",
      dataIndex: "event",
      key: "event",
      width: 100,
      render: (text, record) => {
        let status = record?.variants?.reduce((acc, item) => {
          return (acc = acc + item.inventory_quantity);
        }, 0);
        if (status >= 70) {
          return (
            <div className="status-info">
              <div className="status-flag green"></div>
              {/* <p>green</p> */}
            </div>
          );
        } else if (status >= 50 && status <= 70) {
          return (
            <div className="status-info">
              <div className="status-flag yellow"></div>
              {/* <p>yellow</p> */}
            </div>
          );
        } else if (status <= 50) {
          return (
            <div className="status-info">
              <div className="status-flag red"></div>
              {/* <p>red</p> */}
            </div>
          );
        } else {
          return null;
        }
      },
    },
    // {
    //   title: "Action",
    //   key: "action",
    //   fixed: "right",
    //   width: 100,
    //   className: "text-left",
    //   // sorter: true,
    //   render: (text, record, index) => {
    //     return (
    //       <Space size="middle" className="actions-list">
    //         <Switch
    //           disabled={isModalOpen}
    //           size="small"
    //           checked={record?.is_active}
    //           onChange={() => onToggle(record, index)}
    //         />
    //       </Space>
    //     );
    //   },
    // },
  ];

  const columnDetails = [
    {
      title: "Stock",
      dataIndex: "inventory_quantity",
      key: "inventory_quantity",
      width: 60,
    },
  ];

  const getSku = (skuData) => {
    setSkuError(false);
    setSku(skuData);
  };

  const skuDataFunc = (value) => {
    setSku(value);
  };

  const handleCategory = (e) => {
    let parent = parentCategory?.payload?.message.filter(
      (item) => item.category_id == e
    );
    setParent(parent[0]?.category_name);
  };

  const onChangeTitle = (e) => {
    setInfoTitle(e.target.value);
  };

  const onChangeDescription = (e) => {
    setInfoDesc(e.target.value);
  };

  const changePromoCode = (e, options, name, index) => {
    if (e === undefined) {
    } else {
      var values = e.value.split(" ");
      var discount = values[0];
      setProductPromoCodeDscs(discount);
      setproductPromoCodePromo(e.children);
    }
  };

  const showModalDetail = (s) => {
    setDataDetail(s);
    setIsModalOpenDetail(true);
  };

  const normFile = (e) => {
    if (e.fileList?.length > 0) {
      if (previewImage !== "" || contentType !== "") {
        setVideo(e.file);
      }
    }
    if (Array.isArray(e)) {
      return e;
    }
    return e?.fileList;
  };

  const uploadProps = {
    accept: "image/jpeg, image/png, image/jpg, image/svg+xml, image/svg",
    name: "file",
    multiple: false,
    listType: "picture",
    maxCount: 1,
    action: "https://httpbin.org/post",

    beforeUpload: (file) => {
      const isPNG =
        file.type === "image/png" ||
        file.type === "image/jpg" ||
        file.type === "image/jpeg" ||
        file.type === "image/svg+xml" ||
        file.type === "image/svg";

      if (!isPNG) {
        message.error(`${file?.name} is not a valid format`);
      }

      return isPNG || Upload.LIST_IGNORE;
    },

    onChange(info) {
      setFlagg(true);
      setImageUpload(true);
      const { status } = info?.file;

      if (status !== "uploading") {
        setBanner(info?.file?.originFileObj);
      }

      if (status === "done") {
        message.success(`${info?.file.name} file uploaded successfully.`);
        setImageUpload(false);
        setPreviewImage(info?.file?.response?.files?.banner);
        setBannerExists(true);
        setMsg(false);
      } else if (status === "error") {
        notification.error({
          message: "Upload Failed Try Again",
          className: "toast-error",
        });
        // message.error(`${info.file.name} file upload failed.`);
        setMsg(true);
        setBannerExists(false);
      }
    },

    onDrop(e) {

    },

    onRemove(e) {

      // return Upload.LIST_IGNORE;
      setPreviewImage("");
    },
  };

  const handleTagChange = (newTags) => {
    // Filter to ensure only valid emails are retained
    // const filteredEmails = newTags.filter(email => email && isValidEmail(email));
    const filteredTags = newTags.filter(tag => tag);
    setTags(filteredTags);
    scform.setFieldsValue({
      tags: filteredTags
    })
  };











  function ErrorFallback({ error, resetErrorBoundary }) {
    return (
      <div role="alert">
        <p>Something went wrong:</p>
        <pre>{error.message}</pre>
        <button onClick={resetErrorBoundary}>Try again</button>
      </div>
    );
  }

  // const config = {
  //   bucketName: process.env.REACT_APP_SHOWS_BUCKET_NAME,
  //   region: process.env.REACT_APP_SHOWS_REGION,
  //   accessKeyId: process.env.REACT_APP_SHOWS_ACCESS_KEY_ID,
  //   secretAccessKey: process.env.REACT_APP_SHOWS_SECRET_ACCESS_KEY,
  // };

  AWS.config.update({
    region: process.env.REACT_APP_SHOWS_REGION,
    accessKeyId: process.env.REACT_APP_SHOWS_ACCESS_KEY_ID,
    secretAccessKey: process.env.REACT_APP_SHOWS_SECRET_ACCESS_KEY,
  });
  const videoProps = {
    accept: "video/mp4",
    onRemove: (file) => {
      setVideoThumb(null);
      const index = fileList.indexOf(file);
      const newFileList = fileList.slice();
      newFileList.splice(index, 1);
      setFileList(newFileList);
      refs.current.thumbButton.style.display = "none";
      refs.current.numberInput.style.display = "none";
      setThumbnails([]);
      setThumbNumber(0);
      setVideoUrl();
      setRefresh(true);
    },
    beforeUpload: (file) => {
      setRefresh(false);
      const url = URL.createObjectURL(file);

      const $video = document.createElement("video");
      $video.src = url;
      $video.addEventListener("loadedmetadata", function () {
        if (this.videoWidth > this.videoHeight) {
          setOrientation("landscape");
        } else {
          setOrientation("portrait");
        }
      });

      setContentType(file.type);
      setExt(file.name.split(".")[file.name.split(".")?.length - 1]);
      setFileList([file]);
      if (file.target.files?.length > 0) {
        setVideo(file.target.files[0]);
      }
      return false;
    },
    fileList,
  };
  const onDateTimeChange = (e) => {
    if (e !== null) {
      setDate(e._d);
    } else {
      setDate("");
    }
  };
  const handleButtonClick = () => {
    setPromptModal(false);
    confirm({
      wrapClassName: "modal-generic cancel-event-modal min-modal-600",
      title: "Warning",
      centered: true,
      closable: true,
      icon: <ExclamationCircleOutlined />,
      closeIcon: <FontAwesomeIcon icon={faXmark} />,
      content: "Are you sure you want to exit the show ?",
      okText: " Yes, Exit",
      okType: "danger",
      cancelText: " No, I don't want to exit ",

      onOk() {
        // history("/shows");
        history(published ? "/shows?type=Published" : "/shows");
      },
      onCancel() {
        setPromptModal(true);

      },
    });
  };







  if (genLoad) {
    return (
      <>
        <span className="spinclass loader-center position-relative">
          <Spin size="large" />
        </span>
      </>
    );
  }
  const handlePage = (link, menu) => {
    history(link, {
      state: { key: link.split("/").length > 2 ? link.split("/")[2] : link.split("/")[1], openKey: menu }
    }
    );
  }

  const handleOptionNameChange = (value, index) => {
    const variants = scform.getFieldValue('variants');
    if (variants[index].optionValues.length === 0) {
      variants[index].optionValues.push('');
      scform.setFieldsValue({ variants });
    }
  };

  const jsonToCSV = (jsonData) => {
    const csvRows = [];
    // Extract the headers
    const headers = ["Address", "Phone Number", "City", "Postal Code", "State", "Country", "Additional Address", "Company Name", "Full Name"];
    csvRows.push(headers.join(','));

    // Extract the values
    const values = [
      jsonData.address1,
      jsonData.phone,
      jsonData.city,
      jsonData.zip,
      jsonData.province,
      jsonData.country,
      jsonData.address2 || '',
      jsonData.company || '',
      jsonData.name
    ];
    csvRows.push(values.join(','));

    return csvRows.join('\n');
  };

  const downloadCSV = (csvData, filename) => {
    const blob = new Blob([csvData], { type: 'text/csv' });
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = filename;
    a.click();
    window.URL.revokeObjectURL(url);
  };

  const handleDownload = () => {
    const csvData = jsonToCSV(orderData?.billing_address);
    downloadCSV(csvData, 'billing_address.csv');
  };

  const handleValuesChange = (changedValues, allValues) => {
    if (time) {
      setAllValues(allValues)
      setFormValues(allValues.options);
      // console.log(allValues.options, 'allValues.options');


      const filterValues = {
        ...allValues.options,
        options: allValues.options?.map(option => ({
          ...option,
          optionValues: option.optionValues?.filter(value => value != null) || []
        })).filter(option => option.optionName && option.optionValues.length > 0)
      };


      // console.log('Cleaned Values:', filterValues);

      const variants = allValues?.options ? allValues?.options?.length > 0 ? generateVariants(filterValues.options) : [] : [];
      // setSaveLoading(true)
      const newVariant = variants?.length > 0 ? variants?.map((item, index) => {
        const options = Object.values(item);
        let title;
        if (formState === 'add') {
          title = options.join(' / ');
        }
        else {
          const titleParts = [];
          if (item.option1) titleParts.push(item.option1);
          if (item.option2) titleParts.push(item.option2);
          if (item.option3) titleParts.push(item.option3);
          title = titleParts.join(' / ');
        }

        return {
          ...item,
          position: index + 1,
          // sku: values.sku,
          // weight: values.weight,
          weight_unit: weightUnit,
          title: title,
          // inventory_quantity: item?.quantity,
          id: variantValues[index]?.id,
          barcode: variantValues[index]?.barcode,
          compare_at_price: variantValues[index]?.compare_at_price ? variantValues[index]?.compare_at_price : allValues?.compare_at_price,
          weight: variantValues[index]?.weight,
          sku: variantValues[index]?.sku ? variantValues[index]?.sku : previousVariants[index]?.sku ? previousVariants[index]?.sku : allValues?.sku,
          price: variantValues[index]?.price ? variantValues[index]?.price : variantValues[0]?.price,
          inventory_quantity: variantValues[index]?.quantity ? variantValues[index]?.quantity : variantValues[0]?.quantity,
          quantity: variantValues[index]?.quantity ? variantValues[index]?.quantity : variantValues[0]?.quantity,
        };
      }) : [];
      // { console.log(formValues, 'variants') }



      //transfome data
      // const result = [];

      // newVariant.forEach(item => {
      //   // Find or create the parent option1
      //   let parent = result.find(r => r.title === item.option1);
      //   if (!parent) {
      //     parent = {
      //       key: item.option1,
      //       title: item.option1,
      //       children: []
      //     };
      //     result.push(parent);
      //   }

      //   // Find or create the child option2
      //   let child = parent.children.find(c => c.title === item.option2);
      //   if (!child) {
      //     child = {
      //       key: `${item.option1}-${item.option2}`,
      //       title: item.option2,
      //       ...item,
      //       children: []
      //     };
      //     parent.children.push(child);
      //   }

      //   // Add the sub-child if option3 exists (not in your current data structure, but for future reference)
      //   if (item.option3) {
      //     child.children.push({
      //       key: `${item.option1}-${item.option2}-${item.option3}`,
      //       title: item.option3,
      //       ...item
      //     });
      //   }
      // });
      // setTransformData(result)
      //transfome data


      setVariantValues(newVariant)
    }
  };
  const menu = (
    <Menu style={{ width: "200px" }}>
      <Menu.Item onClick={() => setIsCancelModalOpen(true)}>
        Cancel Order
      </Menu.Item>
      <Menu.Item>
        Archive
      </Menu.Item>
    </Menu>
  );
  const fulfillmentMenu = (
    <Menu style={{ width: "150px" }}>
      <Menu.Item onClick={handleCancelFulfillment}>
        Cancel fulfillment
      </Menu.Item>
    </Menu>
  );
  return (
    <>
      <div className="create-event-top-area mb-4 d-flex justify-content-start">
        <div className="top-area-box ">
          <div className="title-area mb-0 pb-0">
            <h1 className="mb-0 pb-0">
              <>

                <div className="control-room-top-area mb-2">
                  <div className="control-top-box">
                    <Link
                      to={
                        `/scorders`
                      }
                      className="prev-next-link ff-nunito"
                    >
                      <FontAwesomeIcon icon={faAngleLeft} />
                      <span>Go back</span>
                    </Link>
                    {/* </a> */}
                  </div>
                </div>


                Order Detail
              </>
            </h1>
          </div>
        </div>
        {/* <div className="top-area-box">&nbsp;</div> */}
      </div>


      <>
        {
          load ?
            <>
              <span className="spinclass loader-center position-relative">
                <Spin size="large" />
              </span>
            </>
            :
            <div className="create-event-wrapper sc-add-product-container  mb-30">


              <div
                className="create-event-box-left sc-add-products-main"
              >

                {
                  scGetOneOrderDetail?.payload?.success ?
                    <>

                      <Row gutter={20}>
                        <Col span={16} className="pr-20 code-activated-popup">
                          <div className="order-detail-title-area flex-column">
                            <div className="d-flex flex-column mb-2">
                              <div className="order-num total-orders-batches">
                                Order
                                <div className="order-name">
                                  {
                                    orderData
                                      ?.name
                                  }
                                </div>

                                {/* {
                                  orderData?.refunds?.[0]?.note == 'Order Cancelled' &&
                                  <div className="connection-status-badge-red firstLetterCapital">
                                    Cancelled
                                  </div>
                                } */}

                                <div className={` firstLetterCapital ${orderData?.financial_status == 'cancelled' ? 'connection-status-badge-red' : 'connection-status-badge-grey'}`}>
                                  {
                                    orderData
                                      ?.financial_status
                                  }
                                </div>
                                {
                                  // orderData?.fulfillments && orderData?.fulfillments?.length > 0 && orderData?.fulfillments?.some(item => item?.status == 'success') ?
                                  orderData?.fulfillment_status === 'fulfilled' ?
                                    // <div className="connection-status-badge-grey firstLetterCapital">
                                    //   {
                                    // orderData
                                    //   ?.fulfillment_status === "partial" ?
                                    //   <div className="connection-status-badge-orange firstLetterCapital">
                                    //     Partially Fulfilled
                                    //   </div>
                                    //   :
                                    <div className="connection-status-badge-grey firstLetterCapital">
                                      Fulfilled
                                    </div>
                                    : orderData?.fulfillment_status === 'partial' ?
                                      <div className="connection-status-badge-grey firstLetterCapital">
                                        Partially Fulfilled
                                      </div>

                                      //   }
                                      // </div>
                                      :
                                      <div className="connection-status-badge-yellow firstLetterCapital">
                                        Unfulfilled
                                      </div>
                                }
                                {/* {
                            !detailsOrder?.loading && detailsOrder?.payload?.message?.order_return_detail?.length > 0
                            &&
                            <div className="connection-status-badge-grey firstLetterCapital">
                              Returned
                            </div>
                          } */}
                              </div>
                            </div>
                            <span className="order-date">
                              {moment(orderData?.created_at, 'YYYY-MM-DDTHH:mm:ss.SSSZ').calendar(null, {
                                lastDay: '[Yesterday at] h:mm A',
                                sameDay: '[Today at] h:mm A',
                                nextDay: '[Tomorrow at] h:mm A',
                                lastWeek: 'dddd [at] h:mm A', // Adjust this line
                                nextWeek: 'dddd [at] h:mm A',
                                // sameElse: 'DD MMM [at] h:mm A',
                                sameElse: function (now) {
                                  if (this.isBefore(now, 'year')) {
                                    // For dates in the previous year
                                    return 'MMMM DD, YYYY [at] h:mm A';
                                  } else {
                                    // For all other dates
                                    return 'DD MMM [at] h:mm A';
                                  }
                                }
                              })}
                            </span>
                          </div>
                        </Col>
                        {
                          <Col span={8} className="d-flex justify-content-end">
                            {orderData?.refunds?.length == 0 &&
                              ((orderData?.fulfillments && orderData?.fulfillments?.length > 0 && orderData?.fulfillment_status === 'fulfilled') ?
                                <>
                                  {/* {
                                    // !orderData?.fulfillments?.[0]?.tracking_company &&
                                    !orderData?.fulfillments?.some(item => item?.tracking_company) &&
                                    <Button onClick={() => setIsModalOpen(true)}
                                      className="ant-btn ant-btn-primary default-btn padb-3 small-btn m-width-100 ml-0 mt-1 mb-1 fw-normal h-30">
                                      Add Tracking
                                    </Button>
                                  } */}
                                  <Button
                                    // onClick={() => setIsModalOpen(true)} 
                                    onClick={() => {
                                      setIsReturnModalOpen(true);
                                      setSelectedRecord(orderData?.line_items.map(item => ({
                                        ...item,
                                        quantity: item.quantity,
                                      })));
                                    }}
                                    className="ant-btn ant-btn-primary default-btn padb-3 small-btn m-width-100 ml-0 mt-1 mb-1 fw-normal h-30">
                                    Return
                                  </Button>
                                </>
                                :
                                <Button onClick={() => {
                                  setIsModalOpen(true);
                                  setSelectedRecord(orderData?.line_items
                                    // .filter(item => item.fulfillment_status !== 'fulfilled') // Filter items that are not fulfilled
                                    .map(item => ({
                                      ...item,
                                      quantity: item.fulfillment_status === 'partial'
                                        ? item.quantity - item.fulfillable_quantity
                                        : item.quantity,
                                      fulfillable_quantity: item.fulfillment_status === 'partial'
                                        ? item.quantity - item.fulfillable_quantity
                                        : item.fulfillable_quantity,
                                    }))
                                  );
                                }}
                                  className="ant-btn ant-btn-primary default-btn padb-3 small-btn m-width-100 ml-0 mt-1 mb-1 fw-normal h-30">
                                  Fulfill
                                </Button>)
                            }

                            {orderData?.refunds?.length == 0 &&
                              <Button
                                onClick={() => {
                                  setIsRefundModalOpen(true);
                                  setSelectedRecord(orderData?.line_items
                                    // .filter(item => item.fulfillment_status !== 'fulfilled') // Filter items that are not fulfilled
                                    .map(item => ({
                                      ...item,
                                      quantity: item.fulfillment_status === 'partial'
                                        ? item.quantity - item.fulfillable_quantity
                                        : item.quantity,
                                      fulfillable_quantity: item.fulfillment_status === 'partial'
                                        ? item.quantity - item.fulfillable_quantity
                                        : item.fulfillable_quantity,
                                    }))
                                  );

                                }}
                                className="ant-btn ant-btn-primary default-btn padb-3 small-btn m-width-100 ml-0 mt-1 mb-1 fw-normal h-30">
                                Refund
                              </Button>}
                            {
                              orderData?.refunds?.[0]?.note != 'Order Cancelled'
                              && ((orderData?.fulfillments && orderData?.fulfillments?.length == 0)
                                // || orderData?.fulfillments?.[orderData?.fulfillments?.length - 1]?.status == 'cancelled'
                              )
                              &&
                              <Dropdown
                                className="ant-btn ant-btn-primary default-btn padb-3 small-btn m-width-100 ml-0 mt-1 mb-1 fw-normal h-30"
                                overlay={menu}
                                trigger={['click']} placement="bottom">
                                <a className="sc-order-items-detail" onClick={(e) => e.stopPropagation()}>
                                  <Space>
                                    More actions
                                    <DownOutlined className="hover-icon mb-2" />
                                  </Space>
                                </a>
                              </Dropdown>}

                          </Col>}
                      </Row>
                      <Row gutter={20}>
                        <Col span={16} className="pr-20 code-activated-popup">
                          <div style={{ marginBottom: 8, border: '1px solid #d9d9d9', padding: '16px', borderRadius: '4px' }}>
                            <Space style={{ display: 'flex', marginBottom: 8, flexDirection: 'column', alignItems: 'flex-start' }} align="baseline" className="sc-order-detail-container">


                              {
                                orderData?.fulfillment_status !== 'fulfilled' &&
                                <div>
                                  {
                                    ((orderData?.fulfillments && orderData?.fulfillments.length === 0) || orderData?.fulfillment_status === 'partial' || orderData?.fulfillment_status === null) &&
                                    <div className="connection-status-badge-yellow firstLetterCapital wfit mb-2">
                                      {/* Unfulfilled ({orderData?.line_items && orderData?.line_items?.length}) */}
                                      Unfulfilled (
                                      {orderData?.line_items?.reduce((total, { fulfillment_status, quantity, fulfillable_quantity }) =>
                                        fulfillment_status === null ? total + quantity :
                                          fulfillment_status === 'partial' ? total + (quantity - fulfillable_quantity) : total, 0)}
                                      )
                                    </div>
                                  }
                                  {
                                    // orderData?.fulfillments && orderData?.fulfillments?.length === 0 && 
                                    orderData?.fulfillments?.some(item => item?.status == 'success')
                                    &&
                                    <>
                                      <div>
                                        Delivery method
                                      </div>
                                      <div>
                                        {orderData?.shipping_lines?.[0]?.title}
                                      </div>
                                    </>

                                  }
                                  {/* {
                                orderData?.fulfillments && orderData?.fulfillments?.length > 0 && orderData?.fulfillments?.some(item => item?.status == 'success')
                                &&
                                <>
                                  <div className="order-date">
                                    {moment(orderData?.fulfillments?.[0]?.created_at).format('DD MMMM YYYY')}
                                  </div>
                                  {

                                    orderData?.fulfillments?.[0]?.tracking_company ?
                                      <>
                                        <div>
                                          {orderData?.fulfillments?.[0]?.tracking_company} tracking number
                                        </div>
                                        <div>
                                          <a
                                            target="_blank"
                                            href={orderData?.fulfillments?.[0]?.tracking_url}
                                          >
                                            {orderData?.fulfillments?.[0]?.tracking_numbers}
                                          </a>
                                        </div>
                                      </>
                                      :
                                      <></>

                                  }
                                </>
                              } */}
                                  <hr />
                                  {orderData?.cancel_reason &&
                                    <div className="connection-status-badge-grey firstLetterCapital w-fit mb-1">
                                      Removed ({
                                        orderData
                                          ?.line_items?.length
                                      })
                                    </div>}
                                  <div
                                    className="sc-order-lines-detail"
                                    style={{
                                      marginBottom: '8px',
                                      border: '1px solid rgb(217, 217, 217)',
                                      padding: '16px 0px',
                                      borderRadius: '4px',
                                    }}
                                  >
                                    {orderData?.line_items?.map((item, index) => (
                                      item?.fulfillment_status !== 'fulfilled' &&
                                      <>
                                        <div>
                                          <Row style={{ width: "100%", padding: '0 12px' }}>
                                            <Col span={16}>
                                              <div><b>{item.name}</b></div>
                                              {item?.title &&
                                                <div
                                                  style={{ backgroundColor: '#f0f0f0', width: 'fit-content', borderRadius: '10px' }}
                                                >
                                                  <span
                                                    style={{ padding: '8px', }}
                                                  >
                                                    {item?.title}
                                                  </span>
                                                </div>}
                                              {item?.sku && <span>SKU: {item?.sku}</span>}
                                            </Col>
                                            <Col span={8} className="text-right">
                                              <span className="ml-2">{numeral(
                                                item.price
                                              ).format("$0,0.0'")} </span>
                                              <span className="ml-2">x</span>
                                              <span className="ml-2">{item.fulfillment_status === 'partial' ? item.quantity - item.fulfillable_quantity : item.quantity}</span>
                                              <span className="ml-4">${item.fulfillment_status === 'partial' ? item.price * (item.quantity - item.fulfillable_quantity) : item.price * item.quantity}</span>
                                            </Col>
                                          </Row>
                                        </div>
                                        <hr />
                                      </>
                                    ))}
                                  </div>
                                  <hr />
                                </div>}
                              {
                                (orderData?.fulfillments && orderData?.fulfillments.length > 0
                                  // && orderData?.fulfillments?.some(item => item?.status == 'success')
                                ) && (
                                  orderData?.fulfillments?.map((v, i) => (
                                    v?.status === 'success' &&
                                    <>
                                      <div
                                        style={{
                                          marginBottom: '8px',
                                          border: '1px solid rgb(217, 217, 217)',
                                          padding: '16px',
                                          borderRadius: '4px',
                                        }}

                                      >
                                        <div className=" d-flex justify-content-between">
                                          {/* <div className="connection-status-badge-grey firstLetterCapital wfit mb-2">
                                            Fulfilled ({orderData?.line_items && orderData?.line_items?.length})
                                          </div> */}
                                          <div className="d-flex align-item-center">
                                            <div className="connection-status-badge-grey firstLetterCapital wfit mb-2">
                                              {/* Calculate total fulfillable_quantity */}
                                              Fulfilled ({
                                                v?.line_items.reduce((total, item) => total + item.fulfillable_quantity, 0)
                                              }

                                              )
                                            </div>
                                            <span className="d-flex align-item-center mx-2" style={{ height: '26px' }}>
                                              {orderData?.name} -F{i + 1}
                                            </span>
                                          </div>
                                          <Dropdown
                                            // className="ant-btn ant-btn-primary default-btn padb-3 small-btn m-width-100 ml-0 mt-1 mb-1 fw-normal h-30"
                                            className="cancel-fulfillment-btn"
                                            // overlay={fulfillmentMenu}
                                            overlay={<Menu style={{ width: "150px" }}>
                                              <Menu.Item onClick={() => { handleCancelFulfillment(v?.id) }}>
                                                Cancel fulfillment
                                              </Menu.Item>
                                            </Menu>}
                                            trigger={['click']} placement="bottomRight">
                                            <a className="sc-order-items-detail" onClick={(e) => e.stopPropagation()}>
                                              <Space>
                                                <EllipsisOutlined
                                                  style={{ fontSize: '26px' }}
                                                  className="hover-icon mt-2" />
                                              </Space>
                                            </a>
                                          </Dropdown>
                                        </div>
                                        <div className="order-date">
                                          {moment(v?.created_at).format('DD MMMM YYYY')}
                                        </div>
                                        <div>
                                          {v?.tracking_company}
                                        </div>
                                        <div>
                                          {/* <a
                                          // target="_blank"
                                          // href={v?.tracking_url}
                                          > */}
                                          {v?.tracking_numbers}
                                          {/* </a> */}
                                        </div>
                                        <div
                                          className="sc-order-lines-detail"
                                          style={{
                                            marginBottom: '8px',
                                            border: '1px solid rgb(217, 217, 217)',
                                            padding: '16px 0px',
                                            borderRadius: '4px',
                                          }}
                                        >
                                          {v?.line_items?.map((item, index) => (
                                            <>
                                              <div>
                                                <Row style={{ width: "100%", padding: '0 12px' }}>
                                                  <Col span={16}>
                                                    <div><b>{item.name}</b></div>
                                                    {item?.title &&
                                                      <div
                                                        style={{ backgroundColor: '#f0f0f0', width: 'fit-content', borderRadius: '10px' }}
                                                      >
                                                        <span
                                                          style={{ padding: '8px', }}
                                                        >
                                                          {item?.title}
                                                        </span>
                                                      </div>}
                                                    {item?.sku && <span>SKU: {item?.sku}</span>}
                                                  </Col>
                                                  <Col span={8} className="text-right">
                                                    <span className="ml-2">{numeral(
                                                      item.price
                                                    ).format("$0,0.0'")} </span>
                                                    <span className="ml-2">x</span>
                                                    <span className="ml-2">{item.fulfillable_quantity}</span>
                                                    <span className="ml-4">${item.price * item.fulfillable_quantity}</span>
                                                  </Col>
                                                </Row>
                                              </div>
                                              <hr />
                                            </>
                                          ))}
                                        </div>
                                        {
                                          (!v?.tracking_company && !v?.tracking_number) &&
                                          <div className="d-flex justify-content-end">
                                            <Button
                                              // onClick={() => setIsModalOpen(true)}
                                              onClick={() => showTrackingModal(v)}
                                              className="ant-btn ant-btn-primary default-btn padb-3 small-btn m-width-100 ml-0 mt-1 mb-1 fw-normal h-30">
                                              Add Tracking
                                            </Button>
                                          </div>
                                        }
                                      </div>
                                    </>
                                  ))
                                )



                              }
                              {
                                orderData?.refunds?.length > 0 ?
                                  <>
                                    <div className="connection-status-badge-grey firstLetterCapital w-fit mb-1">
                                      Refunded
                                    </div>
                                    <div
                                      className="sc-order-lines-detail"
                                      style={{
                                        marginBottom: '8px',
                                        border: '1px solid rgb(217, 217, 217)',
                                        padding: '16px 0px',
                                        borderRadius: '4px',
                                      }}
                                    >
                                      <div className="paid-mid-row px-2">
                                        <div className="left-txt multi-content">
                                          <div className="left-hd">Original Order</div>
                                          <div className="left-info">
                                            <span className="order-date">
                                              {moment(orderData?.created_at, 'YYYY-MM-DDTHH:mm:ss.SSSZ').calendar(null, {
                                                lastDay: '[Yesterday at] h:mm A',
                                                sameDay: '[Today at] h:mm A',
                                                nextDay: '[Tomorrow at] h:mm A',
                                                lastWeek: 'dddd [at] h:mm A', // Adjust this line
                                                nextWeek: 'dddd [at] h:mm A',
                                                // sameElse: 'DD MMM [at] h:mm A',
                                                sameElse: function (now) {
                                                  if (this.isBefore(now, 'year')) {
                                                    // For dates in the previous year
                                                    return 'MMMM DD, YYYY [at] h:mm A';
                                                  } else {
                                                    // For all other dates
                                                    return 'DD MMM [at] h:mm A';
                                                  }
                                                }
                                              })}
                                            </span>
                                          </div>
                                        </div>
                                        <div className="text-right">
                                          {numeral(
                                            scGetOneOrderDetail?.payload?.order
                                              ?.total_line_items_price
                                          ).format("$0,0.0'")}
                                        </div>
                                      </div>
                                      <hr />
                                      <div className="paid-mid-row px-2">
                                        <div className="left-txt multi-content">
                                          <div className="left-hd">Subtotal</div>
                                          <div className="left-info">
                                            0 items
                                          </div>
                                        </div>
                                        <div className="text-right">
                                          {numeral(0).format("$0,0.0'")}
                                        </div>
                                      </div>
                                      <div className="paid-mid-row px-2">
                                        <div className="left-txt multi-content">
                                          <div className="left-hd">Shipping</div>
                                          <div className="left-info">
                                            {scGetOneOrderDetail?.payload?.order?.shipping_lines?.[0]?.title}
                                          </div>
                                        </div>
                                        <div className="text-right">
                                          {numeral(
                                            scGetOneOrderDetail?.payload?.order
                                              ?.total_shipping_price_set?.presentment_money?.amount
                                          ).format("$0,0.0'")}
                                        </div>
                                      </div>
                                      <div className="paid-mid-row px-2">
                                        <div className="left-txt multi-content">
                                          <div className="left-hd fw-bold">Total</div>
                                        </div>
                                        <div className="text-right fw-bold">
                                          {numeral(0).format("$0,0.0'")}
                                        </div>
                                      </div>
                                      <hr />
                                      <div className="paid-mid-row px-2">
                                        <div className="left-txt multi-content">
                                          <div className="left-hd">Paid</div>
                                        </div>
                                        <div className="text-right">
                                          {numeral(
                                            scGetOneOrderDetail?.payload?.order
                                              ?.total_line_items_price
                                          ).format("$0,0.0'")}
                                        </div>
                                      </div>
                                      <div className="paid-mid-row px-2">
                                        <div className="left-txt multi-content">
                                          <div className="left-hd">Refunded</div>
                                          <div className="left-info min-w-fit">
                                            Reason: {orderData?.refunds?.[0]?.note}
                                          </div>
                                        </div>
                                        <div className="text-right">
                                          -{numeral(
                                            scGetOneOrderDetail?.payload?.order
                                              ?.total_line_items_price
                                          ).format("$0,0.0'")}
                                        </div>
                                      </div>
                                      <div className="paid-mid-row px-2">
                                        <div className="left-txt multi-content">
                                          <div className="left-hd fw-bold">Net Payment</div>
                                        </div>
                                        <div className="text-right fw-bold">
                                          {numeral(0).format("$0,0.0'")}
                                        </div>
                                      </div>
                                    </div>
                                  </>
                                  :
                                  <div>
                                    <div className="paid-mid-row">
                                      <div className="left-txt multi-content">
                                        <div className="left-hd">Subtotal</div>
                                        <div className="left-info">
                                          {
                                            scGetOneOrderDetail?.payload?.order?.line_items
                                              ?.reduce((total, item) => total + item.quantity, 0)
                                          }{" "}
                                          {scGetOneOrderDetail?.payload?.order?.line_items.reduce((total, item) => total + item.quantity, 0) === 1 ? "item" : "items"}
                                        </div>
                                      </div>
                                      <div className="text-right">
                                        {numeral(
                                          scGetOneOrderDetail?.payload?.order
                                            ?.total_line_items_price
                                        ).format("$0,0.0'")}
                                      </div>
                                    </div>

                                    <div className="paid-mid-row">
                                      <div className="left-txt multi-content">
                                        <div className="left-hd">Discount</div>
                                        <div className="left-info">
                                          {scGetOneOrderDetail?.payload?.order
                                            ?.total_discounts
                                            ? numeral(
                                              scGetOneOrderDetail?.payload?.order
                                                ?.total_discounts /
                                              scGetOneOrderDetail?.payload?.order
                                                ?.total_line_items_price
                                            ).format("0%")
                                            : ""}
                                        </div>
                                      </div>
                                      {scGetOneOrderDetail?.payload?.order
                                        ?.total_discounts ? (
                                        <>
                                          <div className="text-right">
                                            -
                                            {numeral(
                                              scGetOneOrderDetail?.payload?.order
                                                ?.total_discounts
                                            ).format("$0,0.0'")}
                                          </div>
                                        </>
                                      ) : (
                                        <div className="text-right">
                                          {numeral(0).format("$0,0.0'")}
                                        </div>
                                      )}
                                    </div>

                                    <div className="paid-mid-row">
                                      <div className="left-txt multi-content">
                                        <div className="left-hd">Shipping</div>
                                        <div className="left-info">
                                          {scGetOneOrderDetail?.payload?.order?.shipping_lines?.[0]?.title} {scGetOneOrderDetail?.payload?.order?.total_weight && <span>({scGetOneOrderDetail?.payload?.order?.total_weight} "Kg")</span>}
                                        </div>
                                      </div>
                                      <div className="text-right">
                                        {numeral(
                                          scGetOneOrderDetail?.payload?.order
                                            ?.total_shipping_price_set?.presentment_money?.amount
                                        ).format("$0,0.0'")}
                                      </div>
                                    </div>

                                    <div className="paid-mid-row">
                                      <div className="left-txt multi-content">
                                        <div className="left-hd">Tax</div>
                                        {/* <div className="left-info ">
                                          {
                                            scGetOneOrderDetail?.payload?.order
                                              ?.tax_lines?.map(item => `${item.title} ${item.rate * 100}%`).join(', ')
                                          }
                                        </div> */}
                                      </div>
                                      <div className="text-right">
                                        {numeral(
                                          scGetOneOrderDetail?.payload?.order?.total_tax
                                        ).format("$0,0.0'")}
                                      </div>
                                    </div>
                                    <div className="paid-mid-row">
                                      <div className="left-txt multi-content">
                                        <div className="left-hd fw-bold">Total Amount</div>
                                      </div>
                                      <div className="text-right fw-bold">
                                        {numeral(
                                          scGetOneOrderDetail?.payload?.order?.total_price
                                        ).format("$0,0.0'")}
                                      </div>
                                    </div>
                                  </div>
                              }

                              <div>
                              </div>
                            </Space>
                          </div>
                        </Col>
                        <Col span={8} className=" pl-20">
                          <div style={{ marginBottom: 8, border: '1px solid #d9d9d9', padding: '16px', borderRadius: '4px' }}>
                            <Space style={{ display: 'flex', marginBottom: 8, flexDirection: 'column', alignItems: 'flex-start' }} align="baseline" className="sc-option-input">
                              <div>
                                <b>Customer</b>
                              </div>
                              <div>
                                {scGetOneOrderDetail?.payload?.order?.customer
                                  ?.first_name +
                                  " " +
                                  scGetOneOrderDetail?.payload?.order?.customer
                                    ?.last_name}
                              </div>
                              <div>
                                <b>Contact Information</b>
                              </div>
                              <div>
                                {scGetOneOrderDetail?.payload?.order?.customer
                                  ?.email}
                              </div>
                              <div>
                                <b>Shipping Address</b>
                              </div>
                              <div>
                                {scGetOneOrderDetail?.payload?.order?.shipping_address
                                  ?.address1 + scGetOneOrderDetail?.payload?.order?.shipping_address
                                    ?.address2}
                              </div>
                              <div>
                                {scGetOneOrderDetail?.payload?.order?.shipping_address
                                  ?.city}, {" "} {scGetOneOrderDetail?.payload?.order?.shipping_address
                                    ?.province} {" "} {scGetOneOrderDetail?.payload?.order?.shipping_address
                                      ?.zip}
                              </div>
                              <div>
                                {scGetOneOrderDetail?.payload?.order?.shipping_address
                                  ?.country}
                              </div>
                              {
                                scGetOneOrderDetail?.payload?.order?.billing_address &&
                                <>
                                  <div>
                                    <b>Billing Address</b>
                                  </div>
                                  {
                                    scGetOneOrderDetail?.payload?.order?.billing_address?.name === scGetOneOrderDetail?.payload?.order?.shipping_address?.name
                                      ?
                                      <div>
                                        Same as shipping address
                                      </div>
                                      :
                                      <>
                                        <div>
                                          {scGetOneOrderDetail?.payload?.order?.billing_address
                                            ?.address1 + scGetOneOrderDetail?.payload?.order?.billing_address
                                              ?.address2}
                                        </div>
                                        <div>
                                          {scGetOneOrderDetail?.payload?.order?.billing_address
                                            ?.city}, {" "} {scGetOneOrderDetail?.payload?.order?.billing_address
                                              ?.province} {" "} {scGetOneOrderDetail?.payload?.order?.billing_address
                                                ?.zip}
                                        </div>
                                        <div>
                                          {scGetOneOrderDetail?.payload?.order?.billing_address
                                            ?.country}
                                        </div>
                                      </>}
                                </>}
                              <div>
                                {/* {allValues && allValues?.options[index]?.values.length > 0 &&
                            allValues?.options[index]?.values.map((item, index) => {
                              return (<Tag bordered={false}>{item}</Tag>)
                            })
                          } */}
                              </div>
                            </Space>
                          </div>
                        </Col>
                      </Row>
                    </>
                    :
                    <div>
                      {scGetOneOrderDetail?.payload?.data?.message}
                    </div>}

                {/* <Form
                  form={scform}
                  layout="vertical"
                  onFinish={handleSubmit}
                  onValuesChange={handleValuesChange}
                  scrollToFirstError={true}
                  initialValues={{ status: 'active', chargeTax: true, trackQuantity: true, weight: 0, price: 0, quantity: 0, compare_at_price: 0, variants: [{ optionName: '', optionValues: [''] }] }}
                >
                  <Row gutter={20}>

                    <Col span={16} className="pr-20">
                      <Form.Item name="title" label="Title" rules={[{ required: true, message: 'Enter product title' }]}>
                        <Input placeholder="Short sleeve t-shirt" onChange={(e) => {
                          setFlag(true);
                        }} />
                      </Form.Item>

                      {
                        // formState === 'edit' ?
                        //   <Form.Item name="description" label="Description">
                        //     <TextArea className="sc-textarea" rows={10} value={description} onChange={setDescription} />
                        //   </Form.Item>
                        //   :
                        <Form.Item name="description" label="Description" className="sc-description">
                          <SunEditor
                            className='sc-sun-editor'
                            setDefaultStyle='font-family: Nunito Sans'
                            setContents={description}
                            onChange={handleDescriptionChange}
                            setOptions={editorOptions}
                          />
                        </Form.Item>}

                      <Form.Item name="media" label="Media" className="sc-media-container">
                        
                        
                       
                        {previewImage && (

                          <Image
                            wrapperStyle={{
                              display: 'none',
                            }}
                            preview={{
                              visible: previewOpen,
                              onVisibleChange: (visible) => setPreviewOpen(visible),
                              afterOpenChange: (visible) => !visible && setPreviewImage(''),
                            }}
                            src={previewImage}
                          />

                        )}
                       
                      </Form.Item>
                     
                      <Row gutter={16}>
                        {
                          (formState === 'add' ||
                            (scGetOneProductDetail.product && scGetOneProductDetail?.product?.variants[0]?.title == "Default Title") || (formState === 'edit' && variantValues.length === 0)) &&
                          <Col span={12}>
                            <Form.Item name="sku" label="SKU (Stock Keeping Unit)"
                            // rules={[{ required: true, message: 'Enter product sku' }]}
                            >
                              <Input
                              // onChange={(e) => {
                              //   setFlag(true);
                              // }} 
                              />
                            </Form.Item>
                          </Col>}
                        {(formState === 'add' ||
                          (scGetOneProductDetail.product && scGetOneProductDetail?.product?.variants[0]?.title == "Default Title") || (formState === 'edit' && variantValues.length === 0)) &&
                          <Col span={12}>
                            <Form.Item name="quantity" label="Quantity" rules={[{ required: true, message: 'Enter quantity' }]}>
                              <InputNumber
                                // defaultValue={0}
                                onChange={(e) => {
                                  setFlag(true);
                                }}
                                min={0} style={{ width: '100%' }} placeholder="0" />
                            </Form.Item>
                          </Col>}



                      


                        {(formState === 'add' ||
                          (scGetOneProductDetail.product && scGetOneProductDetail?.product?.variants[0]?.title == "Default Title") || (formState === 'edit' && variantValues.length === 0)) &&
                          <Col span={12}>
                            <Form.Item name="price" label="Price" rules={[{ required: true, message: 'Enter price' }]}>
                              <InputNumber
                                onChange={(e) => {
                                  setFlag(true);
                                }}
                                // defaultValue={"0.00"}
                                placeholder="0.00"
                                style={{ width: '100%' }}
                                min={0}
                                formatter={(value) => `$ ${value}`}
                                parser={(value) => value.replace('$ ', '')}
                              />
                            </Form.Item>
                          </Col>}
                        {(formState === 'add' ||
                          (scGetOneProductDetail.product && scGetOneProductDetail?.product?.variants[0]?.title == "Default Title") || (formState === 'edit' && variantValues.length === 0))
                          &&
                          <Col span={12}>
                            <Form.Item
                              name="compare_at_price"
                              label={
                                <span>
                                  Compare-at price&nbsp;
                                  <Tooltip title="To display a markdown, enter a value higher than your price. Often shown with a strikethrough.">
                                    <QuestionCircleOutlined />
                                  </Tooltip>
                                </span>
                              }
                            >
                              <InputNumber
                                min={0}
                                formatter={(value) => `$ ${value}`}
                                parser={(value) => value.replace('$ ', '')}
                                style={{ width: '100%' }}
                              />
                            </Form.Item>
                          </Col>}



                        {
                          (formState === 'add' ||
                            (scGetOneProductDetail.product && scGetOneProductDetail?.product?.variants[0]?.title == "Default Title") || (formState === 'edit' && variantValues.length === 0))
                          &&
                          <Col span={12}>
                            <Form.Item name="weight" label="Weight">
                              <InputNumber
                                placeholder="0.00"
                                style={{ width: '100%' }}
                                min={0}
                                // formatter={(value) => `${value} kg`}
                                // parser={(value) => value.replace(' kg', '')}
                                addonAfter={selectWeightUnit}
                              />

                            </Form.Item>
                          </Col>}
                      </Row>
                      {

                        <Form.Item label="Variants">
                          <Form.List name="options">
                            {(fields, { add, remove }) => (
                              <>
                                {fields.map(({ key, name, fieldKey, ...restField }, index) => (
                                  <div key={key} style={{ marginBottom: 8, border: '1px solid #d9d9d9', padding: '16px', borderRadius: '4px' }}>
                                    <Space style={{ display: 'flex', marginBottom: 8 }} align="baseline" className="sc-option-input">
                                      <Form.Item
                                        label="Option Name"
                                        {...restField}
                                        name={[name, 'optionName']}
                                        fieldKey={[fieldKey, 'optionName']}
                                        rules={[{ required: true, message: 'Option name is required' }]}
                                      >
                                        <Input placeholder={`${index === 0 ? "Size" : index === 1 ? "Color" : "Material"}`} onChange={(e) => handleOptionNameChange(e.target.value, index)} />
                                      </Form.Item>
                                      <MinusCircleOutlined onClick={() => remove(name)} />
                                    </Space>
                                    <Form.List name={[name, 'optionValues']}>
                                      {(valueFields, { add: addValue, remove: removeValue }) => (
                                        <>
                                          {valueFields.map(({ key: valueKey, name: valueName, fieldKey: valueFieldKey, ...restValueField }) => (
                                            <Space key={valueKey} style={{ display: 'flex', marginBottom: 8 }} align="baseline" className="sc-optionvalues-input">
                                              <Form.Item
                                                label="Option Values"
                                                {...restValueField}
                                                name={[valueName]}
                                                fieldKey={[valueFieldKey]}
                                              >
                                                <Input placeholder={`${(index === 0 && valueFields.length === 1) ? "Medium" : (index === 1 && valueFields.length === 1) ? "Black" : (index === 2 && valueFields.length === 1) ? "Rubber" : "Add another value"}`}
                                                  onChange={(e) => {
                                                    if (e.target.value && valueFields.length === valueName + 1) {
                                                      addValue();
                                                    }
                                                  }} />
                                              </Form.Item>
                                              <MinusCircleOutlined onClick={() => removeValue(valueName)} />
                                            </Space>
                                          ))}
                                          {valueFields.length === 0 && (
                                            <Form.Item>
                                              <Button type="dashed" onClick={() => addValue()} block icon={<PlusOutlined />}>
                                                Add another value
                                              </Button>
                                            </Form.Item>
                                          )}
                                        </>
                                      )}
                                    </Form.List>
                                  </div>
                                ))}
                                {fields.length < 3 && (
                                  <Form.Item>
                                    <Button type="dashed" onClick={() => add({ optionName: '', optionValues: [''] })} block icon={<PlusOutlined />}>
                                      Add another option
                                    </Button>
                                  </Form.Item>
                                )}
                              </>
                            )}
                          </Form.List>
                        </Form.Item>
                      }
                    </Col>

                    <Col span={8} className="guide-column-sc pl-20">
                      <Form.Item name="status" label="Status">
                        <Select>
                          <Option value="active">Active</Option>
                          <Option value="draft">Draft</Option>
                        </Select>
                      </Form.Item>

                     
                      <Form.Item
                        // name="tags"
                        label="Tags">
                        <Select
                          mode="tags"
                          placeholder="Enter Tags"
                          onChange={handleTagChange}
                          value={tags}
                          // value={tags.length > 0 ? tags : []}
                          tokenSeparators={[', ', ',']} // Defines characters that trigger a separation into tags
                          allowClear
                          size="medium"
                        // dropdownStyle={{ display: 'none' }} // This prevents the dropdown from being visible
                        // dropdownRender={() => null} // This prevents the dropdown from rendering
                        >
                          {tags?.map(tag => (
                            <Option key={tag} value={tag}>{tag}</Option>
                          ))}
                        </Select>
                      </Form.Item>
                      
                    </Col>
                    <Col span={16} className="pr-10">
                      {variantValues.length > 0 &&
                        <Table
                          size="small"
                          className="product-listing-table mb-4"
                          pagination={false}
                          columns={variantsColumn}
                          dataSource={variantValues}
                        />
                        // <Table
                        //   size="small"
                        //   className="product-listing-table"

                        //   columns={variantsColumn2}
                        //   dataSource={transformData}
                        // />
                      }
                    </Col>

                    <Col span={16} className="pr-10">
                      <Form.Item className="sc-add-product-btn">
                        <Button
                          disabled={saveLoading}
                          className="default-btn d-inline-flex align-items-center justify-content-center"
                          loading={saveLoading}
                          type="primary"
                          htmlType="submit"
                        >
                          Save
                        </Button>
                      </Form.Item>
                    </Col>
                  </Row>
                  <Affix offsetTop={0}
                    className="affix-container"
                    // style={{ position: "absolute", top: 60, right: 30 }}
                    onChange={(affixed) => setAffixed(affixed)}
                  >
                    <div className={affixed ? 'affix-background-sc' : 'd-none'}>

                      <Button
                        // onClick={addTOORME}
                        className="default-btn fltr-hpr  medium-btn m-width-100 ml-0 fw-normal"
                        disabled={saveLoading}
                        loading={saveLoading}
                        type="primary"
                        htmlType="submit"
                      // disabled={loading || skuLoad}
                      >
                        Save
                      </Button>
                    </div>
                  </Affix>



                </Form> */}

              </div>


            </div >
        }
      </>


      <Modal
        className="modal-generic modal-500 form-styling"
        centered
        visible={isCheckedOpen}
        onOk={OpenSubmit}
        onCancel={handleCancelInfo}
        closeIcon={<FontAwesomeIcon icon={faXmark} />}
        maskClosable={false}
        keyboard={false}
        footer={[
          // <Button key="back" onClick={handleCancel}>
          //   Cancel
          // </Button>,
          <Button
            key="submit"
            type="primary"
            // className="d-table m-auto"
            onClick={handleOkInfo}
          >
            Save
          </Button>,
          // <Button key="back" onClick={handleCancelInfo}>
          //   Exit
          // </Button>,
        ]}
      >
        <h2 className="modal-hd1">Add Info</h2>
        {/* <p>Please note these email addresses must be registered on ormelive.com as a Shopper.
            By submitting this, the role will have an access to control room chat.
          </p> */}
        <h5 className="rounded">Title</h5>
        <Input
          placeholder="Enter Title"
          className="mb-20"
          value={infoTitle}
          label="Title"
          size="large"
          onChange={onChangeTitle}
        />
        <h5 className="rounded">Description</h5>
        <TextArea
          value={infoDesc}
          label="Description"
          size="large"
          placeholder="Description....."
          onChange={onChangeDescription}
        />
      </Modal>
      {/* fulfill Modal */}
      <Modal
        maskClosable={false}
        className="modal-generic form-new modal-800 form-styling"
        centered
        // visible={false}
        // visible={true}
        visible={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}

        closeIcon={<FontAwesomeIcon icon={""} />}
        footer={[
          <Button key="back" onClick={handleCancel}>
            Cancel
          </Button>,
          <Button
            className={`default-btn`}
            key="submit"
            type="button"
            // type="primary"
            onClick={handleOk}
            loading={saveLoading}
            // disabled={saveLoading}
            disabled={!selectedRecord?.some(item => item.fulfillable_quantity > 0)}
          // onClick={() =>
          //   history(published ? "/shows?type=Published" : "/shows")
          // }
          >
            Save
          </Button>,
        ]}
      >
        <h2 className="modal-hd1">Fulfill item</h2>
        <Row gutter={20}>
          <Col span={16} className="pr-10 code-activated-popup">
            <div style={{ marginBottom: 8, border: '1px solid #d9d9d9', padding: '16px', borderRadius: '4px' }}>

              <div className="d-flex align-items-center mb-2">
                {
                  orderData?.fulfillments && orderData?.fulfillments.length === 0 ?
                    <div className="connection-status-badge-yellow firstLetterCapital wfit mr-2">
                      Unfulfilled
                    </div>
                    : orderData?.fulfillments && orderData?.fulfillments.length > 0 ?
                      <div className="connection-status-badge-green firstLetterCapital wfit mr-2">
                        Fulfilled
                      </div>
                      : "-"
                }
                <div className="order-name">
                  {
                    orderData
                      ?.name
                  }
                </div>
              </div>
              <div
                className="sc-order-lines-detail"
                style={{
                  marginBottom: '8px',
                  border: '1px solid rgb(217, 217, 217)',
                  padding: '16px 0px',
                  borderRadius: '4px',
                }}
              >

                {orderData?.line_items?.map((item, index) => (
                  item?.fulfillment_status !== 'fulfilled' &&
                  <>
                    <div>
                      <Row style={{ width: "100%", padding: '0 12px' }}>
                        <Col span={18}>
                          <div><b>{item.name}</b></div>
                          {item?.title &&
                            <div
                              style={{ backgroundColor: '#f0f0f0', width: 'fit-content', borderRadius: '10px' }}
                            >
                              <span
                                style={{ padding: '8px', }}
                              >
                                {item?.title}
                              </span>
                            </div>}
                          {item?.sku && <span>SKU: {item?.sku}</span>}
                        </Col>
                        {/* <Col span={6} className="text-right ">
                          <span className="mt-1">{item?.weight || 0} kg</span>
                          <InputNumber
                            // readOnly
                            defaultValue={item?.quantity}
                            value={selectedRecord?.[index]?.quantity}
                            // prefix="of 1"
                            min={0}
                            max={item?.quantity}
                            placeholder="0"
                            style={{ width: '100%' }}
                            onChange={(value) => handleQuantityChange(value, index)}
                          // formatter={value => `${value} of 1`}  // Adds the suffix
                          // parser={value => value.replace(' of 1', '')}  // Removes the suffix when parsing back the value
                          />

                        </Col> */}
                        <Col span={6} className="text-right d-flex align-items-center">
                          <span className="mt-1 mr-1">{item?.weight || 0} kg</span>
                          <div style={{ display: "flex", alignItems: "center", marginTop: "8px" }}>
                            <InputNumber
                              defaultValue={0}
                              value={selectedRecord?.[index]?.fulfillable_quantity || 0}
                              min={0}
                              max={item.fulfillment_status === 'partial' ? item.quantity - item.fulfillable_quantity : item.quantity}
                              style={{ width: 50, textAlign: "center", marginRight: "8px" }}
                              onChange={(value) => handleQuantityChange(value, index)}
                            />
                            <span>of {item.fulfillment_status === 'partial' ? item.quantity - item.fulfillable_quantity : item.quantity}</span>

                          </div>
                        </Col>
                      </Row>
                    </div>
                    <hr />
                  </>
                ))}

              </div>
              <div className="mb-2">
                <b>Tracking Information</b>
              </div>
              <Alert
                className="mb-2"
                message="Add tracking to improve customer satisfaction"
                description="Orders with tracking let customers receive delivery updates and reduce support requests."
                type="info"
                showIcon
                closable
              />
              <Row style={{ width: "100%", justifyContent: 'space-between' }}>
                <Col span={12} >
                  <Form.Item label="Tracking Number" className="sc-product-variant-view mr-2">
                    <Input
                      // defaultValue={formState === "edit" ? selectedRecord?.quantity ? selectedRecord?.quantity : allValues.quantity : allValues.quantity ? allValues.quantity : 0}
                      // value={selectedRecord?.quantity ? selectedRecord?.quantity : allValues.quantity}
                      // value={variantValues[selectedRecord?.position - 1]?.quantity}
                      // min={0}
                      // max={allValues.quantity}
                      placeholder="Enter Tracking Number"
                      value={trackingNo}
                      style={{ width: '100%' }}
                      onChange={(e) => setTrackingNo(e.target.value)}
                    // onChange={(e) => handleTableChange(e, selectedRecord?.position, 'quantity')}
                    />
                  </Form.Item>
                </Col>
                <Col span={12} >
                  <Form.Item label="Shipping Career" className="sc-product-variant-view ml-2">
                    <Input
                      placeholder="Enter Shipping Career"
                      value={shippingCareer}
                      style={{ width: '100%' }}
                      onChange={(e) => setShippingCareer(e.target.value)}
                    />
                  </Form.Item>
                </Col>
              </Row>
              <Row style={{ width: "100%", justifyContent: 'space-between' }}>
                <Col span={24} >
                  <Form.Item label="Tracking Url" className="sc-product-variant-view"
                  >
                    <Input
                      placeholder="Enter Tracking URL"
                      value={trackingUrl}
                      style={{ width: '100%' }}
                      onChange={
                        // (e) => setTrackingUrl(e.target.value)
                        handleUrlChange
                      }
                    />
                    {isValid === null ? (
                      <p></p>
                    ) : isValid ? (
                      <p></p>
                    ) : (
                      <p style={{ color: 'red' }}>Invalid URL</p>
                    )}
                  </Form.Item>
                </Col>
              </Row>
              {/* <hr />
              <div className="mb-2">
                <b>Notify customer to shipment</b>
              </div> */}
            </div>
          </Col>
          <Col span={8}>
            <div style={{ marginBottom: 8, border: '1px solid #d9d9d9', padding: '16px', borderRadius: '4px' }}>
              <Space style={{ display: 'flex', marginBottom: 8, flexDirection: 'column', alignItems: 'flex-start' }} align="baseline" className="sc-option-input">
                <div>
                  <b>Shipping Address</b>
                </div>
                <div>
                  {scGetOneOrderDetail?.payload?.order?.shipping_address
                    ?.address1 + scGetOneOrderDetail?.payload?.order?.shipping_address
                      ?.address2}
                </div>
                <div>
                  {scGetOneOrderDetail?.payload?.order?.shipping_address
                    ?.city}, {" "} {scGetOneOrderDetail?.payload?.order?.shipping_address
                      ?.province} {" "} {scGetOneOrderDetail?.payload?.order?.shipping_address
                        ?.zip}
                </div>
                <div>
                  {scGetOneOrderDetail?.payload?.order?.shipping_address
                    ?.country}
                </div>
                <div>
                  The customer selected <b>{scGetOneOrderDetail?.payload?.order?.shipping_lines?.[0]?.title}</b> at checkout.
                </div>
              </Space>
            </div>
          </Col>
        </Row>




      </Modal>
      {/* Cancel Modal */}
      <Modal
        maskClosable={false}
        className="modal-generic form-new modal-800 form-styling"
        centered
        // visible={false}
        // visible={true}
        visible={isCancelModalOpen}
        onOk={handleCancelOrder}
        onCancel={handleCancel}

        closeIcon={<FontAwesomeIcon icon={""} />}
        footer={[
          <Button key="back" onClick={handleCancel}>
            Keep Order
          </Button>,
          <Button
            key="submit"
            type="primary"
            onClick={handleCancelOrder}
            loading={saveLoading}
            disabled={saveLoading}
          // disabled={true}
          // onClick={() =>
          //   history(published ? "/shows?type=Published" : "/shows")
          // }
          >
            Cancel Order
          </Button>,
        ]}
      >
        <h2 className="modal-hd1">Cancel Order {orderData?.name}?</h2>
        <Row gutter={20}>
          <Col span={24} className="pr-10 code-activated-popup">
            <div style={{ marginBottom: 8, border: '1px solid #d9d9d9', padding: '16px', borderRadius: '4px' }}>

              {/* <div className="d-flex mb-2 flex-column justify-content-start">
                <div className="order-name">
                  <b>Refund payments</b>
                </div>
                <div>
                  <Radio.Group onChange={handleOptionChange} value={refundOption} style={{ width: '100%' }}>
                    <Card
                      // hoverable
                      style={{
                        marginBottom: 16,
                        borderColor: refundOption === 'now' ? '#052977' : '#f0f0f0',
                      }}
                      bodyStyle={{ padding: 12 }}
                    >
                      <Radio value="now" style={{ width: '100%' }}>
                        <div>
                          <Text strong>Now</Text>
                          <div>
                            Refund {numeral(
                              scGetOneOrderDetail?.payload?.order?.total_price
                            ).format("$0,0.0'")}
                          </div>
                        </div>
                      </Radio>
                    </Card>

                    <Card
                      // hoverable
                      style={{
                        borderColor: refundOption === 'later' ? '#052977' : '#f0f0f0',
                      }}
                      bodyStyle={{ padding: 12 }}
                    >
                      <Radio value="later" style={{ width: '100%' }}>
                        <div>
                          <Text strong>Later</Text>
                          <div style={{ color: '#8c8c8c' }}>
                            Your customer won’t be refunded. Refund the amount owed at a later point.
                          </div>
                        </div>
                      </Radio>
                    </Card>
                  </Radio.Group>
                </div>
              </div> */}
              {/* <div
                className="sc-order-lines-detail"
                style={{
                  marginBottom: '8px',
                  border: '1px solid rgb(217, 217, 217)',
                  padding: '16px 0px',
                  borderRadius: '4px',
                }}
              >

                {orderData?.line_items?.map((item, index) => (
                  <>
                    <div>
                      <Row style={{ width: "100%", padding: '0 12px' }}>
                        <Col span={18}>
                          <div><b>{item.name}</b></div>
                          {item?.title &&
                            <div
                              style={{ backgroundColor: '#f0f0f0', width: 'fit-content', borderRadius: '10px' }}
                            >
                              <span
                                style={{ padding: '8px', }}
                              >
                                {item?.title}
                              </span>
                            </div>}
                          {item?.sku && <span>SKU: {item?.sku}</span>}
                        </Col>
                        <Col span={6} className="text-right">
                          <span>{item?.weight}</span>
                          <InputNumber
                            readOnly
                            defaultValue={item?.quantity}
                            // value={selectedRecord?.quantity ? selectedRecord?.quantity : allValues.quantity}
                            // value={variantValues[selectedRecord?.position - 1]?.quantity}
                            addonAfter={`of ${item?.quantity}`}
                            min={0}
                            max={item?.quantity}
                            placeholder="0"
                            style={{ width: '100%' }}
                          // onChange={(e) => handleTableChange(e, selectedRecord?.position, 'quantity')}
                          />
                        </Col>
                      </Row>
                    </div>
                    <hr />
                  </>
                ))}

              </div>
              <div className="mb-2">
                <b>Tracking Information</b>
              </div>
              <Alert
                className="mb-2"
                message="Add tracking to improve customer satisfaction"
                description="Orders with tracking let customers receive delivery updates and reduce support requests."
                type="info"
                showIcon
                closable
              /> */}
              <Row style={{ width: "100%", justifyContent: 'space-between' }}>
                <Col span={24} >
                  <Form.Item label="Reason for cancellation" className="sc-product-variant-view">
                    <Select
                      className="w-100 select-style dark-field"
                      placeholder="Select Reason for cancellation"
                      size="medium"
                      focus={false}
                      loading={loading}
                      disabled={loading}
                      onBlur={false}
                      value={cancelReason}
                      onChange={(e) => setCancelReason(e)}
                      defaultValue={"customer"}
                    >
                      <Option value="customer">Customer changed or canceled order </Option>
                      <Option value="declined">Payment declined</Option>
                      <Option value="fraud">Fradulent order</Option>
                      <Option value="inventory">Items unavailable</Option>
                      <Option value="staff">Staff error</Option>
                      <Option value="other">Other</Option>
                    </Select>
                  </Form.Item>
                </Col>
                {/* <Col span={24} >
                  <Form.Item label="Staff Note" className="sc-product-variant-view mb-0">
                    <Input
                      placeholder="Enter Staff Note"
                      value={staffNote}
                      style={{ width: '100%' }}
                      onChange={(e) => setStaffNote(e.target.value)}
                    />
                  </Form.Item>
                  <p style={{ color: 'grey', marginBottom: '0px' }}>Only you and other staff can see this note.</p>
                </Col> */}
              </Row>
              {/* <Row style={{ width: "100%", justifyContent: 'space-between' }}>
                <Col span={24} >
                  <Form.Item label="Tracking Url" className="sc-product-variant-view"
                  >
                    <Input
                      placeholder="Enter Tracking URL"
                      value={trackingUrl}
                      style={{ width: '100%' }}
                      onChange={
                        // (e) => setTrackingUrl(e.target.value)
                        handleUrlChange
                      }
                    />
                    {isValid === null ? (
                      <p></p>
                    ) : isValid ? (
                      <p></p>
                    ) : (
                      <p style={{ color: 'red' }}>Invalid URL</p>
                    )}
                  </Form.Item>
                </Col>
              </Row> */}
              {/* <hr />
              <div className="mb-2">
                <b>Notify customer to shipment</b>
              </div> */}
            </div>
          </Col>
          {/* <Col span={8}>
            <div style={{ marginBottom: 8, border: '1px solid #d9d9d9', padding: '16px', borderRadius: '4px' }}>
              <Space style={{ display: 'flex', marginBottom: 8, flexDirection: 'column', alignItems: 'flex-start' }} align="baseline" className="sc-option-input">
                <div>
                  <b>Shipping Address</b>
                </div>
                <div>
                  {scGetOneOrderDetail?.payload?.order?.shipping_address
                    ?.address1 + scGetOneOrderDetail?.payload?.order?.shipping_address
                      ?.address2}
                </div>
                <div>
                  {scGetOneOrderDetail?.payload?.order?.shipping_address
                    ?.city}, {" "} {scGetOneOrderDetail?.payload?.order?.shipping_address
                      ?.province} {" "} {scGetOneOrderDetail?.payload?.order?.shipping_address
                        ?.zip}
                </div>
                <div>
                  {scGetOneOrderDetail?.payload?.order?.shipping_address
                    ?.country}
                </div>
              </Space>
            </div>
          </Col> */}
        </Row>
      </Modal>

      <Modal
        zIndex={999999}
        maskClosable={false}
        className="modal-generic modal-600"
        centered
        visible={isModalOpenDetail}
        onOk={handleOkDetail}
        onCancel={handleCancelDetail}
        closeIcon={<FontAwesomeIcon icon={faXmark} />}
        // footer={false}
        footer={[
          <Button
            key="back"
            onClick={handleCancelDetail}
            className="d-table m-auto"
          >
            Exit
          </Button>,
          // <Button key="submit" type="primary"
          //   className="d-table m-auto"
          //   // onClick={handleOk}
          //   // onClick={handleCancel}
          // >
          //   Submit
          // </Button>,
        ]}
      >
        <h2 className="modal-hd1">Details</h2>
        <div className="co">
          {/* <Table
            pagination={false}
            columns={columnDetails}
            size="small"
            className="product-listing-tabl inventory-box-main details-table"
            //  rowSelection={{ ...rowSelection }}
            dataSource={dataDetails}
            // scroll={{
            //     x: 500,
            //     y: 500,
            // }}
            scroll={dataDetails?.length > 10 ? { y: 400, x: 500 } : { x: 500 }}
          /> */}
          <div className="table-container inventory-table">
            <table
              class={`table m-0 ${dataDetails?.productOpt?.length &&
                dataDetails?.productOpt?.length + 2 == 5
                ? "width-item-5"
                : ""
                } ${dataDetails?.productOpt?.length &&
                  dataDetails?.productOpt?.length + 2 == 6
                  ? "width-item-6"
                  : ""
                }`}
            >
              <thead>
                <tr>
                  {dataDetails?.productOpt?.map((item, i) => {
                    return (
                      <th className="width-25 border-bottom-0">{item.name}</th>
                    );
                  })}
                  <th className="width-25 border-bottom-0">Stock</th>
                  <th className="width-25 border-bottom-0">Price</th>
                </tr>
              </thead>
            </table>
            <div className="table-body scrollbar-style">
              <table
                class={`table ${dataDetails?.productOpt?.length &&
                  dataDetails?.productOpt?.length + 2 == 5
                  ? "width-item-5"
                  : ""
                  } ${dataDetails?.productOpt?.length &&
                    dataDetails?.productOpt?.length + 2 == 6
                    ? "width-item-6"
                    : ""
                  }`}
              >
                <tbody>
                  {dataDetails?.variants?.map((item, i) => {
                    return (
                      <tr>
                        <td className="width-25">{item.option1}</td>
                        {item.option2 && (
                          <td className="width-25">{item.option2}</td>
                        )}
                        {item.option3 && (
                          <td className="width-25">{item.option3}</td>
                        )}
                        <td className="width-25">{item.inventory_quantity}</td>
                        <td className="width-25">${item.price}</td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </Modal>

      {/* Only Tracking Modal  */}
      <Modal
        maskClosable={false}
        className="modal-generic form-new modal-800 form-styling"
        centered
        // visible={false}
        // visible={true}
        visible={isTrackingModalOpen}
        // onOk={handleAddTracking}
        onCancel={handleCancel}

        closeIcon={<FontAwesomeIcon icon={""} />}
        footer={[
          <Button key="back" onClick={handleCancel}>
            Cancel
          </Button>,
          <Button
            className={`default-btn`}
            key="submit"
            type="button"
            // type="primary"
            onClick={handleAddTracking}
            loading={saveLoading}
            disabled={saveLoading}
          // disabled={!selectedRecord?.some(item => item.fulfillable_quantity > 0)}
          // onClick={() =>
          //   history(published ? "/shows?type=Published" : "/shows")
          // }
          >
            Save
          </Button>,
        ]}
      >
        <h2 className="modal-hd1">Add Tracking</h2>
        <Row gutter={20}>
          <Col span={24} className="pr-10 code-activated-popup">
            <div style={{ marginBottom: 8, border: '1px solid #d9d9d9', padding: '16px', borderRadius: '4px' }}>

              <div className="mb-2">
                <b>Tracking Information</b>
              </div>
              <Alert
                className="mb-2"
                message="Add tracking to improve customer satisfaction"
                description="Orders with tracking let customers receive delivery updates and reduce support requests."
                type="info"
                showIcon
                closable
              />
              <Row style={{ width: "100%", justifyContent: 'space-between' }}>
                <Col span={12} >
                  <Form.Item label="Tracking Number" className="sc-product-variant-view mr-2">
                    <Input
                      // defaultValue={formState === "edit" ? selectedRecord?.quantity ? selectedRecord?.quantity : allValues.quantity : allValues.quantity ? allValues.quantity : 0}
                      // value={selectedRecord?.quantity ? selectedRecord?.quantity : allValues.quantity}
                      // value={variantValues[selectedRecord?.position - 1]?.quantity}
                      // min={0}
                      // max={allValues.quantity}
                      placeholder="Enter Tracking Number"
                      value={trackingNo}
                      style={{ width: '100%' }}
                      onChange={(e) => setTrackingNo(e.target.value)}
                    // onChange={(e) => handleTableChange(e, selectedRecord?.position, 'quantity')}
                    />
                  </Form.Item>
                </Col>
                <Col span={12} >
                  <Form.Item label="Shipping Career" className="sc-product-variant-view ml-2">
                    <Input
                      placeholder="Enter Shipping Career"
                      value={shippingCareer}
                      style={{ width: '100%' }}
                      onChange={(e) => setShippingCareer(e.target.value)}
                    />
                  </Form.Item>
                </Col>
              </Row>
              <Row style={{ width: "100%", justifyContent: 'space-between' }}>
                <Col span={24} >
                  <Form.Item label="Tracking Url" className="sc-product-variant-view"
                  >
                    <Input
                      placeholder="Enter Tracking URL"
                      value={trackingUrl}
                      style={{ width: '100%' }}
                      onChange={
                        // (e) => setTrackingUrl(e.target.value)
                        handleUrlChange
                      }
                    />
                    {isValid === null ? (
                      <p></p>
                    ) : isValid ? (
                      <p></p>
                    ) : (
                      <p style={{ color: 'red' }}>Invalid URL</p>
                    )}
                  </Form.Item>
                </Col>
              </Row>
              {/* <hr />
              <div className="mb-2">
                <b>Notify customer to shipment</b>
              </div> */}
            </div>
          </Col>
          {/* <Col span={8}>
            <div style={{ marginBottom: 8, border: '1px solid #d9d9d9', padding: '16px', borderRadius: '4px' }}>
              <Space style={{ display: 'flex', marginBottom: 8, flexDirection: 'column', alignItems: 'flex-start' }} align="baseline" className="sc-option-input">
                <div>
                  <b>Shipping Address</b>
                </div>
                <div>
                  {scGetOneOrderDetail?.payload?.order?.shipping_address
                    ?.address1 + scGetOneOrderDetail?.payload?.order?.shipping_address
                      ?.address2}
                </div>
                <div>
                  {scGetOneOrderDetail?.payload?.order?.shipping_address
                    ?.city}, {" "} {scGetOneOrderDetail?.payload?.order?.shipping_address
                      ?.province} {" "} {scGetOneOrderDetail?.payload?.order?.shipping_address
                        ?.zip}
                </div>
                <div>
                  {scGetOneOrderDetail?.payload?.order?.shipping_address
                    ?.country}
                </div>
                <div>
                  The customer selected <b>{scGetOneOrderDetail?.payload?.order?.shipping_lines?.[0]?.title}</b> at checkout.
                </div>
              </Space>
            </div>
          </Col> */}
        </Row>




      </Modal>

      {/* Refund Modal */}
      <Modal
        maskClosable={false}
        className="modal-generic form-new modal-800 form-styling"
        centered
        // visible={false}
        // visible={true}
        visible={isRefundModalOpen}
        onOk={handleRefund}
        onCancel={handleCancel}

        closeIcon={<FontAwesomeIcon icon={""} />}
        footer={[
          <Button key="back" onClick={handleCancel}>
            Cancel
          </Button>,
          <Button
            key="submit"
            type="primary"
            onClick={handleRefund}
            loading={saveLoading}
            disabled={saveLoading}
          // onClick={() =>
          //   history(published ? "/shows?type=Published" : "/shows")
          // }
          >
            Refund
          </Button>,
        ]}
      >
        <h2 className="modal-hd1">Refund item</h2>
        <Row gutter={20}>
          <Col span={16} className="pr-10 code-activated-popup">
            <div style={{ marginBottom: 8, border: '1px solid #d9d9d9', padding: '16px', borderRadius: '4px' }}>

              <div className="d-flex align-items-center mb-2">
                {
                  orderData?.fulfillments && orderData?.fulfillments.length === 0 ?
                    <div className="connection-status-badge-yellow firstLetterCapital wfit mr-2">
                      Unfulfilled
                    </div>
                    : orderData?.fulfillments && orderData?.fulfillments.length > 0 ?
                      <div className="connection-status-badge-green firstLetterCapital wfit mr-2">
                        Fulfilled
                      </div>
                      : "-"
                }
                <div className="order-name">
                  {
                    orderData
                      ?.name
                  }
                </div>
              </div>
              <div
                className="sc-order-lines-detail"
                style={{
                  marginBottom: '8px',
                  border: '1px solid rgb(217, 217, 217)',
                  padding: '16px 0px',
                  borderRadius: '4px',
                }}
              >

                {orderData?.line_items?.map((item, index) => (
                  item?.fulfillment_status !== 'fulfilled' &&
                  <>
                    <div>
                      <Row style={{ width: "100%", padding: '0 12px' }}>
                        <Col span={18}>
                          <div><b>{item.name}</b></div>
                          {item?.title &&
                            <div
                              style={{ backgroundColor: '#f0f0f0', width: 'fit-content', borderRadius: '10px' }}
                            >
                              <span
                                style={{ padding: '8px', }}
                              >
                                {item?.title}
                              </span>
                            </div>}
                          {item?.sku && <span>SKU: {item?.sku}</span>}
                        </Col>
                        <Col span={6} className="text-right d-flex align-items-center">
                          <span className="mt-1 mr-1">{item?.weight || 0} kg</span>
                          <div style={{ display: "flex", alignItems: "center", marginTop: "8px" }}>
                            <InputNumber
                              // readOnly
                              // defaultValue={0}
                              // value={item?.quantity}
                              value={selectedRecord?.[index]?.fulfillable_quantity || 0}
                              min={0}
                              // max={item?.quantity}
                              max={item.fulfillment_status === 'partial' ? item.quantity - item.fulfillable_quantity : item.quantity}

                              style={{ width: 50, textAlign: "center", marginRight: "8px" }}
                              onChange={(value) => handleQuantityChange(value, index)}
                            />
                            <span>of {item.fulfillment_status === 'partial' ? item.quantity - item.fulfillable_quantity : item.quantity}</span>


                          </div>
                        </Col>
                      </Row>
                    </div>
                    <hr />
                  </>
                ))}

              </div>
              <div className="mb-2">
                Refunded items will be removed from the order
              </div>
              <hr />
              {/* <Checkbox
                checked={isReStock}
                onChange={() => setIsReStock(!isReStock)}
              >
                Restock item
              </Checkbox> */}

              <div className="mb-2">
                Reason for refund
              </div>

              <Input
                placeholder="Enter Reason"
                value={refundReason}
                style={{ width: '100%' }}
                onChange={(e) => setRefundReason(e.target.value)}
              />

            </div>
          </Col>
          <Col span={8}>
            <div style={{ marginBottom: 8, border: '1px solid #d9d9d9', padding: '16px', borderRadius: '4px' }}>
              <Space style={{ display: 'flex', marginBottom: 8, flexDirection: 'column', alignItems: 'flex-start', width: '100%' }} align="baseline" className="sc-option-input-refund">
                <div>
                  <b>Summary</b>
                </div>
                <div className="d-flex justify-content-between w-100">
                  <span>
                    Item subtotal ({orderData?.line_items?.length})
                  </span>
                  <span>
                    $ {orderData?.total_price}
                  </span>
                </div>
                <hr className="m-0" />
                <div className="d-flex justify-content-between w-100">
                  <span>
                    <b> Refund total</b>
                  </span>
                  <span>
                    <b> $ {orderData?.total_price}</b>
                  </span>
                </div>
                <hr className="m-0" />
                <b> Refund amount</b>
                <Input
                  placeholder=""
                  value={refundAmount}
                  style={{ width: '100%' }}
                  readOnly
                  onChange={(e) => setRefundAmount(e.target.value)}
                />
              </Space>
            </div>
          </Col>
        </Row>
      </Modal>

      {/* Return Modal */}
      <Modal
        maskClosable={false}
        className="modal-generic form-new modal-800 form-styling"
        centered
        // visible={false}
        // visible={true}
        visible={isReturnModalOpen}
        onOk={handleReturn}
        onCancel={handleCancel}

        closeIcon={<FontAwesomeIcon icon={""} />}
        footer={[
          <Button key="back" onClick={handleCancel}>
            Cancel
          </Button>,
          <Button
            key="submit"
            type="primary"
            onClick={handleReturn}
            loading={saveLoading}
            disabled={saveLoading}
          // onClick={() =>
          //   history(published ? "/shows?type=Published" : "/shows")
          // }
          >
            Return
          </Button>,
        ]}
      >
        <h2 className="modal-hd1">Return item</h2>
        <Row gutter={20}>
          <Col span={16} className="pr-10 code-activated-popup">
            <div style={{ marginBottom: 8, border: '1px solid #d9d9d9', padding: '16px', borderRadius: '4px' }}>

              <div className="d-flex align-items-center mb-2">
                {
                  orderData?.fulfillments && orderData?.fulfillments.length === 0 ?
                    <div className="connection-status-badge-yellow firstLetterCapital wfit mr-2">
                      Unfulfilled
                    </div>
                    : orderData?.fulfillments && orderData?.fulfillments.length > 0 ?
                      <div className="connection-status-badge-green firstLetterCapital wfit mr-2">
                        Fulfilled
                      </div>
                      : "-"
                }
                <div className="order-name">
                  {
                    orderData
                      ?.name
                  }
                </div>
              </div>
              <div
                className="sc-order-lines-detail"
                style={{
                  marginBottom: '8px',
                  border: '1px solid rgb(217, 217, 217)',
                  padding: '16px 0px',
                  borderRadius: '4px',
                }}
              >

                {orderData?.line_items?.map((item, index) => (
                  <>
                    <div>
                      <Row style={{ width: "100%", padding: '0 12px' }}>
                        <Col span={18}>
                          <div><b>{item.name}</b></div>
                          {item?.title &&
                            <div
                              style={{ backgroundColor: '#f0f0f0', width: 'fit-content', borderRadius: '10px' }}
                            >
                              <span
                                style={{ padding: '8px', }}
                              >
                                {item?.title}
                              </span>
                            </div>}
                          {item?.sku && <span>SKU: {item?.sku}</span>}
                        </Col>
                        <Col span={5} className="text-right ml-2">
                          <span>{item?.weight}</span>
                          <InputNumber
                            readOnly
                            defaultValue={item?.quantity}
                            // value={selectedRecord?.quantity ? selectedRecord?.quantity : allValues.quantity}
                            // value={variantValues[selectedRecord?.position - 1]?.quantity}
                            addonAfter={`of ${item?.quantity}`}
                            min={0}
                            max={item?.quantity}
                            placeholder="0"
                            style={{ width: '100%' }}
                          // onChange={(e) => handleTableChange(e, selectedRecord?.position, 'quantity')}
                          />
                        </Col>
                      </Row>
                      <div style={{ width: "100%", padding: '0 12px' }}>
                        <span>{numeral(
                          item.price / item.quantity
                        ).format("$0,0.0'")} </span>
                        <span className="ml-2">x</span>
                        <span className="ml-2">{item.quantity}</span>
                        {/* <span className="ml-4">${item.price}</span> */}
                      </div>
                      <div style={{ width: "100%", padding: '0 12px' }}>
                        <div className="mb-2">
                          Reason for return
                        </div>

                        <Input
                          placeholder="Enter Reason"
                          value={selectedRecord?.[index]?.reason}
                          style={{ width: '100%' }}
                          // onChange={(e) => setRefundReason(e.target.value)}
                          onChange={(e) => handleReturnReason(e.target.value, index)}
                        />
                      </div>
                    </div>
                    <hr />
                  </>
                ))}

              </div>
            </div>
          </Col>
          <Col span={8}>
            <div style={{ marginBottom: 8, border: '1px solid #d9d9d9', padding: '16px', borderRadius: '4px' }}>
              <Space style={{ display: 'flex', marginBottom: 8, flexDirection: 'column', alignItems: 'flex-start', width: '100%' }} align="baseline" className="sc-option-input-refund">
                <div>
                  <b>Summary</b>
                </div>
                <div className="d-flex justify-content-between w-100">
                  <span>
                    Return item ({orderData?.line_items?.length})
                  </span>
                  <span>
                    -${orderData?.total_price}
                  </span>
                </div>
                <div className="d-flex justify-content-between w-100">
                  <span>
                    Return shipping
                  </span>
                  <span>
                    <b>-</b>
                  </span>
                </div>
                <div className="d-flex justify-content-between w-100">
                  <span>
                    Restocking fee
                  </span>
                  <span>
                    <b>-</b>
                  </span>
                </div>
                <hr className="m-0" />
                <div className="d-flex justify-content-between w-100">
                  <span>
                    <b> Expected refund</b>
                  </span>
                  <span>
                    <b>${orderData?.total_price}</b>
                  </span>
                </div>
                <Alert
                  className="mb-2"
                  message="No refund will be issued at this time"
                  // description="Orders with tracking let customers receive delivery updates and reduce support requests."
                  type="info"
                  showIcon
                  closable
                />
                {/* <Input
                  placeholder=""
                  value={refundAmount}
                  style={{ width: '100%' }}
                  readOnly
                  onChange={(e) => setRefundAmount(e.target.value)}
                /> */}
              </Space>
            </div>
          </Col>
        </Row>
      </Modal>

      <ErrorBoundary FallbackComponent={ErrorFallback}>
        <ReactRouterPrompt
          // BlockerFunction={(currentLocation, nextLocation, historyAction)}
          when={
            ((scform.getFieldValue().title || fileUrl.length > 0 || scform.getFieldValue().sku)
              &&
              promptModal &&
              (formState === "add") ||
              (formState === "edit" && flag && promptModal && true) ||
              (formState === "edit" && variantValueChange)
            )

          }
        >
          {({ isActive, onConfirm, onCancel }) =>
            isActive && (
              <Modal
                maskClosable={false}
                className="modal-generic modal-500"
                centered
                visible={isActive}
                onOk={() => {
                  onConfirm();
                }}
                onCancel={onCancel}
                closeIcon={<FontAwesomeIcon icon={faXmark} />}
                footer={[
                  <Button type="primary" onClick={onCancel}>
                    Cancel
                  </Button>,
                  <Button
                    key="submit"
                    type="primary"
                    // onClick={handleOk}
                    onClick={() => {
                      onConfirm();
                    }}
                  >
                    Ok
                  </Button>,
                ]}
              >
                <h2 className="modal-hd1">Warning</h2>
                <div className="my-4">
                  <h5>By leaving this page you will lose all your changes.</h5>
                  <h5>Would you like to continue?</h5>
                </div>
              </Modal>
              // <div className="lightbox">
              //   <div className="container">
              //     <p>Do you really want to leave?</p>
              //     <button onClick={onCancel}>Cancel</button>
              //     <button onClick={onConfirm}>Ok</button>
              //   </div>
              // </div>
            )
          }
        </ReactRouterPrompt>
      </ErrorBoundary>
    </>
  );
}
